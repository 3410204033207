import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getProgramTests } from "../../../actions/user_program";
import { pluck } from "../../../utils/helpers";
import { setAlert } from "../../../actions/alert";
import CustomVideoPlayer from '../AssessmentTimer/CustomVideoPlayer';
import ScrollTopOnMount from '../../Layout/ScrollTopOnMount';
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";


const TestVideos = ({ ownProps, getTestsRequest, program_tests, getProgramTests, setAlert }) => {
    // const { id } = useParams();
    const location = useLocation()
    const navigate = useNavigate();
    const [programTests, setProgramTests] = useState([]);
    let [selectedTests, setSelectedTests] = useState([]);
    let [selectAllChecked, setSelectAllChecked] = useState(false);
    const { program } = location.state

    useEffect(() => {
        if (program) {
            setProgramTests(program.programTests ? program.programTests : [])
        }
    }, [program])

    const handleSelectAll = async () => {
        setSelectAllChecked(!selectAllChecked)
        if (!selectAllChecked) {
            setSelectedTests(await pluck(programTests, 'id'))
        } else {
            setSelectedTests([])
        }
    }

    const handleCheck = (e, id) => {
        if (e.target.checked && !selectedTests.includes(id)) {
            setSelectedTests([...selectedTests, id]);
        } else {
            setSelectedTests(selectedTests =>
                selectedTests.filter(item => {
                    return item !== id;
                })
            );
        }
    }

    const startTests = (e) => {
        e.preventDefault()
        if (selectedTests.length == 0) {
            setAlert('Please select at least one test', 'danger')
        } else {
            navigate('/assessment-test/' + selectedTests[0], { state: { selectedTests } })
        }
    }

    const handlePlayerClick = (e) => {
        e.preventDefault();// Prevent event propagation to parent elements
        e.nativeEvent.stopImmediatePropagation();
    };


    return (
        <>
            <ScrollTopOnMount />
            <Header />
            {/*<section className="welcome_001">*/}
            {/*    <div className="container h-100">*/}
            {/*        <div className="row h-100">*/}
            {/*            <div className="col-12 col-lg-6 col-md-6 m-auto">*/}
            {/*                <div className="box_banner_inner text-center m-auto">*/}
            {/*                    <div className="mx-auto">*/}
            {/*                        <h1>Test Videos</h1>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            <section className="videos">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-5">
                            <div className="videos_headings text-center">
                                <h2 className="detail_span text-center" style={{ color: "#fff" }}>Choose the tests that you will perform daily</h2>
                                <h4>Start with the test that you struggled with the most during the assessment</h4>
                            </div>
                        </div>
                        {programTests.length === 0 ?
                            <div className={"empty-page"} >
                                <span className="itemsPerPagelabel">No Tests Available.</span>
                            </div>
                            :
                            programTests && programTests.map((item, index) =>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4" key={index}>
                                    <div className="test_videobox pt-4">
                                        <div className="check_test pb-2">
                                            <input type="checkbox" id="check_test1" className="exercise" onChange={(e) => {
                                                handleCheck(e, item.id)
                                            }} checked={selectedTests.includes(item.id) ? true : false} />
                                            <label className="" htmlFor="check_test1" onClick={handlePlayerClick}>
                                                <div className="vids" >
                                                    <div className="video_new">
                                                        <div className="ssa video_testbox" style={{ textAlign: "center" }}
                                                            data-click="0">
                                                            <CustomVideoPlayer videoUrl={item.video} className={'video'} posterUrl={item.images}  />
                                                            {/*<video className="video" style={{ width: '100%' }}*/}
                                                            {/*    controls="controls" crossOrigin='anonymous'  preload="meta">*/}
                                                            {/*    <source src={item.video + '#t=0.001'} type="video/mp4" />*/}
                                                            {/*</video>*/}
                                                        </div>
                                                    </div>
                                                    <div className="video_testcontent">
                                                        <h6>{item.title}</h6>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {
                        programTests.length > 0 ?
                            <div class="test_btn text-center pb-5">
                                <Link to="#" class="theme_btn1 mt-30" data-target="#statuscheckout" onClick={startTests}>
                                    Start Selected test</Link>
                                <button class="theme_btn1 mt-30 checkAll" onClick={handleSelectAll}>
                                    {selectedTests.length > 0 ? "Unselect All" : "Select All"}
                                </button>
                            </div>
                            :
                            <div class="test_btn text-center pb-5">
                                <Link to={`/assessment-timer`} className="theme_btn1 mt-30">
                                    Start Exercises
                                </Link>
                            </div>
                    }
                </div>
            </section>
            <Footer />
        </>
    )

}
const mapStateToProps = (state, ownProps) => ({
    getTestsRequest: state.programReducer.getTestsRequest,
    getTestsSuccess: state.programReducer.getTestsSuccess,
    getTestsFailure: state.programReducer.getTestsFailure,
    program_tests: state.programReducer.program_tests,
    ownProps: ownProps
});

export default connect(mapStateToProps, { getProgramTests, setAlert })(TestVideos);