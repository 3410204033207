import React, { useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import CustomVideoPlayer from '../Pages/AssessmentTimer/CustomVideoPlayer';
import { getInstructions } from '../../actions/instructions';
import { connect } from 'react-redux';

const HowToUseModal = ({ show, handleClose, getInstructions, instructionsSuccess, instructions }) => {
    useEffect(() => {
        getInstructions()
    }, [])

    return (
        <>
            <Modal show={show}
                onHide={handleClose}
                id="IntroModal"
                className="modal fade"
                aria-hidden="true"
                aria-labelledby="exampleModalLabel"
                size='xl'
                centered>
                <Modal.Header>
                    <Modal.Title>How To Use This App</Modal.Title>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleClose}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {
                        instructions?.length == 0 ?
                            <span>No video available</span>
                            :
                            instructions?.length > 0 && instructions?.length < 2 ?
                                <CustomVideoPlayer autoPlay={true} videoUrl={instructions[0]?.video ? instructions[0]?.video : ''} className={'video'} />
                                // <video className='w-100' height={500} poster='' controls autoPlay={true} muted >
                                //     <source src={instructions[0]?.video} type="video/mp4" />
                                // </video> 
                                :
                                (instructions?.map((item, index) => (
                                <CustomVideoPlayer videoUrl={item?.video ? item?.video : ''} className={'video'} /> )))
                                // <Carousel interval={null}>
                                //     {
                                //         instructions?.map((item, index) => (
                                //             <Carousel.Item key={index}>
                                //                 {/* <video className='w-100' height={500} poster='' controls>
                                //                     <source src={item?.video} type="video/mp4" />
                                //                 </video> */}
                                //                 <CustomVideoPlayer videoUrl={item?.video ? item?.video : ''} className={'video'} />
                                //             </Carousel.Item>
                                //         ))
                                //     }
                                // </Carousel>
                    }

                    {/* <Carousel>
                        <Carousel.Item>
                            <img
                                className="img-fluid"
                                src="images/steps/step01.png"
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="img-fluid"
                                src="images/steps/step02.png"
                                alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="img-fluid"
                                src="images/steps/step03.png"
                                alt="Third slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="img-fluid"
                                src="images/steps/step04.png"
                                alt="Third slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="img-fluid"
                                src="images/steps/step05.png"
                                alt="Third slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="img-fluid"
                                src="images/steps/step06.png"
                                alt="Third slide"
                            />
                        </Carousel.Item>
                    </Carousel> */}

                </Modal.Body>

            </Modal></>
    )
}



const mapStateToProps = state => ({
    instructionsSuccess: state.instructionsReducer.instructionsSuccess || null,
    instructions: state.instructionsReducer.instructions || null,
});

export default connect(mapStateToProps, { getInstructions })(HowToUseModal) 