import {
    AUTH_ERROR,
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_UNVERIFIED,
    LOGOUT,
    LOGOUT_FAILURE,
    LOGOUT_REQUEST,
    OTP_FAILURE,
    OTP_REQUEST,
    OTP_SUCCESS,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_FAILURE,
    REGISTER_FAIL,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    RESEND_OTP_FAILURE,
    RESEND_OTP_REQUEST,
    RESEND_OTP_SUCCESS,
    USER_LOADED,
    VERIFY_OTP_FAILURE,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS,
    USER_PROFILE_REQUEST,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAILURE,
    USER_PROFILE_UPLOAD_REQUEST,
    USER_PROFILE_UPLOAD_SUCCESS,
    USER_PROFILE_UPLOAD_FAILURE,
    USER_PROFILE_UPDATE_REQUEST,
    USER_PROFILE_UPDATE_SUCCESS,
    USER_PROFILE_UPDATE_FAILURE,
    USER_PROFILE_UPDATE_PURGE,
    USER_PROFILE_UPLOAD_PURGE,
    PAGE_DATA_REQUEST,
    PAGE_DATA_SUCCESS,
    PAGE_DATA_FAILURE,
    REGISTER_PURGE,
    OCCUPATION_REQUEST,
    OCCUPATION_SUCCESS,
    OCCUPATION_FAILURE,
    SPORT_REQUEST,
    SPORT_SUCCESS,
    SPORT_FAILURE,
} from '../actions/types';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    loading: false,
    user: null,
    hasRegistered: false,
    hasRegisterPopup: false,
    isVerified: false,
    loginSuccess: false,
    profilePicture: '',
    profile: null,
    OTP: null,
    resentOTP: null
}

export default function auth(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REGISTER_PURGE:
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                user: null,
                hasRegistered: false
            }
            break;
        case REGISTER_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                user: payload,
                hasRegistered: true,
                hasRegisterPopup:true
            }
            break;
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.access_token.token);
            localStorage.setItem('user', JSON.stringify(payload.user));
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload,
                isVerified: true,
                loginSuccess: true,
                profilePicture: payload.user.image
            }
            break;
        case LOGIN_UNVERIFIED:
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                user: payload,
                isVerified: false,
                loginSuccess: true
            }
            break;
        case OTP_REQUEST:
            return {
                ...state,
                otpRequest: true,
                otpSuccess: false,
                otpFailure: false
            }
            break;
        case OTP_SUCCESS:
            return {
                ...state,
                otpRequest: false,
                otpSuccess: true,
                otpFailure: false
            }
            break;


        case PAGE_DATA_REQUEST:
            return {
                ...state,
                otpRequest: true,
                otpSuccess: false,
                otpFailure: false,
                page: null
            }
            break;
        case PAGE_DATA_SUCCESS:
            return {
                ...state,
                otpRequest: false,
                otpSuccess: true,
                otpFailure: false,
                page: payload
            }
            break;

        case PAGE_DATA_FAILURE:
            return {
                ...state,
                otpRequest: false,
                otpSuccess: false,
                otpFailure: true,
                page: null
            }
            break;
        case REGISTER_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                user: null,
                hasRegistered: false
            }
            break;
        case AUTH_ERROR:
        case LOGIN_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                user: payload,
                isVerified: false,
                loginSuccess: false,
            }
            break;
        case LOGOUT_REQUEST:
            return {
                ...state,
                logoutRequest: true,
                logoutSuccess: false,
                logoutFailure: false
            }
            break;
        case LOGOUT:
            return {
                ...state,
                logoutRequest: false,
                logoutSuccess: true,
                logoutFailure: false,
                isAuthenticated: false,
                hasRegisterPopup:false,
            }
            break;
        case LOGOUT_FAILURE:
            return {
                ...state,
                logoutRequest: false,
                logoutSuccess: false,
                logoutFailure: true
            }
            break;
        case OTP_FAILURE:
            localStorage.removeItem('token');
            return {
                ...state,
                otpRequest: false,
                otpSuccess: false,
                otpFailure: true
            }
            break;
        case LOGIN_REQUEST:
        case REGISTER_REQUEST:
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: true,
                logoutSuccess: false
            }
            break;
        case PASSWORD_RESET_REQUEST:
            return {
                ...state,
                resetPasswordRequest: true,
                resetPasswordSuccess: false,
                resetPasswordFailure: false,
            }
            break;
        case PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                resetPasswordRequest: false,
                resetPasswordSuccess: true,
                resetPasswordFailure: false,
            }
            break;
        case PASSWORD_RESET_FAILURE:
            return {
                ...state,
                resetPasswordRequest: false,
                resetPasswordSuccess: false,
                resetPasswordFailure: true,
            }
            break;
        case VERIFY_OTP_REQUEST:
            return {
                ...state,
                verifyOTPRequest: true,
                verifyOTPSuccess: false,
                verifyOTPFailure: false,
            }
        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                isVerified: true,
                verifyOTPRequest: false,
                verifyOTPSuccess: true,
                verifyOTPFailure: false,
            }
            break;
        case VERIFY_OTP_FAILURE:
            return {
                ...state,
                verifyOTPRequest: false,
                verifyOTPSuccess: false,
                verifyOTPFailure: true,
            }
            break;
        case USER_LOADED:
            localStorage.setItem('profile', JSON.stringify(payload.profile));
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                profile: payload.profile,
            }
        case RESEND_OTP_REQUEST:
            return {
                ...state,
                resendOTPRequest: true,
                resendOTPSuccess: false,
                resendOTPFailure: false
            }
        case RESEND_OTP_SUCCESS:
            return {
                ...state,
                resendOTPRequest: false,
                resendOTPSuccess: true,
                resendOTPFailure: false
            }
        case RESEND_OTP_FAILURE:
            return {
                ...state,
                resendOTPRequest: false,
                resendOTPSuccess: false,
                resendOTPFailure: true
            }

        case USER_PROFILE_REQUEST:
            return {
                ...state,
                userProfileRequest: true,
                userProfileSuccess: false,
                userProfileFailure: false,
                userProfile: null,
            }
        case USER_PROFILE_SUCCESS:
            localStorage.setItem('user', JSON.stringify(payload.profile));
            return {
                ...state,
                userProfileRequest: false,
                userProfileSuccess: true,
                userProfileFailure: false,
                userProfile: payload.profile,
            }
        case USER_PROFILE_FAILURE:
            return {
                ...state,
                userProfileRequest: false,
                userProfileSuccess: false,
                userProfileFailure: true,
                userProfile: null,
            }

        case USER_PROFILE_UPLOAD_REQUEST:
            return {
                ...state,
                profileUploadRequest: true,
                profileUploadSuccess: false,
                profileUploadFailure: false,
                profileUpload: null,
            }
        case USER_PROFILE_UPLOAD_SUCCESS:
            return {
                ...state,
                profileUploadRequest: false,
                profileUploadSuccess: true,
                profileUploadFailure: false,
                profileUpload: payload,
            }
        case USER_PROFILE_UPLOAD_FAILURE:
            return {
                ...state,
                profileUploadRequest: false,
                profileUploadSuccess: false,
                profileUploadFailure: true,
                profileUpload: null,
            }
        case USER_PROFILE_UPLOAD_PURGE:
            return {
                ...state,
                profileUploadRequest: false,
                profileUploadSuccess: false,
                profileUploadFailure: false,
            }

        case USER_PROFILE_UPDATE_REQUEST:
            return {
                ...state,
                profileUpdateRequest: true,
                profileUpdateSuccess: false,
                profileUpdateFailure: false,
            }
        case USER_PROFILE_UPDATE_SUCCESS:
            return {
                ...state,
                profileUpdateRequest: false,
                profileUpdateSuccess: true,
                profileUpdateFailure: false,
            }
        case USER_PROFILE_UPDATE_FAILURE:
            return {
                ...state,
                profileUpdateRequest: false,
                profileUpdateSuccess: false,
                profileUpdateFailure: true,
            }
        case USER_PROFILE_UPDATE_PURGE:
            return {
                ...state,
                profileUpdateRequest: false,
                profileUpdateSuccess: false,
                profileUpdateFailure: false,
            }


        case OCCUPATION_REQUEST:
            return {
                ...state,
                occupationRequest: true,
                occupationSuccess: false,
                occupationFailure: false,
                occupations: null
            }
        case OCCUPATION_SUCCESS:
            return {
                ...state,
                occupationRequest: false,
                occupationSuccess: true,
                occupationFailure: false,
                occupations: payload
            }
        case OCCUPATION_FAILURE:
            return {
                ...state,
                occupationRequest: false,
                occupationSuccess: false,
                occupationFailure: true,
                occupations: null
            }

        case SPORT_REQUEST:
            return {
                ...state,
                sportRequest: true,
                sportSuccess: false,
                sportFailure: false,
                sports: null
            }
        case SPORT_SUCCESS:
            return {
                ...state,
                sportRequest: false,
                sportSuccess: true,
                sportFailure: false,
                sports: payload
            }
        case SPORT_FAILURE:
            return {
                ...state,
                sportRequest: false,
                sportSuccess: false,
                sportFailure: true,
                sports: null
            }
        default:
            return state
    }
}