import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { FadeLoader } from "react-spinners"
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../../actions/auth';


const Login = ({ login, isAuthenticated, loading, isVerified, loginSuccess }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const { email, password } = formData;

    const navigate = useNavigate()

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = (e) => {
        e.preventDefault();
        login(email, password);
    }
    if (loginSuccess && isAuthenticated && isVerified) {
        navigate("/welcome", { replace: true })
    }
    if (loginSuccess && !isVerified) {
        navigate("/verify-email", { state: { email } })
    }
    if (!loginSuccess) {
        loading = false
    }
    return (
        <>
            <Form onSubmit={onSubmit} id="call_form" className="form_signup">
                <div className="sign">
                    <h4>Log In</h4>
                    <p>
                        Welcome to Body Solutions! Please enter your
                        <br /> details to Set up your account
                    </p>
                </div>
                <Form.Group className="mb-3 form_signup">
                    <Form.Label>Email address</Form.Label>

                    <div className="position-relative email_ic">
                        <Form.Control
                            type="email"
                            placeholder="Email Address "
                            className="form-1 email_img"
                            name="email"
                            value={email}
                            onChange={onChange}
                            required
                        />
                    </div>
                    {/* <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text> */}
                </Form.Group>

                <Form.Group className="mb-3 form_signup">
                    <Form.Label>Password</Form.Label>

                    <div className="position-relative password_ic">
                        <Form.Control
                            type="password"
                            placeholder="Type Password"
                            className="form-1 pass_img"
                            value={password}
                            onChange={onChange}
                            name="password"
                            required
                        />
                    </div>
                </Form.Group>
                <Form.Group className="form-group loader_custom">
                    <div className="forgot already">
                        <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                    {loading ?
                        <FadeLoader color={"#FB1417"} height={10} /> : <Form.Control
                            type="submit"
                            value="sign In"
                            className="submit-btn w-100 pb-3"
                            required
                        />
                    }
                    <p className="already">
                        Don’t have an account? <Link to="/signup">Sign Up</Link>
                    </p>
                </Form.Group>
                {/* <Form.Group className="mb-3" >
          <Form.Check type="checkbox" label="Check me out" />
        </Form.Group>
        <Button variant="secondary" type="submit">
          Submit
        </Button> */}
            </Form>
        </>
    );
};

Login.propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    isVerified: PropTypes.bool,
    loginSuccess: PropTypes.bool,

};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading,
    isVerified: state.auth.isVerified,
    loginSuccess: state.auth.loginSuccess,

});

export default connect(mapStateToProps, { login })(Login);
