import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { Link } from "react-router-dom";
import CustomVideoPlayer from "./AssessmentTimer/CustomVideoPlayer";

const ExerciseEducationalVideoModal = ({ show, handleClose, videos }) => {
    return (
        <>
            <Modal show={show} onHide={handleClose} className="modal_test vedio_modal" centered>
                <Modal.Header>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleClose}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {videos && videos.map((item, index) =>
                        <>
                            {item.video ?
                                <CustomVideoPlayer videoUrl={item.video ? item.video : ''} className={'video'} />
                                // <video
                                //     className="video"
                                //     controls="controls"
                                //     width="100%"
                                //     height="100%"
                                //     key={index}
                                // // crossOrigin='anonymous'
                                // >
                                //     <source src={item.video ? item.video : ''} />
                                // </video>
                                :
                                ''
                            }
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ExerciseEducationalVideoModal;
