import React from 'react'
import { Link } from "react-router-dom";

const FooterBottom = () => {
  return (
    <section className="bottom_footer">
      <div className="container ">
        <div className="bt01">
          <div className="row">
            <div className="col-12 col-lg-6 col-md-6">
              <p>
                © 2022 Designed by{" "}
                <Link to="#" target="_blank">
                  {" "}
                  Tekrevol
                </Link>
              </p>
            </div>
            <div className="col-12 col-lg-6 col-md-6 right">
              <p className="right">
                <Link to="/pages/terms-and-conditions" target="_blank">
                  Terms and conditions{" "}
                </Link>
                &{" "}
                <Link to="/pages/terms-and-conditions" target="_blank">
                  Privacy Policy
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FooterBottom