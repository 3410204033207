import React from 'react'
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";

const ChangeLevelModal = ({ show, handleClose, handleSubmit }) => {
    return (
        <>
            <Modal show={show}
                onHide={handleClose}
                className="modal_test"
                id={'changeLevelModel'}
                centered
            >
                <Modal.Body className="text-left">
                    <div className="box_state">
                        <h4>Do you really want to change level?</h4>
                        <button
                            type="button"
                            className="theme_btn1 small_btn w-100"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={handleSubmit}
                        >Confirm</button>
                        <button
                            type="button"
                            className="theme_btn1 small_btn w-100"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}
                        >Close</button>

                    </div>
                </Modal.Body>
            </Modal></>
    )
}
export default ChangeLevelModal