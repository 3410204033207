import React from 'react'

const Welcome1 = ({ program ,exercises = false }) => {
  return (
    <section className={`welcome_001 ${exercises ? 'display-none' : ''}`}>
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-12 col-lg-6 col-md-6 m-auto ">
            <div className="box_banner_inner text-center mx-auto">
              <div>
                <h1>{program.name}</h1>
                {/* <h5> {program.description}</h5> */}
                <p>12 Week Corrective Exercise Program</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Welcome1