import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getExerciseDetail } from "../../../actions/user_program";
import { FadeLoader } from "react-spinners";
import { Link } from "react-router-dom";
import barchart from "../../images/barchart.png"
import StatusCheckinModal from "./StatusCheckinModal"
import DropdownButton from "react-bootstrap/DropdownButton";

const Exercise = ({ getExerciseDetail, exerciseDetail }) => {

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        getExerciseDetail(7)
    }, [])

    const handleClick = (event) => {
        // 👇️ toggle isActive state on click
        setIsActive((current) => !current);
    };

    if (!exerciseDetail) {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 center-loader">
                        <FadeLoader color={"#FB1417"} height={10} />
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <section className="grid_001">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6 col-md-6 test_one">
                            <div className="tab-content box_test ex_box">
                                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-9 col-md-7">
                                                <h4 className="mb-20">{exerciseDetail.exercise.name}</h4>
                                            </div>
                                            <div className="col-3 col-md-5 text-right">
                                                <div className="btn-group">
                                                    <div className="icon_bar" onClick={handleClick}>
                                                        <svg
                                                            width="37"
                                                            height="37"
                                                            viewBox="0 0 37 37"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <circle
                                                                cx="18.1211"
                                                                cy="18.3081"
                                                                r="18"
                                                                fill="black"
                                                            ></circle>
                                                            <rect
                                                                x="10.9209"
                                                                y="18.6885"
                                                                width="3.32275"
                                                                height="9.53105"
                                                                fill="#1AE074"
                                                            ></rect>
                                                            <rect
                                                                x="15.9927"
                                                                y="13.5295"
                                                                width="3.32275"
                                                                height="14.6901"
                                                                fill="#9B9B9B"
                                                            ></rect>
                                                            <rect
                                                                x="21.4141"
                                                                y="8.10806"
                                                                width="3.32275"
                                                                height="20.1114"
                                                                fill="#9B9B9B"
                                                            ></rect>
                                                        </svg>
                                                    </div>
                                                    <div
                                                        className={
                                                            isActive
                                                                ? "dropdown-menu dropdown-menu-right bar_dropdown show"
                                                                : "dropdown-menu dropdown-menu-right bar_dropdown"
                                                        }
                                                    >
                                                        <h4>Exercise Levels</h4>
                                                        <div id="wrapper1">
                                                            {/* <canvas id="barChart"></canvas> */}
                                                            <img src={barchart} alt="barchart" />
                                                            <div className="btn_chart text-center">
                                                                <Link
                                                                    to="/program/change-level"
                                                                    className="theme_btn1 small_btn"
                                                                >
                                                                    Change Level
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <h6>Starting Position</h6>

                                        <ul className="list_exs">
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    {exerciseDetail.exercise.short_description}
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    {exerciseDetail.exercise.short_description}
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                {exerciseDetail.exercise.short_description}
                                            </li>
                                        </ul>
                                        <h6>Movement</h6>
                                        <ul className="list_exs">
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Lie on your side with your straight legs stacked few
                                                    inches in front of your body.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Your bottom arm should be straight out in front of you
                                                    with your plam on the floor.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>Slowly lower back down to the starting position</p>
                                            </li>
                                        </ul>
                                        <div className="exc_p">
                                            <p>
                                                <span>Note:</span> This exercise will help your right
                                                hip
                                            </p>
                                        </div>
                                        <div
                                            className=" ssa box_video"
                                            style={{ "text-align": "center" }}
                                            data-click="0"
                                        >
                                            <video
                                                className="video"
                                                controls="controls"
                                                width="100%"
                                                height="100%"
                                                poster="/images/poster.png"
                                                autoPlay={"autoplay"}
                                                preLoad="auto"
                                            >
                                                <source src="/images/ankle-scraping.mp4" />
                                            </video>
                                            <div id="video_controls_bar">
                                                {/* <img
                            src="/images/puse.png"
                            alt="puse"
                            className="img-fluid img001"
                          /> */}
                                            </div>
                                            <div className="overlay_vid"></div>
                                        </div>

                                        <div className="  position-relative">
                                            {/* <Link

                          to=""
                          className="theme_btn1   "
                          data-toggle="modal"
                          data-target="#statuscheckout"
                        > */}
                                            <StatusCheckinModal />
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="tabs-2" role="tabpanel">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-9 col-md-7">
                                                <h4 className="mb-20">Ankle Scraping</h4>
                                            </div>
                                            <div className="col-3 col-md-5 text-right">
                                                <div className="btn-group ">
                                                    <div className="icon_bar" data-toggle="dropdown">
                                                        <svg
                                                            width="37"
                                                            height="37"
                                                            viewBox="0 0 37 37"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <circle
                                                                cx="18.1211"
                                                                cy="18.3081"
                                                                r="18"
                                                                fill="black"
                                                            ></circle>
                                                            <rect
                                                                x="10.9209"
                                                                y="18.6885"
                                                                width="3.32275"
                                                                height="9.53105"
                                                                fill="#9B9B9B"
                                                            ></rect>
                                                            <rect
                                                                x="15.9927"
                                                                y="13.5295"
                                                                width="3.32275"
                                                                height="14.6901"
                                                                fill="#9B9B9B"
                                                            ></rect>
                                                            <rect
                                                                x="21.4141"
                                                                y="8.10806"
                                                                width="3.32275"
                                                                height="20.1114"
                                                                fill="#FF5355"
                                                            ></rect>
                                                        </svg>
                                                    </div>
                                                    <DropdownButton id="dropdown-basic-button">
                                                        <h4>Exercise Levels</h4>
                                                        <div id="wrapper1">
                                                            <canvas id="barChart1"></canvas>

                                                            <div className="btn_chart text-center">
                                                                <Link
                                                                    to="/program/change-level"
                                                                    className="theme_btn1 small_btn"
                                                                >
                                                                    Change Level
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </DropdownButton>
                                                </div>
                                            </div>
                                        </div>

                                        <h6>Starting Position</h6>

                                        <ul className="list_exs">
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Lie on your side with your straight legs stacked few
                                                    inches in front of your body.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Your bottom arm should be straight out in front of you
                                                    with your plam on the floor.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>Place your top hand behind your head.</p>
                                            </li>
                                        </ul>
                                        <h6>Movement</h6>
                                        <ul className="list_exs">
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Lie on your side with your straight legs stacked few
                                                    inches in front of your body.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Your bottom arm should be straight out in front of you
                                                    with your plam on the floor.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>Slowly lower back down to the starting position</p>
                                            </li>
                                        </ul>
                                        <div className="exc_p">
                                            <p>
                                                <span>Note:</span> This exercise will help your right
                                                hip
                                            </p>
                                        </div>
                                        <div
                                            className=" ssa box_video"
                                            style={{ "text-align": "center" }}
                                            data-click="0"
                                        >
                                            <video
                                                className="video"
                                                controls="controls"
                                                width="100%"
                                                height="100%"
                                                poster="/images/poster.png"
                                            >
                                                <source src="/images/ankle-scraping.mp4" />
                                            </video>
                                            <div id="video_controls_bar">
                                                <img
                                                    src="/images/puse.png"
                                                    alt="puse"
                                                    className="img-fluid img001"
                                                />
                                            </div>
                                            <div className="overlay_vid"></div>
                                        </div>

                                        <div className="  position-relative">
                                            {/* <Link
                          to=""
                          className="theme_btn1   "
                          data-toggle="modal"
                          data-target="#statuscheckout"
                        > */}
                                            <StatusCheckinModal />
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="tabs-3" role="tabpanel">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-9 col-md-7">
                                                <h4 className="mb-20">Ankle Scraping</h4>
                                            </div>
                                            <div className="col-3 col-md-5 text-right">
                                                <div className="btn-group ">
                                                    <div className="icon_bar" data-toggle="dropdown">
                                                        <svg
                                                            width="37"
                                                            height="37"
                                                            viewBox="0 0 37 37"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <circle
                                                                cx="18.1211"
                                                                cy="18.3081"
                                                                r="18"
                                                                fill="black"
                                                            ></circle>
                                                            <rect
                                                                x="10.9209"
                                                                y="18.6885"
                                                                width="3.32275"
                                                                height="9.53105"
                                                                fill="#9B9B9B"
                                                            ></rect>
                                                            <rect
                                                                x="15.9927"
                                                                y="13.5295"
                                                                width="3.32275"
                                                                height="14.6901"
                                                                fill="#159EFF"
                                                            ></rect>
                                                            <rect
                                                                x="21.4141"
                                                                y="8.10806"
                                                                width="3.32275"
                                                                height="20.1114"
                                                                fill="#9B9B9B"
                                                            ></rect>
                                                        </svg>
                                                    </div>
                                                    <div className="dropdown-menu dropdown-menu-right bar_dropdown">
                                                        <h4>Exercise Levels</h4>
                                                        <div id="wrapper1">
                                                            <canvas id="barChart2"></canvas>

                                                            <div className="btn_chart text-center">
                                                                <Link
                                                                    to="/program/change-level"
                                                                    className="theme_btn1 small_btn"
                                                                >
                                                                    Change Level
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <h6>Starting Position</h6>

                                        <ul className="list_exs">
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Lie on your side with your straight legs stacked few
                                                    inches in front of your body.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Your bottom arm should be straight out in front of you
                                                    with your plam on the floor.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>Place your top hand behind your head.</p>
                                            </li>
                                        </ul>
                                        <h6>Movement</h6>
                                        <ul className="list_exs">
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Lie on your side with your straight legs stacked few
                                                    inches in front of your body.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Your bottom arm should be straight out in front of you
                                                    with your plam on the floor.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>Slowly lower back down to the starting position</p>
                                            </li>
                                        </ul>
                                        <div className="exc_p">
                                            <p>
                                                <span>Note:</span> This exercise will help your right
                                                hip
                                            </p>
                                        </div>
                                        <div
                                            className=" ssa box_video"
                                            style={{ "text-align": "center" }}
                                            data-click="0"
                                        >
                                            <video
                                                className="video"
                                                controls="controls"
                                                width="100%"
                                                height="100%"
                                                poster="/images/poster.png"
                                            >
                                                <source src="/images/ankle-scraping.mp4" />
                                            </video>
                                            <div id="video_controls_bar">
                                                <img
                                                    src="/images/puse.png"
                                                    alt="puse"
                                                    className="img-fluid img001"
                                                />
                                            </div>
                                            <div className="overlay_vid"></div>
                                        </div>
                                        <div className="  position-relative">
                                            {/* <Link
                          to=""

                          className="theme_btn1   "
                          data-toggle="modal"
                          data-target="#statuscheckout"
                        > */}
                                            <StatusCheckinModal />
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="tabs-4" role="tabpanel">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-9 col-md-7">
                                                <h4 className="mb-20">Ankle Scraping</h4>
                                            </div>
                                            <div className="col-3 col-md-5 text-right">
                                                <div className="btn-group ">
                                                    <div className="icon_bar" data-toggle="dropdown">
                                                        <svg
                                                            width="37"
                                                            height="37"
                                                            viewBox="0 0 37 37"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <circle
                                                                cx="18.1211"
                                                                cy="18.3081"
                                                                r="18"
                                                                fill="black"
                                                            ></circle>
                                                            <rect
                                                                x="10.9209"
                                                                y="18.6885"
                                                                width="3.32275"
                                                                height="9.53105"
                                                                fill="#1AE074"
                                                            ></rect>
                                                            <rect
                                                                x="15.9927"
                                                                y="13.5295"
                                                                width="3.32275"
                                                                height="14.6901"
                                                                fill="#9B9B9B"
                                                            ></rect>
                                                            <rect
                                                                x="21.4141"
                                                                y="8.10806"
                                                                width="3.32275"
                                                                height="20.1114"
                                                                fill="#9B9B9B"
                                                            ></rect>
                                                        </svg>
                                                    </div>
                                                    <div className="">
                                                        <h4>Exercise Levels</h4>
                                                        <div id="wrapper1">
                                                            <canvas id="barChart3"></canvas>

                                                            <div className="btn_chart text-center">
                                                                <Link
                                                                    to="/program/change-level"
                                                                    className="theme_btn1 small_btn"
                                                                >
                                                                    Change Level
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <h6>Starting Position</h6>

                                        <ul className="list_exs">
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Lie on your side with your straight legs stacked few
                                                    inches in front of your body.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Your bottom arm should be straight out in front of you
                                                    with your plam on the floor.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>Place your top hand behind your head.</p>
                                            </li>
                                        </ul>
                                        <h6>Movement</h6>
                                        <ul className="list_exs">
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Lie on your side with your straight legs stacked few
                                                    inches in front of your body.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Your bottom arm should be straight out in front of you
                                                    with your plam on the floor.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>Slowly lower back down to the starting position</p>
                                            </li>
                                        </ul>
                                        <div className="exc_p">
                                            <p>
                                                <span>Note:</span> This exercise will help your right
                                                hip
                                            </p>
                                        </div>
                                        <div id="video_player_box " className="box_video">
                                            <video
                                                id="my_video"
                                                controls="controls"
                                                width="100%"
                                                height="100%"
                                                poster="/images/poster.png"
                                            >
                                                <source src="/images/ankle-scraping.mp4" />
                                            </video>

                                            <div id="video_controls_bar">
                                                <button>Play</button>
                                                <img
                                                    src="/images/puse.png"
                                                    alt="puse"
                                                    className="img-fluid img001"
                                                    id="playpausebtn"
                                                    onClick={() => {

                                                    }}
                                                />
                                            </div>
                                            <div className="overlay_vid"></div>
                                        </div>
                                        <div className="  position-relative">
                                            {/* <Link
                          to=""
                          className="theme_btn1   "
                          data-toggle="modal"
                          data-target="#statuscheckout"
                        > */}
                                            <StatusCheckinModal />
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="tabs-5" role="tabpanel">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-9 col-md-7">
                                                <h4 className="mb-20">Ankle Scraping</h4>
                                            </div>
                                            <div className="col-3 col-md-5 text-right">
                                                <div className="btn-group ">
                                                    <div className="icon_bar" data-toggle="dropdown">
                                                        <svg
                                                            width="37"
                                                            height="37"
                                                            viewBox="0 0 37 37"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <circle
                                                                cx="18.1211"
                                                                cy="18.3081"
                                                                r="18"
                                                                fill="black"
                                                            ></circle>
                                                            <rect
                                                                x="10.9209"
                                                                y="18.6885"
                                                                width="3.32275"
                                                                height="9.53105"
                                                                fill="#9B9B9B"
                                                            ></rect>
                                                            <rect
                                                                x="15.9927"
                                                                y="13.5295"
                                                                width="3.32275"
                                                                height="14.6901"
                                                                fill="#9B9B9B"
                                                            ></rect>
                                                            <rect
                                                                x="21.4141"
                                                                y="8.10806"
                                                                width="3.32275"
                                                                height="20.1114"
                                                                fill="#FF5355"
                                                            ></rect>
                                                        </svg>
                                                    </div>
                                                    <div className="dropdown-menu dropdown-menu-right bar_dropdown">
                                                        <h4>Exercise Levels</h4>
                                                        <div id="wrapper1">
                                                            <canvas id="barChart4"></canvas>

                                                            <div className="btn_chart text-center">
                                                                <Link
                                                                    to="/program/change-level"
                                                                    className="theme_btn1 small_btn"
                                                                >
                                                                    Change Level
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <h6>Starting Position</h6>

                                        <ul className="list_exs">
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Lie on your side with your straight legs stacked few
                                                    inches in front of your body.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Your bottom arm should be straight out in front of you
                                                    with your plam on the floor.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>Place your top hand behind your head.</p>
                                            </li>
                                        </ul>
                                        <h6>Movement</h6>
                                        <ul className="list_exs">
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Lie on your side with your straight legs stacked few
                                                    inches in front of your body.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Your bottom arm should be straight out in front of you
                                                    with your plam on the floor.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>Slowly lower back down to the starting position</p>
                                            </li>
                                        </ul>
                                        <div className="exc_p">
                                            <p>
                                                <span>Note:</span> This exercise will help your right
                                                hip
                                            </p>
                                        </div>
                                        <div
                                            className=" ssa box_video"
                                            style={{ "text-align": "center" }}
                                            data-click="0"
                                        >
                                            <video
                                                className="video"
                                                controls="controls"
                                                width="100%"
                                                height="100%"
                                                poster="/images/poster.png"
                                            >
                                                <source src="/images/ankle-scraping.mp4" />
                                            </video>
                                            <div id="video_controls_bar">
                                                <img
                                                    src="/images/puse.png"
                                                    alt="puse"
                                                    className="img-fluid img001"
                                                />
                                            </div>
                                            <div className="overlay_vid"></div>
                                        </div>
                                        <div className="  position-relative">
                                            {/* <Link

                          to=""
                          className="theme_btn1   "
                          data-toggle="modal"
                          data-target="#statuscheckout"
                        > */}
                                            <StatusCheckinModal />
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="tabs-6" role="tabpanel">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-9 col-md-7">
                                                <h4 className="mb-20">Ankle Scraping</h4>
                                            </div>
                                            <div className="col-3 col-md-5 text-right">
                                                <div className="btn-group ">
                                                    <div className="icon_bar" data-toggle="dropdown">
                                                        <svg
                                                            width="37"
                                                            height="37"
                                                            viewBox="0 0 37 37"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <circle
                                                                cx="18.1211"
                                                                cy="18.3081"
                                                                r="18"
                                                                fill="black"
                                                            ></circle>
                                                            <rect
                                                                x="10.9209"
                                                                y="18.6885"
                                                                width="3.32275"
                                                                height="9.53105"
                                                                fill="#9B9B9B"
                                                            ></rect>
                                                            <rect
                                                                x="15.9927"
                                                                y="13.5295"
                                                                width="3.32275"
                                                                height="14.6901"
                                                                fill="#159EFF"
                                                            ></rect>
                                                            <rect
                                                                x="21.4141"
                                                                y="8.10806"
                                                                width="3.32275"
                                                                height="20.1114"
                                                                fill="#9B9B9B"
                                                            ></rect>
                                                        </svg>
                                                    </div>
                                                    <div className="dropdown-menu dropdown-menu-right bar_dropdown">
                                                        <h4>Exercise Levels</h4>
                                                        <div id="wrapper1">
                                                            <canvas id="barChart5"></canvas>
                                                            <div className="btn_chart text-center">
                                                                <Link
                                                                    to="/program/change-level"
                                                                    className="theme_btn1 small_btn"
                                                                >
                                                                    Change Level
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <h6>Starting Position</h6>

                                        <ul className="list_exs">
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Lie on your side with your straight legs stacked few
                                                    inches in front of your body.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Your bottom arm should be straight out in front of you
                                                    with your plam on the floor.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>Place your top hand behind your head.</p>
                                            </li>
                                        </ul>
                                        <h6>Movement</h6>
                                        <ul className="list_exs">
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Lie on your side with your straight legs stacked few
                                                    inches in front of your body.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>
                                                    Your bottom arm should be straight out in front of you
                                                    with your plam on the floor.
                                                </p>
                                            </li>
                                            <li>
                                                <svg
                                                    width="5"
                                                    height="7"
                                                    viewBox="0 0 5 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                                                        fill="#FB1417"
                                                    />
                                                </svg>
                                                <p>Slowly lower back down to the starting position</p>
                                            </li>
                                        </ul>
                                        <div className="exc_p">
                                            <p>
                                                <span>Note:</span> This exercise will help your right
                                                hip
                                            </p>
                                        </div>
                                        <div
                                            className=" ssa box_video"
                                            style={{ "text-align": "center" }}
                                            data-click="0"
                                        >
                                            <video
                                                className="video"
                                                controls="controls"
                                                width="100%"
                                                height="100%"
                                                poster="/images/poster.png"
                                            >
                                                <source src="/images/ankle-scraping.mp4" />
                                            </video>
                                            <div id="video_controls_bar">
                                                <img
                                                    src="/images/puse.png"
                                                    alt="puse"
                                                    className="img-fluid img001"
                                                />
                                            </div>
                                            <div className="overlay_vid"></div>
                                        </div>
                                        <div className="  position-relative">
                                            <StatusCheckinModal />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

};

const mapStateToProps = state => ({
    exerciseDetailRequest: state.programReducer.exerciseDetailRequest || null,
    exerciseDetailSuccess: state.programReducer.exerciseDetailSuccess || null,
    exerciseDetailFailure: state.programReducer.exerciseDetailFailure || null,
    exerciseDetail: state.programReducer.exerciseDetail
});
export default connect(mapStateToProps, { getExerciseDetail })(Exercise);