import { Interweave } from 'interweave'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../actions/alert'
import { LEVEL_CHANGE_PURGE } from '../../../actions/types'
import { getExerciseLevelsData, updateExerciseLevel } from '../../../actions/user_program'
import level1 from "../../images/level1.png"
import level2 from "../../images/level2.png"
import ChangeLevelModal from '../../Layout/ChangeLevelModal'
import { EXERCISE_TIMES } from '../../../utils/constants'
import PosterVideoPlayer from "../AssessmentTimer/PosterVideoPlayer";
import GeneralModal from "./GeneralModal";

const ChangeLevel2 = ({ ownProps, exercisesLevels, getExerciseLevelsData, weekSchedule, updateExerciseLevel, levelUpdate, levelUpdateSuccess, redirectLink }) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [level, setLevel] = useState(null);
    const [showStartModal, setShowStartModal] = useState(false);
    const [modalData, setModalData] = useState();
    const [modalTitle, setModalTitle] = useState();
    const maxLength = 40;
    let { exerciseId } = ownProps
    let { curLevel } = ownProps
    let { activeId } = ownProps
    let { programId } = ownProps

    const [exerciseLevelsData, setExerciseLevelsData] = useState([])
    const [exerciseLevel1Equipments, setExerciseLevel1Equipments] = useState([])
    const [exerciseLevel2Equipments, setExerciseLevel2Equipments] = useState([])
    const [exerciseLevel3Equipments, setExerciseLevel3Equipments] = useState([])

    useEffect(() => {
        if (exerciseId && curLevel) {
            getExerciseLevelsData(exerciseId)
        }
    }, [exerciseId, curLevel])

    useEffect(() => {
        setExerciseLevelsData([])
        setExerciseLevel1Equipments([])
        setExerciseLevel2Equipments([])
        setExerciseLevel3Equipments([])
        if (exercisesLevels != null && exercisesLevels != undefined && exercisesLevels.level1 != undefined && exercisesLevels.level1.length > 0) {
            if (exercisesLevels.level1[0].equipment_exercise_type == 0) {
                setExerciseLevelsData(exerciseLevelsData => [...exerciseLevelsData, exercisesLevels.level1[0]])
                setExerciseLevel1Equipments(exerciseLevel1Equipments => exercisesLevels.level1.filter((_, index) => index !== 0));

            } else {
                setExerciseLevel1Equipments(exerciseLevel1Equipments => exercisesLevels.level1);
            }
        }
        if (exercisesLevels != null && exercisesLevels != undefined && exercisesLevels.level2 != undefined && exercisesLevels.level2.length > 0) {
            if (exercisesLevels.level2[0].equipment_exercise_type == 0) {
                setExerciseLevelsData(exerciseLevelsData => [...exerciseLevelsData, exercisesLevels.level2[0]])
                setExerciseLevel2Equipments(exerciseLevel2Equipments => exercisesLevels.level2.filter((_, index) => index !== 0));
            } else {
                setExerciseLevel2Equipments(exerciseLevel2Equipments => exercisesLevels.level2);
            }
        }
        if (exercisesLevels != null && exercisesLevels != undefined && exercisesLevels.level3 != undefined && exercisesLevels.level3.length > 0) {
            if (exercisesLevels.level3[0].equipment_exercise_type == 0) {
                setExerciseLevelsData(exerciseLevelsData => [...exerciseLevelsData, exercisesLevels.level3[0]])
                setExerciseLevel3Equipments(exerciseLevel3Equipments => exercisesLevels.level3.filter((_, index) => index !== 0));
            } else {
                setExerciseLevel3Equipments(exerciseLevel3Equipments => exercisesLevels.level3);
            }
        }
    }, [exercisesLevels])

    const changeLevelFunc = (data) => {
        setLevel(data)
        handleShow()
    }

    const changeLevelModal = (data,title) => {
        setModalTitle(title)
        setModalData(data)
        setShowStartModal(true)
    }
    const setShowStartFunc = (data) => {
        setShowStartModal(data)
    }

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleSubmit = () => {
        if (level && exerciseId) {
            updateExerciseLevel(activeId, exerciseId, level)
        }
    }

    if (levelUpdateSuccess && levelUpdate) {
        dispatch({ type: 'LEVEL_CHANGE_PURGE' });
        if (redirectLink !== null && redirectLink !== undefined && redirectLink !== '') {
            navigate(`${redirectLink}`, { replace: true ,state: { myData: activeId } });
        } else {
            navigate(`/program/${programId}`, { replace: true });
        }
    }

    const ReadMoreP = ({ text, maxLength, className }) => {
        const [expanded, setExpanded] = useState(false);
        const truncatedText = text?.slice(0, maxLength);
        return (
            <div>
                <div className={`titles-label ${className}`}>
                    <Interweave content={expanded ? text : truncatedText} />
                </div>

            </div>
        );
    }

    const handleClick = () => {
        if (redirectLink !== null && redirectLink !== undefined && redirectLink !== '') {
            navigate(`${redirectLink}`, { replace: true , state: { myData: activeId }});
        } else {
            navigate(`/program/${programId}`, { replace: true });
        }
    };

    const StartingPosition = () => {
        console.log(modalData)
        return (
            <div className={"ViewModalContact"}>
                <div>
                    <div className={`titles-label`}>
                        <Interweave content={modalData} />
                    </div>

                </div>
            </div>
        )
    }
    return (
        <>
            <section className="change_level_002">
                <div className="container">
                    <div className="row">
                        {exerciseLevelsData.length !== 0 &&
                            <>
                        <div className="col-12 col-lg-10 col-md-9 col-sm-8 level01 order-3 order-sm-2 order-md-1">
                            <h4>Want to select a different level?</h4>
                            <p>If you select an exercise, it will replace the original exercise in your program. </p>
                            <p>You can change the exercise levels at any time.</p>
                        </div>
                            <div className="col-12 col-lg-2 col-md-3 col-sm-4 level01 order-2 order-md-2 order-sm-3 text-sm-end text-center">
                            <div className="position-relative">
                            <button type="button" className="theme_btn1" onClick={() => handleClick()}>Back</button>
                            </div>
                            </div>
                        </>
                        }

                        {exerciseLevelsData.length == 0 &&
                            <>
                                <div className="col-12 col-md-12 col-lg-12 mb-3"  id="checknew">
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <p className={'text-center'}>No levels options available for selected exercise</p>
                                        </div>
                                    </div>
                                </div>
                        <div className="col-12 col-lg-12 col-md-12 col-sm-12 level01  text-center">
                            <div className="position-relative">
                                <button type="button" className="theme_btn1" onClick={() => handleClick()}>Back</button>
                            </div>
                        </div>
                        </>
                        }
                        {exerciseLevelsData.length > 0 && exerciseLevelsData.map((item, index) => (
                            <div className="col-12 col-md-6 col-lg-6 order-3" key={index}>
                                <ul className="checkbox_list pd-list">
                                    <li>
                                        <label className="container-checkbox" id={'ex-' + index}>
                                            <span className=" checkmark main_check">
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 col-md-6">
                                                        <div className="box_levelimg box_levelimg2">
                                                            <PosterVideoPlayer videoUrl={item.video + '#t=' + item.video_start_time + ',' + item.video_end_time}
                                                                posterUrl={item.video_cover}
                                                                className={`${item.video_flip == true ? 'video flip-video' : 'video'}`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-6 col-md-6">
                                                          <div className={'change-level-info-wrapper'}>
                                                        <h6>{item.file_name ? item.file_name + ':' : ''} <span className={`level level${item.level_id}`}>Level {item.level_id}</span></h6>
                                                        {item.starting_position &&  <button type="button" className="changeLevelBtn" onClick={() => changeLevelModal(item.starting_position,"Starting Position")}>Starting Position</button>}
                                                        {item.movements &&  <button type="button" className="changeLevelBtn" onClick={() => changeLevelModal(item.movements,"Movement Instructions")}>Movement Instructions</button>}
                                                        {item.notes &&  <button type="button" className="changeLevelBtn" onClick={() => changeLevelModal(item.notes,"Notes")}>Notes</button>}
                                                        {item.metric == 10 ? <p><span className="ten">Reps: </span> {item.reps}</p> : ''}
                                                        {item.metric == 20 ? <p><span className="ten">Time: </span> {EXERCISE_TIMES[item.time]}</p> : ''}
                                                        <div className="level-change-btn-container">
                                                            <button type="button" className="level-change-btn w-50" onClick={() => changeLevelFunc(item.id)}>Change Level</button>
                                                        </div>
                                                          </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        ))
                        }
                    </div>
                    <div className="row mt-50">


                        {exerciseLevel1Equipments?.length > 0 &&
                            <div className="col-12 col-md-6 col-lg-6 " id="checknew">

                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="heading">Equipment Options</div>
                                    </div>
                                    {exerciseLevel1Equipments.map((item, index) => (
                                        <div className="col-12 col-md-12 col-lg-12" key={index}>
                                            <ul className="checkbox_list pd-list">
                                                <li>
                                                    <label className="container-checkbox" id={'ex-' + index}>
                                                        <span className=" checkmark main_check">
                                                            <div className="row">
                                                                <div className="col-12 col-lg-6 col-md-6">
                                                                    <div className="box_levelimg">
                                                                        <PosterVideoPlayer videoUrl={item.video + '#t=' + item.video_start_time + ',' + item.video_end_time}
                                                                            posterUrl={item.video_cover}
                                                                            className={`${item.video_flip == true ? 'video flip-video' : 'video'}`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-lg-6 col-md-6">
                                                                    {/* Equipment {item.equipment_exercise_type} */}
                                                                    <div className={'change-level-info-wrapper'}>
                                                                    <h6>{item.file_name ? item.file_name + ':' : ''} <span className={`level level${item.level_id}`}>Level {item.level_id}</span> </h6>
                                                                    {item.starting_position &&  <button type="button" className="changeLevelBtn" onClick={() => changeLevelModal(item.starting_position,"Starting Position")}>Starting Position</button>}
                                                                    {item.movements &&  <button type="button" className="changeLevelBtn" onClick={() => changeLevelModal(item.movements,"Movement Instructions")}>Movement Instructions</button>}
                                                                    {item.notes &&  <button type="button" className="changeLevelBtn" onClick={() => changeLevelModal(item.notes,"Notes")}>Notes</button>}
                                                                    {item.metric == 10 ? <p><span className="ten">Reps: </span> {item.reps}</p> : ''}
                                                                    {item.metric == 20 ? <p><span className="ten">Time: </span> {EXERCISE_TIMES[item.time]}</p> : ''}
                                                                    <div className="level-change-btn-container">
                                                                        <button type="button" className="level-change-btn w-50" onClick={() => changeLevelFunc(item.id)}>Change Level</button>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    ))
                                    }

                                </div>
                            </div>
                        }
                        {exerciseLevel1Equipments?.length === 0 && exercisesLevels?.level1?.length > 0 ?
                            <div className="col-12 col-md-6 col-lg-6" id="checknew">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="heading">Equipment Options</div>
                                        <p>No equipment options available for <span className="level">Level 1</span> of selected exercise</p>
                                    </div>
                                </div>
                            </div> : null
                        }
                        {exerciseLevel2Equipments?.length > 0 &&
                            <div className="col-12 col-md-6 col-lg-6 " id="checknew">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="heading">Equipment Options</div>
                                    </div>
                                    {exerciseLevel2Equipments.map((item, index) => (
                                        <div className="col-12 col-md-12 col-lg-12" key={index}>
                                            <ul className="checkbox_list pd-list">
                                                <li>
                                                    <label className="container-checkbox" id={'ex-' + index}>
                                                        <span className=" checkmark main_check">
                                                            <div className="row">
                                                                <div className="col-12 col-lg-6 col-md-6">
                                                                    <div className="box_levelimg">
                                                                        <PosterVideoPlayer videoUrl={item.video + '#t=' + item.video_start_time + ',' + item.video_end_time}
                                                                            posterUrl={item.video_cover}
                                                                            className={`${item.video_flip == true ? 'video flip-video' : 'video'}`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-lg-6 col-md-6">
                                                                    <div className={'change-level-info-wrapper'}>
                                                                    <h6>{item.file_name ? item.file_name + ':' : ''} <span className={`level level${item.level_id}`}>Level {item.level_id}</span>  </h6>
                                                                    {item.starting_position &&  <button type="button" className="changeLevelBtn" onClick={() => changeLevelModal(item.starting_position,"Starting Position")}>Starting Position</button>}
                                                                    {item.movements &&  <button type="button" className="changeLevelBtn" onClick={() => changeLevelModal(item.movements,"Movement Instructions")}>Movement Instructions</button>}
                                                                    {item.notes &&  <button type="button" className="changeLevelBtn" onClick={() => changeLevelModal(item.notes,"Notes")}>Notes</button>}
                                                                    {item.metric == 10 ? <p><span className="ten">Reps: </span> {item.reps}</p> : ''}
                                                                    {item.metric == 20 ? <p><span className="ten">Time: </span> {EXERCISE_TIMES[item.time]}</p> : ''}
                                                                    <div className="level-change-btn-container">
                                                                        <button type="button" className="level-change-btn w-50" onClick={() => changeLevelFunc(item.id)}>Change Level</button>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    ))
                                    }

                                </div>
                            </div>
                        }
                        {exerciseLevel2Equipments?.length === 0 && exercisesLevels?.level2?.length > 0 ?
                            <div className="col-12 col-md-6 col-lg-6 " id="checknew">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="heading">Equipment Options</div>
                                        <p>No equipment options available for <span className="level2">Level 2</span> of selected exercise</p>
                                    </div>
                                </div>
                            </div> : null
                        }
                        {exerciseLevel3Equipments?.length > 0 &&
                            <div className="col-12 col-md-6 col-lg-6 " id="checknew">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="heading">Equipment Options</div>
                                    </div>
                                    {exerciseLevel3Equipments.map((item, index) => (
                                        <div className="col-12 col-md-12 col-lg-12" key={index}>
                                            <ul className="checkbox_list pd-list">
                                                <li>
                                                    <label className="container-checkbox" id={'ex-' + index}>
                                                        <span className=" checkmark main_check">
                                                            <div className="row">
                                                                <div className="col-12 col-lg-6 col-md-6">
                                                                    <div className="box_levelimg">
                                                                        <PosterVideoPlayer videoUrl={item.video + '#t=' + item.video_start_time + ',' + item.video_end_time}
                                                                            posterUrl={item.video_cover}
                                                                            className={`${item.video_flip == true ? 'video flip-video' : 'video'}`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-lg-6 col-md-6">
                                                                    <h6>{item.file_name ? item.file_name + ':' : ''} <span className={`level level${item.level_id}`}>Level {item.level_id}</span>  </h6>
                                                                    {item.starting_position &&  <button type="button" className="changeLevelBtn" onClick={() => changeLevelModal(item.starting_position,"Starting Position")}>Starting Position</button>}
                                                                    {item.movements &&  <button type="button" className="changeLevelBtn" onClick={() => changeLevelModal(item.movements,"Movement Instructions")}>Movement Instructions</button>}
                                                                    {item.notes &&  <button type="button" className="changeLevelBtn" onClick={() => changeLevelModal(item.notes,"Notes")}>Notes</button>}
                                                                    {item.metric == 10 ? <p><span className="ten">Reps: </span> {item.reps}</p> : ''}
                                                                    {item.metric == 20 ? <p><span className="ten">Time: </span> {EXERCISE_TIMES[item.time]}</p> : ''}
                                                                    <div className="level-change-btn-container">
                                                                        <button type="button" className="level-change-btn w-50" onClick={() => changeLevelFunc(item.id)}>Change Level</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    ))
                                    }

                                </div>
                            </div>
                        }

                        {exerciseLevel3Equipments?.length === 0 && exercisesLevels?.level3?.length > 0 ?
                            <div className="col-12 col-md-6 col-lg-6 " id="checknew">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="heading">Equipment Options</div>
                                        <p>No equipment options available for <span className="level3">Level 3</span> of selected exercise</p>
                                    </div>
                                </div>
                            </div> : null
                        }
                    </div>
                </div>
            </section >
            <GeneralModal title={modalTitle} show={showStartModal} onHide={() => setShowStartFunc(false)} size={"md"} content={<StartingPosition/>} />
           <ChangeLevelModal handleClose={handleClose} show={show} handleSubmit={handleSubmit} />
        </>

    )
}

const mapStateToProps = (state, ownProps) => ({
    exerciseLevelsRequest: state.programReducer.exerciseLevelsRequest || null,
    exerciseLevelsSuccess: state.programReducer.exerciseLevelsSuccess || null,
    exerciseLevelsFailure: state.programReducer.exerciseLevelsFailure || null,
    exercisesLevels: state.programReducer.exercisesLevels,

    levelUpdateRequest: state.programReducer.levelUpdateRequest || null,
    levelUpdateSuccess: state.programReducer.levelUpdateSuccess || null,
    levelUpdateFailure: state.programReducer.levelUpdateFailure || null,
    levelUpdate: state.programReducer.levelUpdate,
    ownProps: ownProps
});

export default connect(mapStateToProps, { getExerciseLevelsData, updateExerciseLevel })(ChangeLevel2);