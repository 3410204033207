import React from 'react'

const Assessment1Day1 = ({ weeklySummary }) => {
  return (
    <section className="assestmentd1_001">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12 col-md-12 text-center">
            <h3>{!weeklySummary ? "Daily Summary" : "Cumulative Summary"}</h3>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Assessment1Day1