import React, { useEffect, useRef, useState } from 'react';
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import { Button, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap'
import Header from "../../Layout/Header";
import { Link, useNavigate } from "react-router-dom";
import Profile from "../../images/user-profile.png";
import { EditProfile, UploadIcon } from "../../../utils/svg";
import Footer from "../../Layout/Footer";
import { useForm } from "react-hook-form";
import { genders, s3Credential, weight_unit } from "../../../utils/constants";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { GetCountries } from "../../../actions/user_program";
import { connect, useDispatch } from "react-redux";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { getUserProfile, getPresignedPostUrl, updateUserProfile } from '../../../actions/auth';

function EditProfie({ GetCountries, countries, userProfile, getUserProfile, getPresignedPostUrl, profileUpload, profileUploadSuccess, updateUserProfile, profileUpdateSuccess }) {

    const { register, watch, setError, clearErrors, setValue, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });
    const [data, setData] = useState([]);
    const [age, setAge] = useState({ format: "mm/dd/yyyy" })
    const [pass, TogglePass] = useState(true);
    const [confPass, ToggleConfPass] = useState(true);
    const [confirmPass, setConfirmPass] = useState(true);
    const [phoneValue, setPhoneValue] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [country, setCountyList] = useState([]);
    const [selectCountry, setSelectCountry] = useState()
    const [img, setImg] = useState(null);
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        GetCountries()
        if (!userProfile) {
            getUserProfile()
        }
    }, [])

    useEffect(() => {
        if (userProfile) {
            setData(userProfile);
            setValue("name", userProfile?.name);
            setValue("email", userProfile?.email);
            setValue("phone", userProfile?.phone);
            setValue("gender", userProfile?.userDetail?.gender);
            setValue("age", userProfile?.userDetail?.dob);
            setValue("country", userProfile?.userDetail?.country);
            setValue("weight_unit", userProfile?.userDetail?.weight_unit);
            setPhoneValue(userProfile?.phone)
            setFile(userProfile?.userDetail?.image);
        }
    }, [userProfile])

    useEffect(() => {
        if (countries) {
            const reversedCountries = countries?.reverse();
            setCountyList(reversedCountries);
        }
    }, [countries])

    const password = useRef({});
    password.current = watch("password", "");

    const onSubmit = async data => {
        let date = new Date(data.age.toLocaleString());

        const currentDate = new Date();
        if (date > currentDate) {
            setError('age', { message: 'Age cannot be greater than current date' })
            return false
        } else {
            clearErrors('age')
        }

        let filledSocialLinks = [];
        if (data.facebook) {
            filledSocialLinks.push({
                'platform': 'facebook',
                'url': data.facebook,
            })
        }

        if (data.instagram) {
            filledSocialLinks.push({
                'platform': 'instagram',
                'url': data.instagram,
            })
        }

        if (data.linkedin) {
            filledSocialLinks.push({
                'platform': 'linkedin',
                'url': data.linkedin,
            })
        }

        let dt = {
            name: data.name,
            email: data.email,
            phone: data.phone,
            gender: data.gender,
            weight_unit: data.weight_unit,
            country: selectCountry,
            dob: data.age,
            image: img,
            social_links: filledSocialLinks
        }

        if (data.password && data.password_confirmation) {
            dt.password = data.password;
            dt.password_confirmation = data.password_confirmation;
        }

        try {
            if (phoneValue.length > 0) {
                clearErrors('phone')
                dt.phone = phoneValue
            } else {
                setError('phone', { message: 'Phone number required' });
                return false
            }
        } catch {
            return false
        }
        updateUserProfile(dt, userProfile.id)
    };


    const handleOnChange = (value, country) => {
        // validate phone number here
        if (!value) {
            setError('phone', { message: "Phone number is required" });
        } else if (!country || !country.dialCode) {
            setError('phone', { message: "Invalid country" });
        } else if (value.match(/12345/)) {
            setError('phone', { message: 'Invalid value: ' + value + ', ' + country.name });
        } else if (value.match(/1234/)) {
            setError('phone', { message: "Invalid phone" });
        } else {
            clearErrors('phone')
        }

        setPhoneValue(value);
    };

    const onAgeChange = (e) => {
        const object = e.target.value;
        const currentDate = new Date();
        const date = new Date(object);
        if (date > currentDate) {
            setError('age', { message: 'Age cannot be greater than current date' })
        } else {
            clearErrors('age')
            setAge(date.toLocaleDateString())
        }
    }

    const onCountryChange = (e) => {
        setSelectCountry(e.target.value)
    }

    function handleFileSelect(e) {
        e.preventDefault()
        const file = e.target.files[0];
        setImg((file) => e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
        getPresignedPostUrl(file.type, file.name, file, "user");
    }

    const handleImageUpload = async (presignedPostUrl) => {
        const formData = new FormData();
        Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
            formData.append(k, v);
        });
        formData.append('file', img);
        formData.append('folder_name', 'user');
        const response = await fetch(presignedPostUrl.url, {
            method: "POST",
            body: formData
        });
        if (response.status == 200 || response.status == 204) {
            setValue('image', s3Credential.s3EndPoint + profileUpload.fields.key)
            setImg((currentState) => s3Credential.s3EndPoint + profileUpload.fields.key)
            return true;
        }
        return false;
    }


    if (profileUploadSuccess !== null && profileUploadSuccess !== undefined) {
        dispatch({ type: 'USER_PROFILE_UPLOAD_PURGE' });
        if (profileUpload.fields.key) {
            handleImageUpload(profileUpload)
        }
    }

    if (profileUpdateSuccess !== null && profileUpdateSuccess !== undefined) {
        dispatch({ type: 'USER_PROFILE_UPDATE_PURGE' });
        navigate("/my-profile", { replace: true })
    }

    return (
        <>
            <ScrollTopOnMount />
            <Header />
            <section className="profile">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                            <div className="heading-wrapper">
                                <h3>
                                    My Profile
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <ul className="breadcrumb main">
                                <li className="">
                                    <Link to="/">Home </Link>
                                </li>
                                <li className="">
                                    <Link to="/my-profile"> My Profile</Link>
                                </li>
                                <li className="active">
                                    <Link to="/my-profile/edit-profile"> Edit Profile</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Form encType="multipart/form-data" className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-12 col-lg-3 col-md-5">
                                <div className="edit-img-cont">
                                    <Form.Group controlId="formFile" onChange={(e) => handleFileSelect(e)}
                                        className="custom-file-upload mb-0">
                                        <Form.Label className={"toggleFileUpload mb-0"}>
                                            <UploadIcon />
                                            Upload Image
                                        </Form.Label>
                                        <Form.Control type="file" accept="image/png, image/jpeg, image/jpg, image/gif" />
                                    </Form.Group>
                                    <img src={file != null && file != undefined && file != '' ? file : Profile} alt="User Profile" />
                                </div>
                                <input type={"submit"} className="update-profile" value={" Update Profile"} />
                            </div>
                            <div className="col-12 col-lg-5 col-md-7 mt-sm-3">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-lg-6 col-md-6">
                                        <Form.Group className="mb-3" controlId="full_name">
                                            <Form.Label>Full Name*</Form.Label>
                                            <Form.Control
                                                placeholder="Enter Full Name"
                                                id={"formSignUpName"}
                                                maxLength={25}
                                                minLength={3}
                                                {...register('name', {
                                                    required: {
                                                        value: "required",
                                                        message: "Full Name is required"
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: "Min length is 3"
                                                    },
                                                    maxLength: {
                                                        value: 25,
                                                        message: "Max length is 25"
                                                    },
                                                })} type="text"
                                            />
                                            {errors.name && <Form.Text
                                                className=" validationText hasError">{errors.name.message}</Form.Text>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-6 col-md-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Email Address*</Form.Label>
                                            <Form.Control
                                                id={"email"}
                                                disabled={true}
                                                placeholder="Enter Email Address"
                                                maxLength={255}
                                                {...register('email', {
                                                    required: {
                                                        value: "required",
                                                        message: "email is required"
                                                    },
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: "Entered value does not match email format"
                                                    },
                                                    maxLength: {
                                                        value: 255,
                                                        message: "max length is 255"
                                                    },
                                                })} type="email" />
                                            {errors.email && <Form.Text
                                                className=" validationText hasError">{errors.email.message}</Form.Text>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-6 col-md-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>DOB*</Form.Label>
                                            <Form.Control
                                                id={"age"}
                                                placeholder="Enter Age"
                                                value={age.date}
                                                maxLength={3}
                                                {...register('age', {
                                                    required: {
                                                        value: "required",
                                                        message: "Age is required"
                                                    },
                                                    onChange: (e) => onAgeChange(e),
                                                })} type="date"
                                            />
                                            {errors.age && <Form.Text
                                                className=" validationText hasError">{errors.age.message}</Form.Text>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-6 col-md-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Phone Number*</Form.Label>
                                            <PhoneInput
                                                id="phone"
                                                name="phone"
                                                className="phone-input"
                                                limitMaxLength={20}
                                                international
                                                placeholder="Enter phone number"
                                                value={phoneValue}
                                                defaultCountry="US"
                                                onChange={handleOnChange}

                                            />
                                            {errors.phone && <Form.Text className=" validationText hasError">{errors.phone.message}</Form.Text>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-6 col-md-6">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Gender*</Form.Label>
                                            <Form.Select
                                                id={"gender"}
                                                type="text"
                                                placeholder="Select Gender"
                                                {...register("gender", {
                                                    required: {
                                                        value: "required",
                                                        message: "gender is required"
                                                    }
                                                }
                                                )}
                                            >
                                                <option selected disabled value="">Select Gender</option>
                                                <option value={genders.MALE}>Male</option>
                                                <option value={genders.FEMALE}>Female</option>
                                            </Form.Select>
                                            {errors.gender && <Form.Text
                                                className=" validationText hasError">{errors.gender.message}</Form.Text>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-6 col-md-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Weight Unit</Form.Label>
                                            <Form.Select
                                                placeholder="Select Weight Unit"
                                                id={"weight_unit"}
                                                {...register("weight_unit", {
                                                    required: {
                                                        value: "required",
                                                        message: " Weight unit is required"
                                                    }
                                                }
                                                )}>
                                                <option value="" selected={true} disabled={true}>Select Weight Unit</option>
                                                <option value={weight_unit.KG}>Kg</option>
                                                <option value={weight_unit.LBS}>Lbs</option>
                                            </Form.Select>
                                            {errors.weight_unit && <Form.Text
                                                className=" validationText hasError">{errors.weight_unit.message}</Form.Text>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-6 col-md-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Country</Form.Label>
                                            <Form.Select
                                                id={"country"}
                                                {...register("country", {
                                                    onChange: (e) => onCountryChange(e),
                                                }
                                                )}
                                            >
                                                <option value="" selected={true} disabled={true}>Select Country</option>
                                                {
                                                    country &&
                                                    country?.map((country, index) => (
                                                        <option value={country.id} selected={country.id == data?.userDetail?.country ? true : false}>{country.nicename}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                            {errors.country && <Form.Text
                                                className=" validationText hasError">{errors.country.message}</Form.Text>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-6 col-md-6">
                                        <div className={"pass-field mb-3"}>
                                            <Form.Label>Password*</Form.Label>
                                            <InputGroup >
                                                <FormControl
                                                    id={"password"}
                                                    placeholder="Enter Password"
                                                    autoComplete={"off"}
                                                    maxLength={255}
                                                    minLength={8}
                                                    {...register('password', {
                                                        minLength: {
                                                            value: 8,
                                                            message: "Min length is 8"
                                                        },
                                                        maxLength: {
                                                            value: 255,
                                                            message: "Max length is 255"
                                                        },
                                                        required: false
                                                    })}
                                                    onClick={() => setConfirmPass(!confirmPass)}
                                                    type={pass ? "password" : "text"} />
                                                <Button variant="outline-secondary" id="button-addon2" onClick={() => TogglePass(!pass)}>
                                                    {
                                                        pass ? <FaEye /> : <FaEyeSlash />
                                                    }
                                                </Button>
                                            </InputGroup>
                                            {errors.password && <Form.Text className=" validationText hasError">{errors.password.message}</Form.Text>}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-6 col-md-6">
                                        <div className={"pass-field mb-3"}>
                                            <Form.Label>Confirm Password*</Form.Label>
                                            <InputGroup >
                                                <Form.Control
                                                    id={"password_confirmation"}
                                                    placeholder="Enter Password Again"
                                                    disabled={confirmPass}
                                                    maxLength={255}
                                                    minLength={8}
                                                    {...register('password_confirmation', {
                                                        minLength: {
                                                            value: 8,
                                                            message: "Min length is 8"
                                                        },
                                                        validate: password.current ? value => value === password.current || "The passwords do not match" : '',
                                                        maxLength: {
                                                            value: 255,
                                                            message: "Max length is 255"
                                                        },
                                                        required: false
                                                    })}
                                                    type={confPass ? "password" : "text"}
                                                />
                                                <Button variant="outline-secondary" id="button-addon22" disabled={confirmPass} onClick={() => ToggleConfPass(!confPass)}>
                                                    {
                                                        confPass ? <FaEye /> : <FaEyeSlash />
                                                    }
                                                </Button>
                                            </InputGroup>
                                            {errors.password_confirmation && <Form.Text className=" validationText hasError">{errors.password_confirmation.message}</Form.Text>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </section>
            <Footer />
        </>
    );
}
const mapStateToProps = state => {
    return {
        countries: state.programReducer.countries,
        userProfileRequest: state.auth.userProfileRequest || null,
        userProfileSuccess: state.auth.userProfileSuccess || null,
        userProfileFailure: state.auth.userProfileFailure || null,
        userProfile: state.auth.userProfile,

        profileUploadRequest: state.auth.profileUploadRequest || null,
        profileUploadSuccess: state.auth.profileUploadSuccess || null,
        profileUploadFailure: state.auth.profileUploadFailure || null,
        profileUpload: state.auth.profileUpload,

        profileUpdateRequest: state.auth.profileUpdateRequest || null,
        profileUpdateSuccess: state.auth.profileUpdateSuccess || null,
        profileUpdateFailure: state.auth.profileUpdateFailure || null,
    }
}
export default connect(mapStateToProps, { GetCountries, getUserProfile, getPresignedPostUrl, updateUserProfile })(EditProfie)