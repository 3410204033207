import React from 'react'
import {Link} from 'react-router-dom'
const Home3 = () => {
  return (
    <section className="home_003">
    <div className="container  ">
        <div className="row  ">
            <div className="col-12 col-lg-6 col-md-6 m-auto  ">
                <div className="text-center">
                    <h2>Do you have muscle imbalances?
                    </h2>
                    <Link to="/welcome" className="theme_btn1">click here to take our assessment</Link>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Home3