import React from 'react'
import img6 from '../../images/img6.png'
const Home8 = () => {
  return (
    <section className="home_008">
    <div className="container h-100">
        <div className="row align-items-center h-100">

            <div className="col-12 col-lg-5 col-md-5 my-auto or2">
                <div className="">
                    <h2>Even the way we
                        sit, sleep, and drive
                        will teach our body
                        to stay in that <span>position</span>
                    </h2>
                </div>
            </div>
            <div className="col-12 col-lg-6 col-md-6 or1 ">
                <img src={img6}  alt="img6" className="img-fluid show_mob" />
            </div>
        </div>
    </div>
</section>
  )
}

export default Home8