import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import './css/plugins.css';
import './css/custom.css';
import './css/responsive.css';
import './fonts/stylesheet.css';
import './css/beta.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);


