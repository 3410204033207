import React from "react";
import Modal from "react-bootstrap/Modal";
import imgNotFound from "../../components/images/broken.png"
import CustomVideoPlayer from "./AssessmentTimer/CustomVideoPlayer";

const EducationVideoModal = ({ show, handleClose, testVideos }) => {
    return (
        <>
            <Modal show={show} onHide={handleClose} className="modal_test vedio_modal" centered>
                <Modal.Header>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleClose}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {testVideos.map((item, index) =>
                        <>
                            {item.educational_video ?
                                <CustomVideoPlayer videoUrl={item.educational_video ? item.educational_video : ''} className={'video'} />
                                // <video
                                //     className="video"
                                //     controls="controls"
                                //     width="100%"
                                //     height="100%"
                                //     key={index}
                                //     // crossOrigin='anonymous'
                                //     onError={({ currentTarget }) => {
                                //         currentTarget.onerror = null;
                                //         currentTarget.poster = imgNotFound;
                                //     }}
                                // >
                                //     <source src={item.educational_video ? item.educational_video : ''} />
                                // </video>
                                :
                                ''
                            }
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EducationVideoModal;
