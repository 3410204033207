import React, { useEffect, useState } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Welcome1 from "./Welcome1";
import TimerWeek1 from "./TimerWeek1";
import Welcome2 from "./Welcome2";
import Test1Week1 from "./Test1Week1";
import { FadeLoader } from "react-spinners";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getExerciseDetail, getProgram } from "../../../actions/user_program";
import { useLocation } from 'react-router-dom';

const AssessmentTimer1 = ({ program, getProgram, getExerciseDetail, exercises }) => {
  const [activeDay, setActiveDay] = useState(localStorage.getItem('activeDayId') ? localStorage.getItem('activeDayId') : null)
  const [activeDayName, setActiveDayName] = useState(localStorage.getItem('activeDayName') ? localStorage.getItem('activeDayName') : null)
  const [activeExercise, setActiveExercise] = useState( null)
  const location = useLocation();
  let CurrentActiveExercise = location.state?.myData;
  const [activeCurrentExercise, setActiveCurrentExercise] = useState(CurrentActiveExercise)
  const [showExercise, setShowExercise] = useState(null)
  const [nextExercise, setNextExercise] = useState(null)

  useEffect(() => {
    if (!program) {
      getProgram()
    }
  }, [])

  useEffect(() => {
    const topElement = document.querySelector('.welcome_001'); // Replace 'top-element' with the actual ID of your top element

    if (topElement) {
      topElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // Scroll to the top of the element
      });
    }
  }, []);

  useEffect(() => {
    if (activeDay !== null) {
      getExerciseDetail(activeDay)
    }
  }, [activeDay])

  // useEffect((exercises) => {
  //
  //   if (exercises && exercises.length > 0 && activeCurrentExercise !== undefined && activeCurrentExercise !== null) {
  //     let activeEx = exercises?.filter((item, index) => item?.id == activeCurrentExercise);
  //     if(activeEx?.length > 0  ){
  //       let nextIndex = exercises.findIndex((item, index) => item.id == activeCurrentExercise) + 1;
  //       setActiveExercise(activeEx[0].id)
  //       setShowExercise(activeEx);
  //       setNextExercise(exercises[nextIndex] !== undefined && exercises[nextIndex] !== null ? exercises[nextIndex].id : null)
  //     }else{
  //       setActiveExercise(exercises[0].id)
  //       setShowExercise([exercises[0]]);
  //       setNextExercise(exercises[1] !== undefined && exercises[1] !== null ? exercises[1].id : null)
  //     }
  //     setActiveCurrentExercise( null)
  //   }else if (exercises && exercises.length > 0) {
  //     let activeEx = exercises.filter((item, index) => item.is_started == true);
  //     if(activeEx?.length > 0  ){
  //       let nextIndex = exercises.findIndex((item, index) => item.is_started == true) + 1;
  //       setActiveExercise(activeEx[0].id)
  //       setShowExercise(activeEx);
  //       setNextExercise(exercises[nextIndex] !== undefined && exercises[nextIndex] !== null ? exercises[nextIndex].id : null)
  //     }else{
  //       setActiveExercise(exercises[0].id)
  //       setShowExercise([exercises[0]]);
  //       setNextExercise(exercises[1] !== undefined && exercises[1] !== null ? exercises[1].id : null)
  //     }
  //   }
  // }, [exercises])
  //
  // useEffect(() => {
  //   if(exercises && exercises.length > 0 && activeCurrentExercise !== undefined && activeCurrentExercise !== null){
  //     let activeEx = exercises.filter((item, index) => item.id == activeCurrentExercise);
  //     if(activeEx?.length > 0  ){
  //       let nextIndex = exercises.findIndex((item, index) => item.id == activeCurrentExercise) + 1;
  //       setActiveExercise(activeEx[0].id)
  //       setShowExercise(activeEx);
  //       setNextExercise(exercises[nextIndex] !== undefined && exercises[nextIndex] !== null ? exercises[nextIndex].id : null)
  //       setActiveCurrentExercise( null)
  //     }
  //   }else if(exercises && exercises.length > 0 && activeExercise ) {
  //     let activeEx = exercises.filter((item, index) => item.id == activeExercise);
  //     if(activeEx?.length > 0  ){
  //       let nextIndex = exercises.findIndex((item, index) => item.id == activeExercise) + 1;
  //       setActiveExercise(activeEx[0].id)
  //       setShowExercise(activeEx);
  //       setNextExercise(exercises[nextIndex] !== undefined && exercises[nextIndex] !== null ? exercises[nextIndex].id : null)
  //     }
  //   }else if (exercises && exercises.length > 0 && !activeExercise) {
  //     let activeEx = exercises.filter((item, index) => item.is_started == true);
  //     if(activeEx?.length > 0  ){
  //       let nextIndex = exercises.findIndex((item, index) => item.is_started == true) + 1;
  //       setActiveExercise(activeEx[0].id)
  //       setShowExercise(activeEx);
  //       setNextExercise(exercises[nextIndex] !== undefined && exercises[nextIndex] !== null ? exercises[nextIndex].id : null)
  //     }else{
  //       setActiveExercise(exercises[0].id)
  //       setShowExercise([exercises[0]]);
  //       setNextExercise(exercises[1] !== undefined && exercises[1] !== null ? exercises[1].id : null)
  //     }
  //   }
  // }, [activeExercise,exercises])

  useEffect(() => {
    if (!exercises || exercises.length === 0) return;

    let activeEx;
    let nextIndex;

    if (activeCurrentExercise !== undefined && activeCurrentExercise !== null) {
      activeEx = exercises.find((item) => item.id === activeCurrentExercise);
      nextIndex = exercises.findIndex((item) => item.id === activeCurrentExercise) + 1;
    } else if (activeExercise) {
      activeEx = exercises.find((item) => item.id === activeExercise);
      nextIndex = exercises.findIndex((item) => item.id === activeExercise) + 1;
    } else {
      activeEx = exercises.find((item) => item.is_started === true);
      nextIndex = exercises.findIndex((item) => item.is_started === true) + 1;
    }

    setActiveExercise(activeEx?.id || exercises[0].id);
    setShowExercise([activeEx] || [exercises[0]]);
    setNextExercise(exercises[nextIndex]?.id || null);
    setActiveCurrentExercise(null);
  }, [activeExercise, activeCurrentExercise, exercises]);


  const setActiveExerciseFunc = (data) => {
    setActiveExercise(data)
  }

  const setRepeatCircuitFunc = (data) => {
    if (data === true) {
      if (exercises && exercises.length > 0) {
        let index = exercises.findIndex((item, index) => index == 0)
        let nextIndex = exercises.findIndex((item, index) => index == 0) + 1
        setActiveExerciseFunc(index)
        setShowExercise(exercises.filter((item, index) => index == 0));
        setNextExercise(exercises[nextIndex] !== undefined && exercises[nextIndex] !== null ? exercises[nextIndex].id : null)
      }
    }
  }

  if (!program) {
    return (
      <section className="welcome_001">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 col-lg-6 col-md-6 m-auto">
              <div className="box_banner_inner text-center mx-auto">
                <FadeLoader color={"#FB1417"} height={10} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      <Header />
      <Welcome1 program={program}  exercises={true} />
      <TimerWeek1 exercises={exercises ? exercises : []} activeExercise={activeExercise} setActiveExercise={setActiveExerciseFunc} />
      <Welcome2 activeDay={activeDayName} program={program} />
      <Test1Week1 showExercise={showExercise && showExercise[0] ? showExercise[0] : null} activeDay={activeDay} program={program} setActiveExercise={setActiveExerciseFunc} nextExercise={nextExercise} setRepeatCircuit={setRepeatCircuitFunc} />
      <Footer />
    </>
  );
};

AssessmentTimer1.propTypes = {
  program: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  exerciseDetailRequest: state.programReducer.exerciseDetailRequest || null,
  exerciseDetailSuccess: state.programReducer.exerciseDetailSuccess || null,
  exerciseDetailFailure: state.programReducer.exerciseDetailFailure || null,
  exercises: state.programReducer.exercises,
  program: state.programReducer.program,
});
export default connect(mapStateToProps, { getProgram, getExerciseDetail })(AssessmentTimer1);
