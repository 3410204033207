import React, { useEffect, useState } from "react";

import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Welcome1 from "./Welcome1";
import Assessment1Day1 from "./Assessment1Day1";
import Welcome2 from "./Welcome2";
import Assessment2Day1 from "./Assessment2Day1";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { getExerciseSummaryDetail, getProgram, countSkipExercise } from "../../../actions/user_program";
import PropTypes from "prop-types";

const Weekly = ({ program, getProgram, getExerciseSummaryDetail, exercises, countSkipExercise, skipExerciseCount }) => {
  let { id } = useParams()
  const [exercisePercentage, setExercisePercentage] = useState(100);
  const [weeklySummary, setWeeklySummary] = useState(0);
  const [repsPercentage, setRapsPercentage] = useState(0);
  const [timePercentage, setTimePercentage] = useState(0);
  useEffect(() => {
    if (!program) {
      getProgram()
    }
  }, [])

  useEffect(() => {
    if (id) {
      getExerciseSummaryDetail(id, weeklySummary)
      countSkipExercise(id, weeklySummary)
    }
  }, [id, weeklySummary])


  const countAverageReps = (data) => {
    let averageReps = 0
    let repsCount = data.length
    data.map((item, key) => {
      averageReps += averageReps + parseInt(item.reps)
    })
    return Math.round(averageReps)
  }

  function getSeconds(timeString) {
    if (timeString && timeString != 0) {
      const [minutes, seconds] = timeString.split(' ');
      const totalSeconds = parseInt(minutes) * 60 + parseInt(seconds);
      return totalSeconds;
    }
    return 0;
  }

  function getMinTime(totalSeconds) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}min ${seconds}sec`;
  }

  const countAverageTime = (data) => {
    let averageTime = 0
    let timeCount = data.length
    data.map((item, key) => {
      averageTime += parseInt(item.duration)
    })
    if (averageTime != 0 && averageTime != undefined) {
      return Math.round(averageTime)
    }
    return 0
  }

  useEffect(() => {
    let totalTimeSec = 0;
    let totalRaps = 0

    let userTotalTimeSec = 0;
    let userTotalRaps = 0

    if (skipExerciseCount && exercises) {
      let exercisesTotal = exercises.length
      let exercisesSkipTotal = skipExerciseCount.totalSkipExercises ? skipExerciseCount.totalSkipExercises : 0
      if (exercisesSkipTotal != null && exercisesSkipTotal != 0) {
        setExercisePercentage(((exercisesTotal - exercisesSkipTotal) / exercisesTotal) * 100)
      }
    }

    if (exercises && exercises.length > 0) {
      exercises.map((item, index) => {
        if (item.exerciseLevel.time && item.exerciseLevel.metric == 20) {
          totalTimeSec += parseInt(item.exerciseLevel.time * 60);
          userTotalTimeSec += parseInt(item.userProgramDayExercisesAttempt ? countAverageTime(item.userProgramDayExercisesAttempt) : 0)
        }
        if (item.exerciseLevel.reps && item.exerciseLevel.metric == 10) {
          totalRaps += parseInt(item.exerciseLevel.reps != undefined ? item.exerciseLevel.reps : 0);
          userTotalRaps += parseInt(item.userProgramDayExercisesAttempt ? countAverageReps(item.userProgramDayExercisesAttempt) : 0)
        }
      })

      setTimePercentage(((userTotalTimeSec) / totalTimeSec) * 100)
      setRapsPercentage(((userTotalRaps) / totalRaps) * 100)
    }
  }, [skipExerciseCount, exercises])

  const setWeeklySummaryFunc = (data) => {
    setWeeklySummary(data)
  }

  if (!program) {
    return (
      <section className="welcome_001">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 col-lg-6 col-md-6 m-auto">
              <div className="box_banner_inner text-center">
                <FadeLoader color={"#FB1417"} height={10} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <div>
      <Header />
      {/*<Welcome1 program={program} />*/}
      <Welcome2 activeDayName={localStorage.getItem('activeDayName') ? localStorage.getItem('activeDayName') : ''} activeDay={id} program={program} setWeeklySummary={setWeeklySummaryFunc} weeklySummary={weeklySummary} />
      <Assessment1Day1 weeklySummary={weeklySummary} />
      <Assessment2Day1 exercises={exercises ? exercises : []} dayId={id ? id : ''} programId={program ? program.id : ''} exercisePercentage={exercisePercentage ? exercisePercentage : 0} repsPercentage={repsPercentage ? repsPercentage : 0} timePercentage={timePercentage ? timePercentage : 0} />
      <Footer />
    </div>
  );
};

Weekly.propTypes = {
  program: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  exerciseSummaryRequest: state.programReducer.exerciseSummaryRequest || null,
  exerciseSummarySuccess: state.programReducer.exerciseSummarySuccess || null,
  exerciseSummaryFailure: state.programReducer.exerciseSummaryFailure || null,

  skipExerciseCountRequest: state.programReducer.skipExerciseCountRequest || null,
  skipExerciseCountSuccess: state.programReducer.skipExerciseCountSuccess || null,
  skipExerciseCountFailure: state.programReducer.skipExerciseCountFailure || null,
  skipExerciseCount: state.programReducer.skipExerciseCount,

  exercises: state.programReducer.exercises,
  program: state.programReducer.program,
});
export default connect(mapStateToProps, { getProgram, getExerciseSummaryDetail, countSkipExercise })(Weekly);