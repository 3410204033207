import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import avatar from "../images/avatar.png";
// import "../../css/circular-prog-bar.css";
import {getAuthProfile, logout} from '../../actions/auth';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import HowToUseModal from "./HowToUseModal";
import {getProgram} from "../../actions/user_program";


const Header = ({ logout, profilePicture, logoutSuccess, logoutRequest, logoutFailure,getAuthProfile,program }) => {

    const [mobileMenu, setMobileMenu] = useState(false);
    useEffect(()=>{
        getAuthProfile()
    },[])

    let navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(true)
    };
    const handleClose = () => setShow(false);
    const onCloseClick = () => {
        setMobileMenu(false)
    }
    const logoutAccount = (e) => {
        e.preventDefault()
        logout()
    }

    if (logoutSuccess) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        navigate("/", { replace: true })
    }
    let user = localStorage.getItem('user')
    user = user ? JSON.parse(user) : null
    return (
        <>
            <div className="overlay"></div>
            <header className="header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-2 col-md-2 ">
                            <div className="mobile_setting">
                                <div className="logo">
                                    <Link to="/home">
                                        <img src={logo} alt="logo" className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="dropdown_user">
                                    <div className="dropdown">
                                        <Link
                                            className="h_login"
                                            to="#"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <span className="img_user">
                                                <img src={avatar} alt="user1" className="img-fluid" />
                                            </span>

                                            <svg
                                                width="14"
                                                height="9"
                                                viewBox="0 0 14 9"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M7 7L6.29289 7.70711L7 8.41421L7.70711 7.70711L7 7ZM0.292893 1.70711L6.29289 7.70711L7.70711 6.29289L1.70711 0.292893L0.292893 1.70711ZM7.70711 7.70711L13.7071 1.70711L12.2929 0.292894L6.29289 6.29289L7.70711 7.70711Z"
                                                    fill="#CCD2E3"
                                                />
                                            </svg>
                                        </Link>
                                        <div
                                            className="dropdown-menu menu_top1"
                                            aria-labelledby="dropdownMenuButton"
                                        >
                                            <Link className="dropdown-item profile_h" to="#">
                                                My Profile
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <span className="icon_mobile" onClick={() => {
                                    setMobileMenu(!mobileMenu)
                                }}>
                                    <i className="fas fa-bars"></i>
                                </span>
                            </div>
                        </div>
                        <div
                            className={`col-12 col-lg-10 col-md-10 text-right hide_mobile ${mobileMenu ? "show_mobile_menu" : ""}`}>
                            <span className="show_mobile close1" onClick={() => onCloseClick()}>
                                <i className="far fa-times-circle"></i>
                            </span>
                            <div className="main_head">
                                <ul className="menu_top right_left pl-0">
                                    <li className="dropdown_user mobile-profile">
                                        <div className="dropdown">
                                            <Link
                                                className="h_login"
                                                to="#"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <span className="img_user">
                                                    <img src={user && user?.userDetail?.image ? user.userDetail?.image : avatar} alt="user1" className="img-fluid" />
                                                </span>
                                                <span>{user ? user.name : "-"}</span>
                                                <svg
                                                    width="14"
                                                    height="9"
                                                    viewBox="0 0 14 9"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M7 7L6.29289 7.70711L7 8.41421L7.70711 7.70711L7 7ZM0.292893 1.70711L6.29289 7.70711L7.70711 6.29289L1.70711 0.292893L0.292893 1.70711ZM7.70711 7.70711L13.7071 1.70711L12.2929 0.292894L6.29289 6.29289L7.70711 7.70711Z"
                                                        fill="#CCD2E3"
                                                    />
                                                </svg>
                                            </Link>
                                            <div
                                                className="dropdown-menu menu_top1"
                                                aria-labelledby="dropdownMenuButton"
                                            >
                                                <Link className="dropdown-item profile_h" to="/my-profile">
                                                    My Profile
                                                </Link>
                                                <Link className="dropdown-item profile_h" to="#"
                                                      onClick={logoutAccount}>
                                                    Logout
                                                </Link>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <Link to="/pages/about-us">About</Link>
                                    </li>
                                    <li>
                                        <Link to="/educational-videos" className="h_login">
                                            education
                                        </Link>
                                    </li>
{}
                                    <li>
                                        <Link to={user?.userActivePrograms?.length > 0 ? `/program/${user?.userActivePrograms[0]?.program?.id}`:'/welcome'} className="h_login">
                                            my programs
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="#" className="h_login">
                                            equipment
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to="/recommended-products" className="h_login">
                                            Recommended Products
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/muscles-anatomy/library" className="h_login">
                                           Muscle Library
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/trigger-pain-point/library" className="h_login">
                                            Trigger Point Library
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="#" onClick={handleShow} className="h_login">
                                            How to use the app
                                        </Link>
                                    </li> */}
                                </ul>
                                <ul className="menu_top right_menu">
                                    <li className="dropdown_user hide_mobile">
                                        <div className="dropdown">
                                            <a
                                                className="h_login"
                                                href="javascript:void(0)"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <span className="img_user">
                                                    <img src={user && user.userDetail?.image ? user.userDetail?.image : avatar} alt="user1" className="img-fluid" />
                                                </span>
                                                {user ? <span>{user ? user.name : "-"}</span> :null}

                                                <svg
                                                    width="14"
                                                    height="9"
                                                    viewBox="0 0 14 9"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M7 7L6.29289 7.70711L7 8.41421L7.70711 7.70711L7 7ZM0.292893 1.70711L6.29289 7.70711L7.70711 6.29289L1.70711 0.292893L0.292893 1.70711ZM7.70711 7.70711L13.7071 1.70711L12.2929 0.292894L6.29289 6.29289L7.70711 7.70711Z"
                                                        fill="#CCD2E3"
                                                    />
                                                </svg>
                                            </a>
                                            <div
                                                className="dropdown-menu menu_top1"
                                                aria-labelledby="dropdownMenuButton"
                                            >
                                                <Link className="dropdown-item profile_h" to="/my-profile">
                                                    My Profile
                                                </Link>
                                                <Link className="dropdown-item profile_h" to="#"
                                                    onClick={logoutAccount}>
                                                    Logout
                                                </Link>

                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <HowToUseModal handleClose={handleClose} show={show} />
        </>
    );
};

Header.propTypes = {
    // isAuthenticated: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    profilePicture: PropTypes.string.isRequired,
    logoutRequest: PropTypes.bool,
    logoutSuccess: PropTypes.bool,
    logoutFailure: PropTypes.bool,
}

const mapStateToProps = state => ({
    profilePicture: state.auth.profilePicture,
    program: state.programReducer.program,
    logoutFailure: state.auth.logoutFailure || null,
    logoutSuccess: state.auth.logoutSuccess || null,
    logoutRequest: state.auth.logoutRequest || null,
})
export default connect(mapStateToProps, { logout,getProgram,getAuthProfile })(Header)
