import React, {useEffect, useRef, useState} from "react";
import { connect, useDispatch } from "react-redux";
import { checkPreviousDay } from "../../../actions/user_program";
import { Link, useNavigate } from "react-router-dom";
import { Interweave } from "interweave";
import StatusCheckinModal from "./StatusCheckinModal";
import { CHECK_PREVIOUS_DAY_PURGE } from "../../../actions/types";
import {cross_origin, EXERCISE_TIMES} from "../../../utils/constants";
import CustomVideoPlayer from "../AssessmentTimer/CustomVideoPlayer";
import {useReactToPrint} from "react-to-print";
import {Button} from "react-bootstrap";
import Broken from '../../images/NoImages.png'

const Exercises = ({ ownProps, checkPreviousDay, checkPreviousDaySuccess, previous_day, view, start, setStart }) => {
    let { activeDayId } = ownProps
    let { exercises } = ownProps
    let { programId } = ownProps
    const dispatch = useDispatch();
    const printGridRef = useRef(null)
    const printListRef = useRef(null)
    const navigate = useNavigate();
    const [showExercise, setShowExercise] = useState(null)
    const [user, setUser] = useState()
    const [showStatusInModal, setShowStatusInModal] = useState(false)
    const [preDay, setPreDay] = useState(localStorage.getItem('lastActiveDayId') ? localStorage.getItem('lastActiveDayId') : null);
    const handleStatusInModal = () => setShowStatusInModal(false);
    const ScrollRef = useRef(null);
    useEffect(() => {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        setUser(user)
        setShowExercise(null)
        if (exercises && exercises != null && exercises.length > 0) {
            setShowExercise(exercises[0] ? exercises[0] : null)
        }
    }, [exercises])

    const handleShowExercise = (exercise) => {
        setShowExercise(exercise)
        setTimeout(() => {
            ScrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    }

    useEffect(() => {
        if (start) {
            handleStartTest()
            setStart(false)
        }
    }, [start])

    const handleStartTest = (id) => {
        localStorage.setItem('activeExerciseId', id)
        if (preDay) {
            checkPreviousDay(preDay)
        } else {
            navigate(`/test-instructions/${programId}`, { replace: true });
        }
    }
    const handlePrintGridSummary = useReactToPrint(
             { content: () => printGridRef.current }
    );

    const handlePrintListSummary = useReactToPrint(
        { content: () => printListRef.current }
    );

    if (checkPreviousDaySuccess !== undefined && checkPreviousDaySuccess !== false && previous_day !== null && previous_day !== undefined && !showStatusInModal) {
        dispatch({ type: CHECK_PREVIOUS_DAY_PURGE })
        setPreDay(previous_day.id)
        setShowStatusInModal(true)
    } else if (checkPreviousDaySuccess !== undefined && checkPreviousDaySuccess !== false && previous_day == null) {
        dispatch({ type: CHECK_PREVIOUS_DAY_PURGE })
        navigate(`/test-instructions/${programId}`, { replace: true });
    }

    const convertToEquipmentsCSV = (equipments) => {
        const titles = equipments.map(equipment => equipment.title);
        return titles.join(',');
    };

    return (
        <section className="grid_001">
            <div className="container">
                <div className="row mt-3">
                    <div className="col-12 col-lg-6 col-md-6 mt-3">
                        {view ? <Button type='button' onClick={()=> handlePrintListSummary()} className="theme_btn1 small_btn">Print Summary</Button>
                            : <Button type='button' onClick={() => handlePrintGridSummary()} className="theme_btn1 small_btn">Print Summary</Button>}

                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12 mb-4 ">
                        {!view ?
                            <ul className="nav nav-tabs row left_tabs slider_mobile" role="tablist" ref={printGridRef} >
                                {exercises && exercises.map((item, key) =>
                                    <li key={key} className="nav-item col-6 col-lg-4 col-xl-3 mb-4">
                                        <Link
                                            className={showExercise && item.id == showExercise.id ? "nav-link active" : "nav-link"}
                                            data-toggle="tab"
                                            to="#tabs-1"
                                            role="tab"
                                            onClick={() => {
                                                handleShowExercise(item)
                                            }}
                                        >
                                            <div className="box_levelimg1 box_levelimg_1">
                                                <img
                                                    src={item.exerciseLevel && item.exerciseLevel.video_cover ? item.exerciseLevel.video_cover : ''}
                                                    alt={item.exerciseLevel && item.exerciseLevel.video_cover ? item.exerciseLevel.video_cover : ''}
                                                    className="img-fluid"
                                                    crossOrigin={cross_origin ? "anonymous" : null}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = Broken;
                                                    }}
                                                />
                                            </div>
                                            <div className="title_box">
                                                <h6>{item.exerciseLevel && item.exerciseLevel?.file_name ? item.exerciseLevel?.file_name : ''}</h6>
                                                <div className="icon_level">
                                                    {/* <svg
                                                        width="37"
                                                        height="37"
                                                        viewBox="0 0 37 37"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="18.1211"
                                                            cy="18.3081"
                                                            r="18"
                                                            fill="black"
                                                        ></circle>
                                                        <rect
                                                            x="10.9209"
                                                            y="18.6885"
                                                            width="3.32275"
                                                            height="9.53105"
                                                            fill="#1AE074"
                                                        ></rect>
                                                        <rect
                                                            x="15.9927"
                                                            y="13.5295"
                                                            width="3.32275"
                                                            height="14.6901"
                                                            fill="#9B9B9B"
                                                        ></rect>
                                                        <rect
                                                            x="21.4141"
                                                            y="8.10806"
                                                            width="3.32275"
                                                            height="20.1114"
                                                            fill="#9B9B9B"
                                                        ></rect>
                                                    </svg> */}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                )}
                            </ul>
                            :
                            <>
                                {/* <div className="detail_cir_content text-center pt-4 mb-2">
                                    <h2 className="detail_span text-center">1x Through = One Circuit</h2>
                                    <h4>You can perform up to 3 circuits</h4>
                                    <div className="main_circuit text-center pt-2">
                                        <div className="circuit text pb-2">
                                            <h4 className="d-inline-block">Circuit : 3</h4>
                                            <i className="fas fa-info-circle" data-toggle="modal" data-target="#infoModal"></i>
                                        </div>

                                    </div>
                                </div> */}
                                <ul className="nav nav-tabs row left_tabs slider_mobile" role="tablist" ref={printListRef} >
                                    {exercises != null && exercises.length > 0 ? exercises.map((item, key) =>
                                        <div className="row d-flex justify-content-center mb-4">
                                            <li key={key} className="nav-item col-12 col-sm-10 col-lg-8 col-xl-6">
                                                <Link
                                                    className={showExercise && item.id == showExercise.id ? "nav-link active px-4 py-4" : "nav-link px-4 py-4"}
                                                    data-toggle="tab"
                                                    to="#tabs-1"
                                                    role="tab"
                                                    onClick={() => {
                                                        handleShowExercise(item)
                                                    }}
                                                >
                                                    <div className="row">
                                                        <div class="box-circuit">
                                                            <div class="row align-items-center">
                                                                <div class="col-4 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                                    <div class="detail_all mx-auto text-center">
                                                                        <img
                                                                            src={item?.exerciseLevel && item?.exerciseLevel?.video_cover ? item?.exerciseLevel?.video_cover : ''}
                                                                            alt={item?.exerciseLevel && item?.exerciseLevel?.video_cover ? item?.exerciseLevel?.video_cover : ''}
                                                                            className="img-fluid"
                                                                            crossOrigin={cross_origin ? "anonymous" : null}
                                                                            onError={({ currentTarget }) => {
                                                                                currentTarget.onerror = null;
                                                                                currentTarget.src = Broken;
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div class="col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                                                                    <div class="detail_text">
                                                                        <h6>{item.exerciseLevel && item.exerciseLevel?.file_name ? item.exerciseLevel?.file_name : ''}</h6>
                                                                        <p>Equipment Needed: {item?.exerciseLevel?.exerciseEquipmentUses.length > 0 ? convertToEquipmentsCSV(item?.exerciseLevel?.exerciseEquipmentUses) : 'No Equipment'}</p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-3 col-sm-4 col-md-4 col-lg-4 col-xl-4 my-auto">
                                                                    <div class="detail_hour ms-3">
                                                                        {item.exerciseLevel && item.exerciseLevel.metric == 10 && item.exerciseLevel.reps ? <p><span className="info">Reps:</span> {item.exerciseLevel.reps}</p> : ''}
                                                                        {item.exerciseLevel && item.exerciseLevel.metric == 20 && item.exerciseLevel.time ? <p><span className="info">Time:</span> {EXERCISE_TIMES[item.exerciseLevel.time]}</p> : ''}
                                                                        {user?.userDetail && user?.userDetail?.weight_unit == 10 && item.exerciseLevel.suggested_weight_kg ? <p><span className="info">Weight:</span> {item.exerciseLevel.suggested_weight_kg} kg</p> : ''}
                                                                        {user?.userDetail && user?.userDetail?.weight_unit == 20 && item.exerciseLevel.suggested_weight_lbs ? <p><span className="info">Weight:</span> {item.exerciseLevel.suggested_weight_lbs} lbs</p> : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link >
                                            </li >
                                        </div>
                                    ) : 'No exercise available'}
                                </ul >
                            </>
                        }
                    </div>
                    {showExercise ?
                        <div className="col-12 col-xl-6 col-lg-8 col-md-12 test_one">
                            <div  ref={ScrollRef} className="tab-content box_test ex_box " >
                                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-9 col-md-7">
                                                <h4 className="mb-20">{showExercise.exerciseLevel && showExercise.exerciseLevel.file_name ? showExercise.exerciseLevel.file_name : ''}</h4>
                                            </div>
                                            <div className="col-3 col-md-5 text-right">
                                                <div className="btn-group ">
                                                    <div className="icon_bar" data-toggle="dropdown">
                                                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="18.1211" cy="18.3081" r="18"
                                                                fill="black"></circle>
                                                            <rect x="10.9209" y="18.6885" width="3.32275"
                                                                height="9.53105" fill="#1AE074"></rect>
                                                            <rect x="15.9927" y="13.5295" width="3.32275"
                                                                height="14.6901" fill="#9B9B9B"></rect>
                                                            <rect x="21.4141" y="8.10806" width="3.32275"
                                                                height="20.1114" fill="#9B9B9B"></rect>
                                                        </svg>
                                                    </div>
                                                    <div className="dropdown-menu dropdown-menu-right bar_dropdown">
                                                        <h4>Exercise Levels</h4>
                                                        <ul className="chart01  ">
                                                            <li><span></span> <i><b>Level 1</b> Easy </i>
                                                            </li>
                                                            <li><span></span> <i><b>Level 2</b> Moderate </i></li>
                                                            <li><span></span> <i><b>Level 3</b> Advanced </i></li>
                                                        </ul>
                                                        <div id="wrapper1">
                                                            <div className="btn_chart text-center">
                                                                <Link to={`/program/change-level`} state={{ exercise: showExercise, activeDayId: activeDayId, programId: programId }} className="theme_btn1 small_btn">
                                                                    Change Level
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {showExercise.exerciseLevel.starting_position &&
                                        <>
                                        <h6>Starting Position</h6>
                                        <p><Interweave content={showExercise.exerciseLevel.starting_position} /></p>
                                        </>
                                        }
                                        {showExercise.exerciseLevel.movements && <>
                                            <h6>Movement</h6>
                                            <p><Interweave content={showExercise.exerciseLevel.movements} /></p>
                                        </>
                                        }
                                        {showExercise.exerciseLevel.notes &&
                                        <div className="exc_p">
                                            <p><span>Note:</span><Interweave content={showExercise.exerciseLevel.notes} /></p>
                                        </div>
                                        }

                                        <div className="ssa box_video" style={{ textAlign: 'center' }} data-click="0">
                                            {/* <video crossOrigin="anonymous" className={`${showExercise.exerciseLevel.video_flip == true ? 'video flip-video' : 'video'}`} controls="controls" width="100%" height="100%"
                                                poster={showExercise.exerciseLevel.video_cover}>
                                                <source src={showExercise.exerciseLevel.video + '#t=' + showExercise.exerciseLevel.video_start_time + ',' + showExercise.exerciseLevel.video_end_time} />
                                            </video> */}
                                            <CustomVideoPlayer videoUrl={showExercise.exerciseLevel.video + '#t=' + showExercise.exerciseLevel.video_start_time + ',' + showExercise.exerciseLevel.video_end_time} posterUrl={showExercise.exerciseLevel.video_cover} className={`${showExercise.exerciseLevel.video_flip == true || showExercise.position == 2 ? 'video flip-video' : 'video'}`} showExercise={showExercise} />
                                            <div className="overlay_vid"></div>
                                        </div>
                                        <div className="position-relative">
                                            <button type="button" className="theme_btn1" onClick={() => handleStartTest(showExercise.id)}>START TEST</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        :
                        null}
                </div>
            </div>
            <StatusCheckinModal show={showStatusInModal} handleClose={handleStatusInModal} dayId={preDay ? preDay : ''} programId={programId ? programId : ''} />
        </section >
    );

};
const mapStateToProps = (state, ownProps) => ({
    previous_day: state.programReducer.previous_day,
    checkPreviousDaySuccess: state.programReducer.checkPreviousDaySuccess,
    ownProps: ownProps
});
export default connect(mapStateToProps, { checkPreviousDay })(Exercises);
