import React, {useEffect, useState} from "react"
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Header from "../Home/Header";
import Header2 from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { Container, Row, Col } from "react-bootstrap";
import imgNotFound from "../../images/broken-hd.png"
import imgRedirect from "../../images/redirect.png"
import { getRecommendedProducts } from "../../../actions/user_program";
import { connect } from "react-redux";
import { AiOutlineTwitter } from "react-icons/ai"
import { FaFacebookF } from "react-icons/fa"
import { FaTiktok } from "react-icons/fa"
import profile from "../../images/Group 29358.png"
import {cross_origin, SOCIAL_MEDIA_LINKS} from "../../../utils/constants";
const RecommendedProducts = ({ getRecommendedProducts, products }) => {
    const [user, setUser] = useState(false)
    useEffect(() => {
        getRecommendedProducts()
    }, [])

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'));
        if(user){
            setUser(true)
        }else{
            setUser(false)
        }
    }, [])

    const ProductCard = (data) => {
        const productData = data?.data;
        return (
            <div className={"product-box"}>
                <div className={"mx-auto text-center"}>
                    <img className={"img-fluid product-img"} crossOrigin={cross_origin ? "anonymous" : null} src={productData?.image ? productData?.image : imgNotFound} alt={productData?.title} title={data?.title} onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = imgNotFound;
                    }} />
                </div>
                <div className={"mt-3 p-2"}>
                    <div className={"product-info d-flex flex-column text-center"}>
                        <h3>
                            <b>{productData?.title}</b>
                        </h3>
                        <p>
                            <span>
                                {productData?.description}
                            </span>
                        </p>
                    </div>
                    <div className="clearfix mt-3"></div>
                    <div className={"mx-auto text-center"}>
                        <span>Buy it On:</span>
                        <a href={productData?.referral_link} target="_blank" className={"btn-full"}>
                            {productData?.referral_link_image ? <img className={"img-fluid"} crossOrigin={cross_origin ? "anonymous" : null} src={productData?.referral_link_image} alt={"Recommended Vendor"} title={"Recommended Vendor"} onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = imgRedirect;
                            }} /> : 'Click Here'}
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <ScrollTopOnMount />
            {user ? <Header2/> : <Header />}
            {/*<div className={"banner-recomended"}>*/}
            {/*    <Container className={"h-100"}>*/}
            {/*        <Row className={"h-100"}>*/}
            {/*            <Col className={"col-12 col-lg-6 col-md-6 m-auto"}>*/}
            {/*                /!*<div className="box_banner_inner text-center">*!/*/}
            {/*                /!*    <h3>Recommended Products</h3>*!/*/}
            {/*                /!*</div>*!/*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </Container>*/}
            {/*</div>*/}
            <div className={"clearfix"}></div>

            <section className={"recomended-products"}>
                <Container>
                    <Row>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                            <div className="videos_headings">
                                <h2 className="detail_span" style={{ color: "#fff" }}>Product Recommendations</h2>
                                <p style={{ color: "#fff" }}>Our favorite products used in the Body Solutions Programs</p>
                            </div>
                        </div>
                        {/*<Col xs={12} sm={12} md={12} lg={6} xl={6} className={"mx-auto"}>*/}

                        {/*    <div className={"mx-auto text-center page-title"}>*/}
                        {/*        <h2>*/}
                        {/*            <b>Product Recommendations</b>*/}
                        {/*        </h2>*/}
                        {/*        <p>Our favorite products used in the Body Solutions Programs</p>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                    </Row>
                    <div className={"clearfix mt-5"}></div>
                    <Row>
                        {
                            products && products.map((products, key) => {
                                if (products) {
                                    return (
                                        <Col xs={12} sm={6} md={4} lg={3} xl={3} key={key}>
                                            <ProductCard data={products} />
                                        </Col>
                                    )
                                }
                            })
                        }
                    </Row>
                    <Row className={"mt-5"}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"mx-auto"}>
                            <div className="social-links">
                                <div className="kit">
                                    <h3>Share this kit:</h3>
                                    <ul className="links-icons">
                                        <li><a href={SOCIAL_MEDIA_LINKS.TWITTER} target="_blank"><AiOutlineTwitter /></a></li>
                                        <li><a href={SOCIAL_MEDIA_LINKS.FACEBOOK} target="_blank"><FaFacebookF /></a></li>
                                        <li><a href={SOCIAL_MEDIA_LINKS.TIKTOK} target="_blank"><FaTiktok /></a></li>
                                    </ul>
                                </div>
                                <div className="social-profile">
                                    <div className="wrapper">
                                        <img src={profile} alt="profile" className="img-fluid" />
                                    </div>
                                    <div className="content">
                                        <h3>@bodysolutions</h3>
                                        <a href={SOCIAL_MEDIA_LINKS.WEB} target="_blank" className="btn-follow">Follow</a>
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    )
}
const mapStateToProps = state => {

    return {
        products: state.programReducer.products
    }
}
export default connect(mapStateToProps, { getRecommendedProducts })(RecommendedProducts)