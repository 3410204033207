import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { RiLayoutGridFill, RiListUnordered } from 'react-icons/ri'

const BreadcrumbMenu = ({ setView, activeDayId, lastActiveDayId }) => {
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        const newValue = event.target.checked;
        setView(newValue);
    };
    return (
        <>
            <section className="welcome_002">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-8 col-md-8">
                            <ul className="breadcrumb main">
                                <li className=""><Link to="/">Home </Link></li>
                                <li><Link to="/welcome"> Welcome</Link></li>
                                <li className="active"><Link to="#"> Exercises</Link></li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-4 col-md-4">
                            <div class="pro text-right">
                                <Link to={`/weekly-progress/${activeDayId}`} className="theme-btn-link d-inline-block mt-20">Progress Report</Link>

                                {
                                    lastActiveDayId ? <> <span>-</span> <Link to={`/assessment-timer`} className="theme-btn-link d-inline-block mt-20">Resume</Link> </> : ''
                                }

                            </div>
                            <div className="nav_week justify-content-end">
                                <Link to="#" className="daily"><RiLayoutGridFill /> Grid View</Link>
                                <div className="button-switch">
                                    <input type="checkbox" id="addsetting"
                                        className="switch sw_btn" checked={isChecked} onChange={handleCheckboxChange} />
                                    <label htmlFor="addsetting" className="lbl-off"> </label>
                                </div>
                                <Link to="#" className="weekly">List View <RiListUnordered /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BreadcrumbMenu
