import React from 'react'
import Header from './Header'
import Footer from './Footer'
//import { Outlet } from 'react-router-dom'

function Layout(props) {
  return (
    <div className='Layout1'>
        <Header />
        {props.children}
        {/*<Outlet/>*/}
        <Footer/>
    </div>
  )
}

export default Layout