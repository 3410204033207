import React, {useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import CustomVideoPlayer from "./CustomVideoPlayer";
import {cross_origin} from "../../../utils/constants";
import {BiPause, BiPlay} from "react-icons/bi";
import {MdFullscreen, MdFullscreenExit} from "react-icons/md";

function FullScreenModal({ show, handleClose ,src,className,autoPlay,showExercise,posterUrl}) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const videoRef = useRef(null);
    const [repeatLoop, setRepeatLoop] = useState(false);

    const handlePlayClick = () => {
        if (isPlaying) {
            console.log("false")
            videoRef.current.pause();
            setIsPlaying(false);
        } else {
            console.log("true")
            // setShowPoster(false);
            videoRef.current.play();
            setIsPlaying(true);

        }
    };

    const handleTimeUpdate = () => {
        setCurrentTime(videoRef.current.currentTime);
        setDuration(videoRef.current.duration);
    };

    const handleProgressBarClick = (event) => {
        const progress = event.nativeEvent.offsetX / event.target.clientWidth;
        const currentTime = progress * duration;
        videoRef.current.currentTime = currentTime;
        setCurrentTime(currentTime);
    };
    const handleVideoEnded = () => {
        if (!repeatLoop) {
            // add the "flip-video" className to the video tag
            if (showExercise.exerciseLevel.video_flip == true || showExercise.position == 2) {
                videoRef.current.classList.remove("flip-video");
            } else {
                videoRef.current.classList.add("flip-video");
            }
            setRepeatLoop(true)
        } else {
            if (showExercise.exerciseLevel.video_flip == true || showExercise.position == 2) {
                videoRef.current.classList.add("flip-video");
            } else {
                videoRef.current.classList.remove("flip-video");
            }
            setRepeatLoop(false)
        }

    };
    return (
        <>
            <Modal show={show}
                   onHide={handleClose}
                   id="fullScreenModal"
                   className="fullscreen-modal modal fade"
                   aria-hidden="true"
                   aria-labelledby="exampleModalLabel"
                   size='xl'
                   centered>
                <Modal.Body  className="fullscreen-modal-body">
                    <div className="video-player">
                        {/*{showPoster ? <img onClick={handlePlayClick} src={posterUrl} alt="Video Poster" className={`${showExercise?.exerciseLevel?.video_flip == false  ? 'poster-image flip-video' : 'poster-image'}`} />:*/}
                        <video
                            className={`class-hidden-controls ${className}`}
                            ref={videoRef}
                            src={src + '#t=0.001'}
                            onClick={handlePlayClick}
                            poster={posterUrl}
                            onPlay={() => setIsPlaying(true)}
                            onPause={() => setIsPlaying(false)}
                            onTimeUpdate={handleTimeUpdate}
                            width="100%"
                            height="100%"
                            onEnded={handleVideoEnded}
                            crossOrigin={cross_origin ? "anonymous" : null}
                            controls={false}
                            controlsList="nodownload"
                            playsInline={true}
                            autoPlay={autoPlay ? autoPlay : false}
                            onContextMenu={(e) => e.preventDefault()}
                        />
                        {/*}*/}


                        <div className="controls">
                            <button className="play-pause-button" onClick={handlePlayClick}>{isPlaying ? <BiPause /> :<BiPlay />}</button>
                            <div className="progress-bar" onClick={handleProgressBarClick}>
                                <div className="progress" style={{ width: `${(currentTime / duration) * 100}%` }} />
                            </div>
                            <button className="mobile-fullscreen-button" onClick={handleClose}>
                               <MdFullscreenExit />
                            </button>
                        </div>
                    </div>
                </Modal.Body>

            </Modal></>
    );
}

export default FullScreenModal;