import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Image from "../../images/img8.png";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import {getAptVideos, getEducationalVideos, getPainPoint} from "../../../actions/user_program";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import {cross_origin} from "../../../utils/constants";
import Banner from "../../images/triggercover.png";

function TriggerPainPoint({ getPainPoint, pain_point}) {
    const [painPointData, setPainPointData] = useState(null)

    const [currentPage, setCurrentPage] = useState(pain_point ? parseInt(pain_point.meta.current_page) : 1);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [totalPages, setTotalPages] = useState(0)
    const [keyword, setKeyword] = useState(null)
    const [search, setSearch] = useState(null)
    const [loader, setLoader] = useState(false)

    let array =[
        {
            image:Image
        },
        {
            image:Image
        },
        {
            image:Image
        },
        {
            image:Image
        },
        {
            image:Image
        },
        {
            image:Image
        },
        {
            image:Image
        },
        {
            image:Image
        },
        {
            image:Image
        },
        {
            image:Image
        },
        {
            image:Image
        }
    ]


    useEffect(() => {
        // if(keyword && keyword != null){
            getPainPoint(currentPage, itemsPerPage, 1,keyword)
        // }
    }, [currentPage, itemsPerPage]);


    useEffect(() => {
        setTotalPages(pain_point ? parseInt(pain_point.meta.last_page) : 1)
        if(pain_point?.data){
            setLoader(false)
        }
        setPainPointData(pain_point ? pain_point.data : [])
        // setPainPointData(array)
    }, [pain_point]);


    useEffect(() => {
        setTimeout(()=>{
            const topElement = document.querySelector('.trigger-banner-recommended'); // Replace 'top-element' with the actual ID of your top element
            if (topElement) {
                topElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start', // Scroll to the top of the element
                });
            }

        },2000)

    }, []);


    const handleItemsPerPageChange = (e) => {
        setLoader(true)
        setItemsPerPage(parseInt(e.target.value));
    };


    const handleSearchClick = () => {
            getPainPoint(currentPage, itemsPerPage, 1,keyword)
        setLoader(true)
        setCurrentPage(1)
    };
    const handleSearch = (value) => {
        setKeyword(value)
        setSearch(value)
        if(keyword && value.length == 0){
            getPainPoint(currentPage, itemsPerPage, 1,value)
        }
    };

    return (
        <>
            <ScrollTopOnMount />
            <Header />
            <div className={"trigger-banner-recommended"}>
                <Container className={"h-100"}>
                    <Row className={"h-100"}>
                        <div className="bannerImgWrap">
                            <img
                                src={Banner}
                                alt={'banner'}
                                crossOrigin={cross_origin ? "anonymous" : null}
                            />
                        </div>
                    </Row>
                </Container>
            </div>
            <div className={"clearfix"}></div>
            <section className="videos">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                            <div className="videos_headings">
                                <h2 className="detail_span" style={{ color: "#fff" }}>TRIGGER POINT PAIN REFERRAL LIBRARY</h2>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                            <Form className="d-flex mt-3" onSubmit={(e) => e.preventDefault()}>
                                <Form.Control
                                    value={search}
                                    type="search"
                                    placeholder="Search"
                                    className="me-2 rounded-pill"
                                    aria-label="Search"
                                    onChange={(e)=>handleSearch(e.target.value)}
                                />
                                <Button className="rounded-pill" variant="outline-primary" onClick={handleSearchClick}>
                                    Search
                                </Button>
                            </Form>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                            <div className={"Educational-info d-flex flex-column text-start mb-3"}>
                                <h3 className={'text-center'}>
                                    <p>Search by pain area (i.e. knee, hip, low back, etc.)</p>
                                </h3>
                            </div>
                        </div>
                        {/*<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">*/}
                        {/*  <div className="row">*/}
                        {/*      <div className="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 ">*/}
                        {/*          <div className="library-img-container static-img">*/}
                        {/*          <div className="library-img-box mb-0">*/}
                        {/*          <img src={Image} alt="" crossOrigin="anonymous" className={'img-fluid'}/>*/}
                        {/*          </div>*/}
                        {/*          </div>*/}
                        {/*      </div>*/}
                        {/*      <div className="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 offset-xl-3 offset-lg-3">*/}
                        {/*          <div className="library-img-container">*/}
                        {/*              <div className="library-img-box mb-0">*/}
                        {/*          <img src={Image} alt="" crossOrigin="anonymous" className={'img-fluid'}/>*/}
                        {/*              </div>*/}
                        {/*          </div>*/}
                        {/*      </div>*/}
                        {/*      <div className="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 offset-xl-3 offset-lg-3">*/}
                        {/*          <div className="library-img-container">*/}
                        {/*              <div className="library-img-box mb-0">*/}
                        {/*          <img src={Image} alt="" crossOrigin="anonymous" className={'img-fluid'}/>*/}
                        {/*              </div>*/}
                        {/*          </div>*/}
                        {/*      </div>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                            <hr/>
                            <div className="row">

                                {loader ? <div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 " >
                                        <div className={"empty-page"} >
                                            <div className="spin-loader">
                                                <FadeLoader color={"#EC1246"} height={10} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <> {painPointData?.length > 0 ? painPointData.map((data, key) => {
                                        if (data) {
                                            return (
                                                <div className="col-6 col-sm-6 col-md-3  col-lg-2 col-xl-2 " key={key}>
                                                    <Link
                                                        className={"nav-link library-nav"}
                                                        data-toggle="tab"
                                                        to={"/trigger-pain-point/detail/"+ data?.id}
                                                        role="tab"
                                                    >
                                                        <div className="library-img-container">
                                                            <div className="library-img-box">
                                                                <img
                                                                    src={data?.image ? data?.image : ''}
                                                                    alt={data?.title ? data?.title : ''}
                                                                    crossOrigin={cross_origin ? "anonymous" : null}
                                                                />
                                                            </div>
                                                            <div className="title_box">
                                                                <h6>{data?.title ? data?.title : ''}</h6>
                                                            </div>
                                                            {/*<div className="check_test pb-2">*/}
                                                            {/*    <img src={data?.image ? data?.image : ''} alt="" crossOrigin="anonymous" className={'img-fluid'}/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        }
                                    }) :<div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 " >
                                        <div className={"library-empty-page"} >
                                            <span className="itemsPerPagelabel">Not Found</span>
                                        </div>

                                    </div>
                                    }</>}
                            </div>
                        </div>
                        {painPointData?.length > 0 && <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                            <div className="pagination">
                                <span className="itemsPerPagelabel">Items Per Page:</span>
                                <div className={'perpagedropdown'}>
                                    <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                        <option value={4}>4</option>
                                        <option value={8}>8</option>
                                        <option value={12}>12</option>
                                        <option value={16}>16</option>
                                        <option value={20}>20</option>
                                    </select>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                </div>
                                <span className="totalpages">{currentPage}-{totalPages} of {totalPages}</span>
                                <div className="paginationbuttons">
                                    {currentPage > 1 ? <button
                                        className={'button-previous'}
                                        onClick={() => {setCurrentPage(currentPage - 1)
                                            setLoader(true)}}
                                        disabled={currentPage === 1}

                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                    </button> : ''}
                                    {currentPage < totalPages ? <button
                                        className={'button-next'}
                                        onClick={() =>{ setCurrentPage(currentPage + 1)
                                            setLoader(true)}}
                                        disabled={currentPage === totalPages}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                    </button> : ''}
                                </div>
                            </div>
                        </div> }
                    </div>
                </div>
            </section >
            <Footer />
        </>
    );
}

const mapStateToProps = state => {
    return {
        pain_point: state.programReducer.pain_point,
        user: state.auth.user
    }
}
export default connect(mapStateToProps, { getPainPoint })(TriggerPainPoint)