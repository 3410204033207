import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { checkValidation } from "../../utils/helpers";
import { setAlert } from "../../actions/alert";
import { submitDayFeedback } from "../../actions/user_program";
import { SUBMIT_CHECKOUT_FEEDBACK_PURGE } from "../../actions/types";


const StatusCheckoutModal = ({ show, handleClose, dayId, submitDayFeedback, submitCheckOutSuccess }) => {
  // const [check, setCheck] = useState(false);
  // const [open, setOpen] = useState(false);
  // const ref = useRef();

  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const onBodyClick = (e) => {
  //     if (ref.current.contains(e.target)) {
  //       return;

  //     }
  //     setOpen(false);
  //   };
  //   document.body.addEventListener("click", onBodyClick, { capture: true });

  //   return () => {
  //     document.body.removeEventListener('click', onBodyClick, { capture: true })
  //   }

  // }, []);

  // const handleChange = (e) => {
  //   setCheck(!check);

  // };

  // const content = check
  //   ?
  //   <ul className="chosen-choices custom_list">
  //     <li className="search-choice">
  //       <span>Occiput</span>
  //       <textarea
  //         className="textarea_01"
  //         placeholder="Enter your pain description here"
  //       ></textarea>
  //       <a className="search-choice-close"></a>
  //     </li>
  //   </ul>
  //   : null;

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    let validate = await checkValidation(Object.fromEntries(formData), ['challenging_workout', 'muscles_engage'])
    setValidationErrors(validate)
    if (Object.keys(validate).length > 0) {
      return false;
    }
    if (dayId) {
      formData.append('user_program_day_id', dayId)
      submitDayFeedback(formData)
    } else {
      setAlert('Already submitted', 'danger');
    }
  };

  if (submitCheckOutSuccess) {
    dispatch({ type: SUBMIT_CHECKOUT_FEEDBACK_PURGE })
    handleClose()
    navigate(`/weekly-progress/${dayId}`, { replace: true });
  }

  return (
    <Modal
      show={show} onHide={handleClose}
      className="status_checkout"
      centered
    >
      <Modal.Body>
        <Modal.Header>
          <Modal.Title>Status Check Out</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>

        <div className="box_state">
          <Form onSubmit={onSubmitHandler}>
            <Form.Group className="form-group">
              <Form.Label>How challenging was the workout?</Form.Label>
              <ul className="rating">
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control
                      type="radio"
                      value="1"
                      name="challenging_workout"
                    />
                    <span className="checkmark main_check1">
                      <p>1</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="2" name="challenging_workout" />
                    <span className="checkmark main_check1">
                      <p>2</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="3" name="challenging_workout" />
                    <span className="checkmark main_check1">
                      <p>3</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="4" name="challenging_workout" />
                    <span className="checkmark main_check1">
                      <p>4</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="5" name="challenging_workout" />
                    <span className="checkmark main_check1">
                      <p>5</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="6" name="challenging_workout" />
                    <span className="checkmark main_check1">
                      <p>6</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="7" name="challenging_workout" />
                    <span className="checkmark main_check1">
                      <p>7</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="8" name="challenging_workout" />
                    <span className="checkmark main_check1">
                      <p>8</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="9" name="challenging_workout" />
                    <span className="checkmark main_check1">
                      <p>9</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="10" name="challenging_workout" />
                    <span className="checkmark main_check1">
                      <p>10</p>
                    </span>
                  </Form.Label>
                </li>
              </ul>
              <div className="row">
                <div className="col-6 col-lg-6">
                  <p className="status">Not at all</p>
                </div>
                <div className="col-6 col-lg-6">
                  <p className="status text-right">Very</p>
                </div>
              </div>
              <span
                className="show-error">{validationErrors['challenging_workout']}</span>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>
                Could you feel the correct muscles engage?
              </Form.Label>
              <ul className="rating">
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="1" name="muscles_engage" />
                    <span className="checkmark main_check1">
                      <p>1</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="2" name="muscles_engage" />
                    <span className="checkmark main_check1">
                      <p>2</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="3" name="muscles_engage" />
                    <span className="checkmark main_check1">
                      <p>3</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control
                      type="radio"
                      value="4"
                      name="muscles_engage"
                    />
                    <span className="checkmark main_check1">
                      <p>4</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="5" name="muscles_engage" />
                    <span className="checkmark main_check1">
                      <p>5</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="6" name="muscles_engage" />
                    <span className="checkmark main_check1">
                      <p>6</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="7" name="muscles_engage" />
                    <span className="checkmark main_check1">
                      <p>7</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="8" name="muscles_engage" />
                    <span className="checkmark main_check1">
                      <p>8</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="9" name="muscles_engage" />
                    <span className="checkmark main_check1">
                      <p>9</p>
                    </span>
                  </Form.Label>
                </li>
                <li>
                  <Form.Label className="container-checkbox11">
                    <Form.Control type="radio" value="10" name="muscles_engage" />
                    <span className="checkmark main_check1">
                      <p>10</p>
                    </span>
                  </Form.Label>
                </li>
              </ul>
              <div className="row">
                <div className="col-6 col-lg-6">
                  <p className="status">Not at all</p>
                </div>
                <div className="col-6 col-lg-6">
                  <p className="status text-right">Very</p>
                </div>
              </div>
              <span
                className="show-error">{validationErrors['muscles_engage']}</span>
            </Form.Group>

            {/* <Form.Group className="form-group check_box">
              <Form.Label>
                Did any of the exercises cause you pain while doing them? If
                so, Which One(s):
              </Form.Label>
              <div ref={ref}>
                <div className="select" onClick={() => setOpen(true)}  >
                  <select className="form-control form-1">
                    <option>Select</option>
                  </select>{" "}
                  <svg
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.93396 7.63281L6.22685 8.33992L6.93396 9.04703L7.64107 8.33992L6.93396 7.63281ZM0.226853 2.33992L6.22685 8.33992L7.64107 6.92571L1.64107 0.925706L0.226853 2.33992ZM7.64107 8.33992L13.6411 2.33992L12.2269 0.925706L6.22685 6.92571L7.64107 8.33992Z"
                      fill="#737373"
                    ></path>
                  </svg>
                </div>
                {open && (
                  <div className="row new"  >
                    <div className="col-md-6 chosen-choices" >
                      <Form.Group className="form-group">
                        <Form.Control
                          type="checkbox"
                          className="form-check-input"
                          name="painAreas"
                          value="Occiput"
                          id="1"
                          onChange={handleChange}
                          onClick={() => setOpen(false)}
                        />
                        <Form.Label className="form-check-label" htmlFor="1">
                          {" "}
                          Occiput
                        </Form.Label>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Control
                          className="form-check-input"
                          type="checkbox"
                          name="painAreas"
                          value="Elbow"
                          id="2"
                          onChange={handleChange}
                        />
                        <Form.Label className="form-check-label" htmlFor="2">
                          Elbow
                        </Form.Label>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Control
                          className="form-check-input"
                          type="checkbox"
                          name="painAreas"
                          value="Trapezius"
                          id="3"
                          onChange={handleChange}
                        />
                        <Form.Label className="form-check-label" htmlFor="3">
                          Trapezius
                        </Form.Label>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Control
                          className="form-check-input"
                          type="checkbox"
                          name="painAreas"
                          value="Rib"
                          id="4"
                          onChange={handleChange}
                        />
                        <Form.Label className="form-check-label" htmlFor="4">
                          Rib
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-6 chosen-choices">
                      <Form.Group className="form-group">
                        <Form.Control
                          className="form-check-input"
                          type="checkbox"
                          name="painAreas"
                          value="Gluteal"
                          id="5"
                          onChange={handleChange}
                        />
                        <Form.Label className="form-check-label" htmlFor="5">
                          Gluteal
                        </Form.Label>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Control
                          className="form-check-input"
                          type="checkbox"
                          name="painAreas"
                          value="Knee"
                          id="6"
                          onChange={handleChange}
                        />
                        <Form.Label className="form-check-label" htmlFor="6">
                          Knee
                        </Form.Label>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Control
                          className="form-check-input"
                          type="checkbox"
                          name="painAreas"
                          value="Cervical"
                          id="7"
                          onChange={handleChange}
                        />
                        <Form.Label className="form-check-label" htmlFor="7">
                          Cervical
                        </Form.Label>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Control
                          className="form-check-input"
                          type="checkbox"
                          name="painAreas"
                          value="Ankle"
                          id="8"
                          onChange={handleChange}
                        />
                        <Form.Label className="form-check-label" htmlFor="8">
                          Ankle
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </div>
                )}
              </div>
              {content}

              <Form.Label>
                Are there any of the exercises you just don’t like? If so,
                which one(s):
              </Form.Label>
              <div className="select">
                <select className="form-control form-1">
                  <option>Select</option>
                  <option>option 1</option>
                  <option>option 2</option>
                  <option>option 3</option>
                  <option>option 4</option>
                </select>

                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.93396 7.63281L6.22685 8.33992L6.93396 9.04703L7.64107 8.33992L6.93396 7.63281ZM0.226853 2.33992L6.22685 8.33992L7.64107 6.92571L1.64107 0.925706L0.226853 2.33992ZM7.64107 8.33992L13.6411 2.33992L12.2269 0.925706L6.22685 6.92571L7.64107 8.33992Z"
                    fill="#737373"
                  />
                </svg>
              </div>
            </Form.Group> */}

            <Form.Group className="form-group">
              <Form.Label>If painful sore, please explain:</Form.Label>
              <textarea className="form-control form-1" name="feedback"></textarea>
            </Form.Group>
            <Form.Group className="form-group text-center">
              <Form.Control
                type="submit"
                value="Continue"
                className="theme_btn1"
              />
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
    </Modal>

  );
};

const mapStateToProps = state => ({
  submitCheckOutRequest: state.programReducer.submitCheckOutRequest,
  submitCheckOutSuccess: state.programReducer.submitCheckOutSuccess,
  submitCheckOutFailure: state.programReducer.submitCheckOutFailure,
});

export default connect(mapStateToProps, { submitDayFeedback, setAlert })(StatusCheckoutModal);
