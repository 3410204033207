import api from "../utils/api";
import { setAlert } from "./alert";
import { INSTRUCTIONS_FAILURE, INSTRUCTIONS_REQUEST, INSTRUCTIONS_SUCCESS } from "./types";

export const getInstructions = () => async (dispatch) => {
    try {
        dispatch({
            type: INSTRUCTIONS_REQUEST
        })
        let token = localStorage.getItem('token')
        const res = await api.get('/instructions?is_paginate=0',
            {}, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        dispatch({
            type: INSTRUCTIONS_SUCCESS,
            payload: res.data.data
        });
    } catch (err) {
        const msg = err && err.response ? err.response.data.message : null;
        if (msg) {
            dispatch(setAlert(msg, 'danger'))
        }
        dispatch({
            type: INSTRUCTIONS_FAILURE
        })
    }
}
