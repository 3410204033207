import React from 'react'

const Day1 = () => {
    return (
        <section className="assestmentd1_001">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12 text-center">
                        <h3>Daily Test </h3>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Day1