import React, { useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import { connect } from "react-redux";
import { getProgram, getProgramTest, getUserProgramDay } from "../../../actions/user_program";
import { FadeLoader } from "react-spinners";

const TestInstructions = ({ getProgram, program, getProgramTest }) => {
    useEffect(() => {
        if (!program) {
            getProgram()
        }
    }, [])
    return (
        <>
            <ScrollTopOnMount />
            <Header />
            <section className="welcome_001">
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-12 col-lg-6 col-md-6 m-auto">
                            <div className="box_banner_inner text-center mx-auto">
                                <div className="mx-auto">
                                    <h1>Testing Instructions</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="welcome_002">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-8 col-md-8">
                            <ul className="breadcrumb main">
                                <li className=""><Link to="/">Home </Link></li>
                                <li><Link to="/welcome"> Welcome</Link></li>
                                <li className="active"><Link to="#">Testing Instructions</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="test-new">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-10 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 ">
                            {program ?
                                <div className="test_content pt-3 ">
                                    <h3><b>The GOAL</b></h3>
                                    <p className="">Use the following daily tests to identify which areas of your body
                                        are
                                        not working properly and need extra attention.</p>
                                    <div className="test_ul">
                                        <ul className="list_pain1 pb-2 ">
                                            <li>
                                                <i className="fa fa-check" aria-hidden="true"></i>
                                                <span>Select the tests that you did not pass during the assessment</span>
                                            </li>
                                            <br />
                                            <li>
                                                <i className="fa fa-check" aria-hidden="true"></i>
                                                <span>Skip the tests that you passed</span>
                                            </li>
                                            <p>By the end of the {program.duration} program, you should be able to
                                                see improvement
                                                in all of the tests that you struggled with during the initial assessment.</p>
                                        </ul>
                                        <div className="test_btn text-center pb-5">
                                            <Link to={"/tests/" + program.id} state={{ program: program }} className="theme_btn1 mt-30" data-target="#statuscheckout">Next Page</Link>
                                        </div>
                                    </div>
                                </div> : <div className="test_content pt-3 loader_custom">
                                    <FadeLoader color={"#FB1417"} height={10} />
                                </div>}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
const mapStateToProps = state => ({
    program: state.programReducer.program,
});

export default connect(mapStateToProps, { getProgram })(TestInstructions);