import React from 'react'

const Welcome1 = ({ user_program_day, program ,exercises = false}) => {
    return (
        <section className={`welcome_001 ${exercises ? 'display-none' : ''}`}>
            <div className="container h-100">
                <div className="row h-100">
                    <div className="col-12 col-lg-6 col-md-6 m-auto">
                        <div className="box_banner_inner text-center m-auto">
                            <div>
                                <h1>{program ? program.name : "-"}</h1>
                                {/* <h5>{user_program_day ? 'Day ' + user_program_day.programDay.day : "-"}</h5> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Welcome1