import React, {useState, useRef, useEffect} from 'react';
import { BiPlay,BiVolumeFull,BiVolumeMute } from "react-icons/bi";
import { BiPause } from "react-icons/bi";
import { MdFullscreen, MdFullscreenExit} from "react-icons/md";
import {cross_origin} from "../../../utils/constants";
import HowToUseModal from "../../Layout/HowToUseModal";
import FullScreenModal from "./FullScreenModal";

const CustomVideoPlayer = ({ videoUrl, posterUrl, className, showExercise, autoPlay, height, width }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [currentVideoTime, setCurrentVideoTime] = useState(0);
    const [durationVideo, setVideoDuration] = useState(0);
    const videoRef = useRef(null);
    const [repeatLoop, setRepeatLoop] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [volume, setVolume] = useState(1);
    const isMobile = window.innerWidth <= 767;
    const userAgent = window.navigator.userAgent;
    // const isiPhone = /iPhone/i.test(userAgent);

    useEffect(() => {
        // Add event listeners to track the current time and duration of the video.
        const videoElement = videoRef.current;
        const handleTimeUpdate = () => setCurrentVideoTime(videoElement.currentTime);
        const handleLoadedMetadata = () => setVideoDuration(videoElement.duration);

        videoElement.addEventListener('timeupdate', handleTimeUpdate);
        videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);

        // Remove event listeners when the component is unmounted.
        return () => {
            videoElement.removeEventListener('timeupdate', handleTimeUpdate);
            videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
        };
    }, []);

    useEffect(() => {

        const handleFullScreenChange = () => {
            setIsFullScreen(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement || document.webkitEnterFullscreen );
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('msfullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);


        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('msfullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
        };
    }, []);

    const handlePlayClick = () => {
        if (isPlaying) {
            console.log("false")
            videoRef.current.pause();
            setIsPlaying(false);
        } else {
            console.log("true")
            // setShowPoster(false);
            videoRef.current.play();
            setIsPlaying(true);

        }
    };

    const handleTimeUpdate = () => {
        setCurrentTime(videoRef.current.currentTime);
        setDuration(videoRef.current.duration);
    };

    const handleProgressBarClick = (event) => {
        const progress = event.nativeEvent.offsetX / event.target.clientWidth;
        const currentTime = progress * duration;
        videoRef.current.currentTime = currentTime;
        setCurrentTime(currentTime);
    };
    const handleVideoEnded = () => {
        if (!repeatLoop) {
            // add the "flip-video" className to the video tag
            if (showExercise.exerciseLevel.video_flip == true || showExercise.position == 2) {
                videoRef.current.classList.remove("flip-video");
            } else {
                videoRef.current.classList.add("flip-video");
            }
            setRepeatLoop(true)
        } else {
            if (showExercise.exerciseLevel.video_flip == true || showExercise.position == 2) {
                videoRef.current.classList.add("flip-video");
            } else {
                videoRef.current.classList.remove("flip-video");
            }
            setRepeatLoop(false)
        }

    };

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            console.log("fullscreen not true")
            if (videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen();
            } else if (videoRef.current.mozRequestFullScreen) {
                videoRef.current.mozRequestFullScreen();
            } else if (videoRef.current.webkitRequestFullscreen) {
                console.log("here in webkit")
                videoRef.current.webkitRequestFullscreen();
            } else if (videoRef.current.msRequestFullscreen) {
                videoRef.current.msRequestFullscreen();
            }else{
                videoRef.current.webkitEnterFullscreen()
                console.log("here in else")
            }
        } else {
            console.log("fullscreen true")
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }else if(document.webkitExitFullscreen){
                document.webkitExitFullscreen();
            }
        }
        setIsFullScreen(false);
    };


    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    const toggleMute = () => {
        setIsMuted(!isMuted);
        videoRef.current.muted = !isMuted;
        if(isMuted){
            videoRef.current.volume = 1;
        }else{
            videoRef.current.volume = 0;
        }
        setVolume( isMuted? 1 : 0);
    };

    const handleVolumeChange = (event) => {
        const newVolume = parseFloat(event.target.value);
        setVolume(newVolume);
        if(newVolume > 0){
            videoRef.current.muted = false;
        }
        videoRef.current.volume = newVolume;
        setIsMuted(newVolume === 0);
    };



    return (
        <div className="video-player">
            {/*{showPoster ? <img onClick={handlePlayClick} src={posterUrl} alt="Video Poster" className={`${showExercise?.exerciseLevel?.video_flip == false  ? 'poster-image flip-video' : 'poster-image'}`} />:*/}
            <video
                className={`class-hidden-controls ${className}`}
                ref={videoRef}
                src={videoUrl + '#t=0.001'}
                onClick={handlePlayClick}
                poster={posterUrl}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                onTimeUpdate={handleTimeUpdate}
                width="100%"
                height="100%"
                onEnded={handleVideoEnded}
                crossOrigin={cross_origin ? "anonymous" : null}
                controls={false}
                controlsList="nodownload"
                playsInline={true}
                autoPlay={autoPlay ? autoPlay : false}
                onContextMenu={(e) => e.preventDefault()}
            />
{/*}*/}


            <div className="controls">
                <div className="controls-wrap">
                    <div className="left-wrapper">
                        <button className="play-pause-button" onClick={handlePlayClick}>{isPlaying ? <BiPause /> :<BiPlay />}</button>
                        <p className="video-duration"> {formatTime(currentVideoTime)}/{formatTime(durationVideo)}</p>
                    </div>
                    <div className="right-wrapper">
                        <div className="sound-controls">
                            <input
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                                value={volume}
                                onChange={handleVolumeChange}
                                className="sound-bar"
                                style={{ background:`linear-gradient(to right, #ffffff ${(volume / 1) * 100}%, #ccc ${(volume / 1) * 100}%)`}}
                            />
                            <button onClick={toggleMute} className="sound-button">
                                {isMuted ? <BiVolumeMute/> :<BiVolumeFull/> }
                            </button>

                        </div>
                        <button className="fullscreen-button" onClick={toggleFullScreen}>
                            {isFullScreen ? <MdFullscreenExit /> : <MdFullscreen />}
                        </button>
                    </div>

                </div>
                <div className="progress-bar" onClick={handleProgressBarClick}>
                    <div className="progress" style={{ width: `${(currentTime / duration) * 100}%` }} />
                </div>

                {/*{isiPhone && <button className="mobile-fullscreen-button" onClick={toggleFullScreen}>*/}
                {/*    {isFullScreen ? <MdFullscreenExit /> : <MdFullscreen />}*/}
                {/*</button> }*/}

            </div>
            {/*<FullScreenModal handleClose={handleClose} show={show}  src={videoUrl + '#t=0.001'} className={'video'} autoPlay={autoPlay} showExercise={showExercise} posterUrl={posterUrl}/>*/}
        </div>
    );
};

export default CustomVideoPlayer;