import React, { useState } from 'react'
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import HowToUseModal from '../HowToUseModal';

const FooterTop = () => {
  const user = localStorage.getItem('user');
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true)
  };
  const handleClose = () => setShow(false);

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4 col-md-4">
              <div className="bx_footer">
                <div className="footer_logo">
                  <Link to={user? "/home" : "/"}>
                    {" "}
                    <img src={logo} alt="logo" className="img-fluid" />
                  </Link>
                </div>
                <p>
                  Copyright Body Solutions 2023 | All Rights Reserved
                </p>
                <ul className="cards">
                  <li>
                    <div>
                      <Link to="#">Facebook</Link>
                    </div>
                  </li>
                  <li>
                    <div>
                      <Link to="#">Twitter</Link>
                    </div>
                  </li>
                  <li>
                    <div>
                      <Link to="#">Instagram</Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-lg-4 col-md-4">
              <h6>Sitemap </h6>
              <ul className="menu_footer two">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to={user? "/pages/about-us" : "/page/about-us"}>About</Link>
                </li>
                <li>
                  <Link to="#" onClick={handleShow}>
                    How to use the app
                  </Link>
                </li>
                <li>
                  <Link to="/faqs">
                    FAQs
                  </Link>
                </li>
                {/* <li>
                  <Link to="/welcome">Programs </Link>
                </li> */}
                <li>
                  <Link to={user? "/educational-videos" : "/education"}>Education </Link>
                </li>
                {user &&
                <>
                  <li>
                    <Link to="/muscles-anatomy/library" >
                      Muscles Library
                    </Link>
                  </li>
                  <li>
                    <Link to="/trigger-pain-point/library">
                      Trigger Point Library
                    </Link>
                  </li>
                </>
                }
              </ul>
            </div>
            <div className="col-6 col-lg-2 col-md-2">
              <h6> Company </h6>
              <ul className="menu_footer">
                <li>
                  <Link to={user? "/pages/about-us" : "/page/about-us"}>About Us </Link>
                </li>
                {/*<li>*/}
                {/*  <Link to="#">Who We Are</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <Link to="#">What We Do </Link>*/}
                {/*</li>*/}
                <li>
                  <Link to="#">Contact Us </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-2 col-md-2">
              <h6>Contact Details</h6>
              <div className="help">
                <p>
                  <a href="mailto:info@bodysolutions.com">
                    info@bodysolutions.com
                  </a>
                </p>

                <p>28 Cambridge Avenue San Francisco 3426</p>
                <p>
                  <a href="tel:(758) 937 - 2694">(758) 937 - 2694</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <HowToUseModal handleClose={handleClose} show={show} />
    </>

  )
}

export default FooterTop