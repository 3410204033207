import Modal from "react-bootstrap/Modal";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FadeLoader } from "react-spinners";
import { skipExercise } from "../../../actions/user_test";


const SkipExerciseModal = ({ show, handleClose, exerciseId, skipTestReasons, skipExerciseRequest, skipExercise, dayId }) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        mode: "onBlur",
    });
    const [selectedOptions, setSelectedOptions] = useState('');
    useEffect(() => {
        reset();
    }, [exerciseId])

    const onSubmit = async data => {
        handleClose()
        let submitData = {
            user_program_day_id: dayId,
            user_program_day_exercise_id: exerciseId,
            reason:skipTestReasons[0]?.id || '',
            reason_text:selectedOptions,
            note: data.note,
        }
        skipExercise(submitData)
    }
    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            // If checkbox is checked, add the value to the string
            setSelectedOptions((prevSelected) =>
                prevSelected ? prevSelected + ',' + value : value
            );
        } else {
            // If checkbox is unchecked, remove the value from the string
            setSelectedOptions((prevSelected) =>
                prevSelected
                    ? prevSelected
                        .split(',')
                        .filter((item) => item !== value)
                        .join(',')
                    : ''
            );
        }
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            className="modal_test"
            id="skip"
            centered
        >
            <Modal.Body className="text-left">
                <div className="box_state">
                    <h4>Why do you want to skip the exercise?</h4>
                    <Form onSubmit={handleSubmit(onSubmit)} className="formSkipExercise" id="formSkipExercise" autoComplete="off" role="presentation">
                        {skipTestReasons && skipTestReasons.map((item, index) => {
                            return (
                                <>
                                    <Form.Group key={index} className="form-group checkbox_custom mb-0 mt-4">
                                        <Form.Control type="checkbox" id={"skip-check-" + index} name="reason"
                                            value={item.option}
                                            {...register('reason', {
                                                required: {
                                                    value: "required",
                                                    message: "Please select at least one option"
                                                },
                                                onChange:handleCheckboxChange
                                            })} />
                                        <Form.Label htmlFor={"skip-check-" + index}>
                                            {item.option}
                                        </Form.Label>
                                    </Form.Group>
                                    {errors.reason && <Form.Text
                                        className=" validationText hasError">{errors.reason.message}</Form.Text>}
                                </>
                            )
                        })}
                        <Form.Group className="form-group mt-4">
                            <textarea className="form-control form-2 text-left" placeholder="Other" rows="5" maxLength="500" name="note" id="note" {...register('note', {
                                maxLength: {
                                    value: 500,
                                    message: "Max length is 500"
                                },
                            })}></textarea>
                            {errors.note && <Form.Text
                                className=" validationText hasError">{errors.note.message}</Form.Text>}
                        </Form.Group>
                        {skipExerciseRequest ?
                            <div className="loader_custom"><FadeLoader color={"#FB1417"} height={10} /></div> :
                            <Form.Control
                                type="submit"
                                value="Submit"
                                className="theme_btn1 small_btn"
                            />}
                    </Form>
                </div>
            </Modal.Body>
        </Modal >
    );
};

const mapStateToProps = state => ({
    skipExerciseRequest: state.testReducer.skipExerciseRequest,
    skipExerciseSuccess: state.testReducer.skipExerciseSuccess,
});

export default connect(mapStateToProps, { skipExercise })(SkipExerciseModal);
