import React, { useState } from 'react'
import { Link } from 'react-router-dom'
const Welcome2 = ({ activeDayName, activeDay, program, setWeeklySummary, weeklySummary }) => {
    const [isChecked, setIsChecked] = useState(weeklySummary == 1 ? true : false);
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        setWeeklySummary(event.target.checked ? 1 : 0)
    };
    return (
        <section className="welcome_002">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-8 col-md-8">
                        <ul className="breadcrumb main">
                            <li className="">
                                <Link to="/">Home </Link>
                            </li>
                            <li>
                                <Link to="/welcome"> Welcome</Link>
                            </li>
                            {
                                program ? <li><Link to={"/program/" + program.id}> Assessment</Link></li> : ''
                            }
                            {
                                weeklySummary != 1 && activeDayName ? <li><Link to={"/assessment-timer"}>{activeDayName}</Link></li> : ''
                            }
                        </ul>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4">
                        <div className="nav_week justify-content-end">
                            <Link to="#" className="daily">Daily</Link>
                            <div className="button-switch">
                                <input type="checkbox" id="addsetting"
                                    className="switch sw_btn" checked={isChecked} onChange={handleCheckboxChange} />
                                <label htmlFor="addsetting" className="lbl-off"></label>
                            </div>
                            <Link to="#" className="weekly">Cumulative</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Welcome2