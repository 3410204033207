import React, { useEffect } from 'react';
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Header from "../../Layout/Header";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../Layout/Footer";
import profile from '../../images/dummy.jpg'
import profile1 from "../../images/dummyprofile1.jpg";
import profile2 from "../../images/dummyprofile2.jpg";
import profile3 from "../../images/dummyprofile3.jpg";
import { connect } from "react-redux";
import { getTestimonials } from "../../../actions/user_program";


function TestimonialsDetail({ getTestimonials, testimonial }) {
    useEffect(() => {
        getTestimonials()

    }, [])

    const data = testimonial?.data
    return (
        <>
            <ScrollTopOnMount />
            <Header />
            <section className="testimonials">
                <Container className={"h-100"}>

                    <Row className={"h-100"}>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                            <div className="videos_headings">
                                <h2 className="detail_span" style={{ color: "#fff" }}>Testimonials</h2>
                                <p style={{ color: "#fff" }}> What our client are saying</p>
                            </div>
                        </div>
                    </Row>
                    <div className="test-wrap">
                    {
                        data && data.map((testimonial, key) => {
                            if (testimonial) {
                                return (
                                    <Row className="wrapper" key={key}>

                                        <Col className=" col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12  mb-md-5 mb-sm-5 mb-3 ">
                                            <div className="Profile">

                                                <div className="img-cover">
                                                    <img src={testimonial?.image} alt="" className="img-fluid" />
                                                </div>



                                                <div className="profile-info">
                                                    <h6>{testimonial?.name}</h6>
                                                    <p>
                                                        {testimonial?.title}
                                                    </p>
                                                </div>


                                            </div>
                                        </Col>
                                        <Col className="col-xl-7  col-lg-7 col-md-12 col-sm-12 col-12 m-auto   ">
                                            <div className="slide-content">
                                                <p>
                                                    {testimonial?.description}  </p>
                                            </div>

                                        </Col>



                                    </Row>
                                )

                            }

                        })
                    }
                    </div>
                    {/*<Row className="wrapper">*/}

                    {/*        <Col className=" col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12  mb-md-5 mb-sm-5 mb-3 ">*/}
                    {/*            <div className="Profile">*/}

                    {/*                <div className="img-cover">*/}
                    {/*                    <img src={profile} alt="" className="img-fluid"/>*/}
                    {/*                </div>*/}



                    {/*                <div className="profile-info">*/}
                    {/*                    <h6>Mark Brook</h6>*/}
                    {/*                    <p>*/}
                    {/*                        HitRefresh Agency/ Trainer*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}


                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*        <Col className="col-xl-7  col-lg-7 col-md-12 col-sm-12 col-12 m-auto   ">*/}
                    {/*            <div className="slide-content">*/}
                    {/*                <p>*/}
                    {/*                    “This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed.  These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. Think of them merely as actors on a paper stage, in a performance.”*/}
                    {/*                </p>*/}
                    {/*            </div>*/}

                    {/*        </Col>*/}



                    {/*</Row>*/}
                    {/*<Row className="wrapper">*/}

                    {/*    <Col className=" col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12  mb-md-5 mb-sm-5 mb-3 ">*/}
                    {/*        <div className="Profile">*/}

                    {/*            <div className="img-cover">*/}
                    {/*                <img src={profile1} alt="" className="img-fluid"/>*/}
                    {/*            </div>*/}



                    {/*            <div className="profile-info">*/}
                    {/*                <h6>Mark Brook</h6>*/}
                    {/*                <p>*/}
                    {/*                    HitRefresh Agency/ Trainer*/}
                    {/*                </p>*/}
                    {/*            </div>*/}


                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*    <Col className="col-xl-7  col-lg-7 col-md-12 col-sm-12 col-12 m-auto   ">*/}
                    {/*        <div className="slide-content">*/}
                    {/*            <p>*/}
                    {/*                “This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed.  These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. Think of them merely as actors on a paper stage, in a performance.”*/}
                    {/*            </p>*/}
                    {/*        </div>*/}

                    {/*    </Col>*/}



                    {/*</Row>*/}
                    {/*<Row className="wrapper">*/}

                    {/*    <Col className=" col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12  mb-md-5 mb-sm-5 mb-3 ">*/}
                    {/*        <div className="Profile">*/}

                    {/*            <div className="img-cover">*/}
                    {/*                <img src={profile2} alt="" className="img-fluid"/>*/}
                    {/*            </div>*/}



                    {/*            <div className="profile-info">*/}
                    {/*                <h6>Mark Brook</h6>*/}
                    {/*                <p>*/}
                    {/*                    HitRefresh Agency/ Trainer*/}
                    {/*                </p>*/}
                    {/*            </div>*/}


                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*    <Col className="col-xl-7  col-lg-7 col-md-12 col-sm-12 col-12 m-auto   ">*/}
                    {/*        <div className="slide-content">*/}
                    {/*            <p>*/}
                    {/*                “This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed.  These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. Think of them merely as actors on a paper stage, in a performance.”*/}
                    {/*            </p>*/}
                    {/*        </div>*/}

                    {/*    </Col>*/}



                    {/*</Row>*/}
                    {/*<Row className="wrapper">*/}

                    {/*    <Col className=" col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12  mb-md-5 mb-sm-5 mb-3 ">*/}
                    {/*        <div className="Profile">*/}

                    {/*            <div className="img-cover">*/}
                    {/*                <img src={profile3} alt="" className="img-fluid"/>*/}
                    {/*            </div>*/}



                    {/*            <div className="profile-info">*/}
                    {/*                <h6>Mark Brook</h6>*/}
                    {/*                <p>*/}
                    {/*                    HitRefresh Agency/ Trainer*/}
                    {/*                </p>*/}
                    {/*            </div>*/}


                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*    <Col className="col-xl-7  col-lg-7 col-md-12 col-sm-12 col-12 m-auto   ">*/}
                    {/*        <div className="slide-content">*/}
                    {/*            <p>*/}
                    {/*                “This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed.  These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. Think of them merely as actors on a paper stage, in a performance.”*/}
                    {/*            </p>*/}
                    {/*        </div>*/}

                    {/*    </Col>*/}



                    {/*</Row>*/}
                </Container>
            </section>
            <Footer />
        </>

    );
}
const mapStateToProps = state => {

    return {
        testimonial: state.programReducer.testimonial
    }
}
export default connect(mapStateToProps, { getTestimonials })(TestimonialsDetail)
