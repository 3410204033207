import React, {useEffect} from 'react'


import Header from "../../Layout/Header"
import Footer from "../../Layout/Footer"
import ChangeLevel1 from './ChangeLevel1'
import ChangeLevel2 from './ChangeLevel2'
import Banner from '../AssessmentGridView/Banner'
import { useLocation } from 'react-router-dom'

const ChangeLevel = () => {
  const location = useLocation()
  const { exercise } = location?.state
  // const { activeDayId } = location.state
  const { programId } = location?.state
  const { redirectLink } = location?.state


  return (
    <div>
      <Header />
      {/*<Banner />*/}
      <ChangeLevel1 curLevel={exercise && exercise.exerciseLevel.level ? exercise.exerciseLevel.level : null} />
      {/* <Welcome2 /> */}
      <ChangeLevel2 exerciseId={exercise && exercise.exercise_id ? exercise.exercise_id : null} curLevel={exercise && exercise.exerciseLevel.level ? exercise.exerciseLevel.level.id : null} activeId={exercise && exercise.id ? exercise.id : null} programId={programId ? programId : null} redirectLink={redirectLink} />
      <Footer />
    </div>
  )
}

export default ChangeLevel