import React, { useEffect } from 'react';
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import {Col, Container, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {getEducationalVideos, getFaqs} from "../../../actions/user_program";

function Faqs({getFaqs,faqs}) {
    useEffect( () => {
         getFaqs()
    }, []);


    const handleAccordionClick = (accordionTab, contents) => {
        accordionTab.classList.toggle('active');
        contents.style.display = contents.style.display === 'block' ? 'none' : 'block';

        const otherTabs = Array.from(accordionTab.parentNode.children).filter(
            (tab) => tab !== accordionTab
        );
        otherTabs.forEach((tab) => {
            tab.classList.remove('active');
            tab.querySelector('.contents').style.display = 'none';
        });
    };

    return (
        <>
        <ScrollTopOnMount />
        <Header />
        <section className="section accordion-section">
            <div className={"container h-100"}>

                <Row className={"h-100"}>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                        <div className="videos_headings">
                            <h2 className="detail_span" style={{ color: "#fff" }}>FREQUENTLY ASKED QUESTIONS</h2>
                        </div>
                    </div>
                    {/*<Col className={"col-12 col-lg-12 col-md-12 m-auto"}>*/}
                    {/*    <div className="text-center">*/}
                    {/*        <h2>Frequently Asked Questions*/}
                    {/*        </h2>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>
                <div className="faq-wrapper">
                    {/*<h3 className="sec-faq-heading">Frequently Asked Questions</h3>*/}
                    <div className="accordion">
                        {console.log(faqs)}
                        {faqs && faqs?.data?.map((data, key) => {
                            if (data) {
                                return(
                                    <div  className="accordion-tab" key={key} onClick={(e) => handleAccordionClick(e.currentTarget, e.currentTarget.querySelector('.contents'))}>
                                        <div className="faq-heading">{data?.question}</div>
                                        <div className="contents">
                                            {data?.answer}
                                        </div>
                                    </div>
                                    )

                            }
                    })}

                    </div>
                </div>
            </div>
        </section>
    <Footer />
</>
    );
}

const mapStateToProps = state => {

    return {
        faqs: state.programReducer.faqs
    }
}
export default connect(mapStateToProps, { getFaqs })(Faqs)
