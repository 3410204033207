import React from 'react'
import { Link } from 'react-router-dom'

const Welcome2 = () => {
    return (
        <section className="welcome_002">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-8 col-md-8">
                        <ul className="breadcrumb main">
                            <li className=""><Link to="/">Home </Link></li>
                            <li><Link to="/welcome"> Welcome</Link></li>
                            <li><Link to="assestment-gridview.php"> Assestment</Link></li>
                            <li><Link to="/assestment-day1"> Day 1</Link></li>
                            <li className="active"><Link to=""> Test</Link></li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4">
                        <div className="nav_week">
                            <Link to="/assestment-day1" className="daily">Daily</Link>
                            <div className="button-switch">
                                <input type="checkbox" id="addsetting"
                                    className="switch sw_btn" />
                                <label for="addsetting" className="lbl-off"> </label>
                            </div>
                            <Link to="#" className="weekly">Weekly</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Welcome2