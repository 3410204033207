import React from 'react'

const Home2 = () => {
  return (
    <section className="home_002">
    <div className="container h-100">
        <div className="row h-100">
            <div className="col-12 col-lg-4 col-md-5 my-auto ml-auto">
                <div className="box_banner">
                    <h2>Muscle


                        <span>IMBALANCES</span>
                        may be to
                        blame</h2>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Home2