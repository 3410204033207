import { INSTRUCTIONS_FAILURE, INSTRUCTIONS_REQUEST, INSTRUCTIONS_SUCCESS } from "../actions/types";

const initialState = {
    instrations: null,
    loading: false,
};

export default function instructionsReducer(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case INSTRUCTIONS_REQUEST:
            return {
                ...state,
                instructionsRequest: true,
                instructionsSuccess: false,
                instructionsFailure: false
            }
        case INSTRUCTIONS_SUCCESS:
            return {
                ...state,
                instructions: payload,
                instructionsRequest: false,
                instructionsSuccess: true,
                instructionsFailure: false
            }
        case INSTRUCTIONS_FAILURE:
            return {
                ...state,
                instructionsRequest: false,
                instructionsSuccess: false,
                instructionsFailure: true
            }
        default:
            return state;
    }
}