import React, {useEffect, useRef, useState} from 'react'

const TimerWeek1 = ({ exercises, activeExercise, setActiveExercise }) => {

  const scrollToActiveDiv = () => {
    const activeListItem = document.getElementById(activeExercise);
    if (activeListItem) {
      activeListItem.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToActiveDiv();
  }, [activeExercise]);

  const setActiveExerciseFunc = (data) => {
    setActiveExercise(data)
    localStorage.setItem('activeExerciseId', data)
  }
  return (
    <section className="assestmenttimwe_001">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12 col-md-12">
            <ul className="timer_step">
              {exercises.length > 0 && exercises.map((item, key) =>
                item.exercise && item.exercise.name ?
                  <li li key={key} className={activeExercise == item.id ? 'active' : ''}  id={item.id} onClick={() => setActiveExerciseFunc(item.id)}>
                    <p key={key}>{item.exerciseLevel ? item.exerciseLevel.file_name : ''}</p>
                  </li>
                  : ''
              )}
            </ul>
          </div>
        </div>
      </div>
    </section >
  )
}

export default TimerWeek1