import React from 'react'
import { Link } from "react-router-dom";
const Welcome2 = ({ activeDay, program }) => {
  return (
    <section className="welcome_002">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-12 col-md-12">
            <ul className="breadcrumb main">
              <li className="">
                <Link to="/">Home </Link>
              </li>
              <li>
                <Link to="/welcome"> Welcome</Link>
              </li>
              {
                program ? <li><Link to={"/program/" + program.id}> Assessment</Link></li> : ''
              }
              {
                activeDay ? <li><Link to={"/assessment-timer"}>{activeDay}</Link></li> : ''
              }
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Welcome2