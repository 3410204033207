import React, {useEffect, useState} from 'react';
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Header from "../../Layout/Header";
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FadeLoader from "react-spinners/FadeLoader";
import {Link,useParams} from "react-router-dom";
import Footer from "../../Layout/Footer";
import {connect} from "react-redux";
import { getMusclesAnatomyData, getPainPointData} from "../../../actions/user_program";
import {Interweave} from "interweave";
import Broken from "../../images/NoImages.png";

function DetailScreen({getMusclesAnatomyData ,muscles_anatomy_detail,getPainPointData,pain_point_detail }) {
    const [hasMusclesAnatomy, setHasMusclesAnatomy] = useState(false);
    const [musclesAnatomyData, setMusclesAnatomyData] = useState(null)
    const [loader, setLoader] = useState(true)
    const { id } = useParams();


    useEffect(()=>{
        const currentURL = window.location.href;
        const containsMusclesAnatomy = currentURL.includes('muscles-anatomy');
        setHasMusclesAnatomy(containsMusclesAnatomy);
        if(containsMusclesAnatomy){
            getMusclesAnatomyData(id)
        }else{
            getPainPointData(id)
        }

    },[])

    useEffect(() => {
        setTimeout(()=>{
            const topElement = document.querySelector('.videos'); // Replace 'top-element' with the actual ID of your top element
            if (topElement) {
                topElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start', // Scroll to the top of the element
                });
            }

        },2000)

    }, []);

    return (
        <>
            <ScrollTopOnMount />
            <Header />
            <div className={"banner-recomended display-none"}>
                <Container className={"h-100"}>
                    <Row className={"h-100"}>
                        <Col className={"col-12 col-lg-6 col-md-6 m-auto"}>
                            <div className="box_banner_inner text-center mx-auto">
                                <h3>{hasMusclesAnatomy ? 'Muscles Anatomy Details' : 'Trigger Point Pain Details'}</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={"clearfix"}></div>
            <section className="videos">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                            <div className="videos_headings">
                                <h2 className="detail_span" style={{ color: "#fff" }}>{hasMusclesAnatomy ? 'Muscles Anatomy Details' : 'Trigger Point Pain Details'}</h2>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                            <Row className="wrapper mt-3 mb-3">
                                {hasMusclesAnatomy ? <>
                                    <Col className=" col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12  mb-md-5 mb-sm-5 mb-3 ">
                                        <div className="detail-box">
                                            <div className="detail-box-img library-detail-img-box library-img-box">
                                                <img src={muscles_anatomy_detail ? muscles_anatomy_detail?.image : ''} alt=""  onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = Broken;
                                                }} />
                                            </div>
                                            <div className="detail-info">
                                                <h6>{muscles_anatomy_detail ? muscles_anatomy_detail?.title : ''}</h6>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="col-xl-9  col-lg-9 col-md-7 col-sm-12 col-12">
                                        <div className="slide-content">
                                            <p>
                                                {/*<span className={"description"}>Description:</span>*/}
                                                <Interweave content={muscles_anatomy_detail ? muscles_anatomy_detail?.description : 'No Data Available'} /></p>
                                        </div>
                                    </Col>
                                </>
                                    :
                                    <>
                                    <Col className=" col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12  mb-md-5 mb-sm-5 mb-3 ">
                                        <div className="detail-box">
                                            <div className="detail-box-img library-img-box">
                                                <img src={pain_point_detail ? pain_point_detail?.image : ''} alt=""  onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = Broken;
                                                }} />
                                            </div>
                                            <div className="detail-info">
                                                <h6>{pain_point_detail ? pain_point_detail?.title : ''}</h6>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="col-xl-9  col-lg-9 col-md-7 col-sm-12 col-12">
                                        <div className="slide-content">

                                            <p>
                                                {/*<span className={"description"}>Description:</span>*/}
                                                <Interweave content={pain_point_detail ? pain_point_detail?.description : 'No Data Available'} /></p>
                                        </div>
                                    </Col>
                                </>
                                }

                            </Row>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    );
}
const mapStateToProps = state => {
    return {
        muscles_anatomy_detail: state.programReducer.muscles_anatomy_detail,
        pain_point_detail: state.programReducer.pain_point_detail,
        user: state.auth.user
    }
}
export default connect(mapStateToProps, { getMusclesAnatomyData,getPainPointData })(DetailScreen)
