export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_PURGE = 'REGISTER_PURGE';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const OTP_SUCCESS = 'OTP_SUCCESS'
export const OTP_FAILURE = 'OTP_FAILURE';
export const OTP_REQUEST = 'OTP_REQUEST';
export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE'
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';
export const GET_USER_PROGRAM = 'GET_USER_PROGRAM';
export const UPDATE_USER_PROGRAM = 'UPDATE_USER_PROGRAM';
export const USER_PROGRAM_REQUEST = 'USER_PROGRAM_REQUEST';
export const USER_PROGRAM_REQUEST_FAILURE = 'USER_PROGRAM_REQUEST_FAILURE';
export const WEEK_SCHEDULE_REQUEST = 'WEEK_SCHEDULE_REQUEST';
export const WEEK_SCHEDULE_SUCCESS = 'WEEK_SCHEDULE_SUCCESS';
export const WEEK_SCHEDULE_FAILURE = 'WEEK_SCHEDULE_FAILURE';
export const LOGIN_UNVERIFIED = 'LOGIN_UNVERIFIED';
export const RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAILURE = 'RESEND_OTP_FAILURE';
export const EXERCISE_DETAIL_REQUEST = 'EXERCISE_DETAIL_REQUEST';
export const EXERCISE_DETAIL_SUCCESS = 'EXERCISE_DETAIL_SUCCESS';
export const EXERCISE_DETAIL_FAILURE = 'EXERCISE_DETAIL_FAILURE';

export const EXERCISE_LEVELS_REQUEST = 'EXERCISE_LEVELS_REQUEST';
export const EXERCISE_LEVELS_SUCCESS = 'EXERCISE_LEVELS_SUCCESS';
export const EXERCISE_LEVELS_FAILURE = 'EXERCISE_LEVELS_FAILURE';

export const LEVEL_CHANGE_REQUEST = 'LEVEL_CHANGE_REQUEST';
export const LEVEL_CHANGE_SUCCESS = 'LEVEL_CHANGE_SUCCESS';
export const LEVEL_CHANGE_FAILURE = 'LEVEL_CHANGE_FAILURE';
export const LEVEL_CHANGE_PURGE = 'LEVEL_CHANGE_PURGE';

export const SKIP_TEST_REASON_REQUEST = 'SKIP_TEST_REASON_REQUEST';
export const SKIP_TEST_REASON_SUCCESS = 'SKIP_TEST_REASON_SUCCESS';
export const SKIP_TEST_REASON_FAILURE = 'SKIP_TEST_REASON_FAILURE';
export const SKIP_TEST_REASON_PURGE = 'SKIP_TEST_REASON_PURGE';


export const GET_TESTS_REQUEST = 'GET_TESTS_REQUEST';
export const GET_TESTS = 'GET_TESTS';
export const GET_TESTS_FAILURE = 'GET_TESTS_FAILURE';
export const GET_TEST_REQUEST = 'GET_TEST_REQUEST';
export const GET_TEST = 'GET_TEST';
export const GET_TEST_FAILURE = 'GET_TEST_FAILURE';
export const GET_USER_PROGRAM_DAY_REQUEST = 'GET_USER_PROGRAM_DAY_REQUEST';
export const GET_USER_PROGRAM_DAY = 'GET_USER_PROGRAM_DAY';
export const GET_USER_PROGRAM_DAY_FAILURE = 'GET_USER_PROGRAM_DAY_FAILURE';
export const GET_QUESTION_REQUEST = 'GET_QUESTION_REQUEST';
export const GET_QUESTION = 'GET_QUESTION';
export const GET_QUESTION_FAILURE = 'GET_QUESTION_FAILURE';
export const CHECK_PREVIOUS_DAY_REQUEST = 'CHECK_PREVIOUS_DAY_REQUEST';
export const CHECK_PREVIOUS_DAY = 'CHECK_PREVIOUS_DAY';
export const CHECK_PREVIOUS_DAY_FAILURE = 'CHECK_PREVIOUS_DAY_FAILURE';
export const CHECK_PREVIOUS_DAY_PURGE = 'CHECK_PREVIOUS_DAY_PURGE';

export const SUBMIT_CHECKIN_FEEDBACK_REQUEST = 'SUBMIT_CHECKIN_FEEDBACK_REQUEST';
export const SUBMIT_CHECKIN_FEEDBACK = 'SUBMIT_CHECKIN_FEEDBACK';
export const SUBMIT_CHECKIN_FEEDBACK_FAILURE = 'SUBMIT_CHECKIN_FEEDBACK_FAILURE';
export const SUBMIT_CHECKIN_FEEDBACK_PURGE = 'SUBMIT_CHECKIN_FEEDBACK_PURGE';

export const SUBMIT_CHECKOUT_FEEDBACK_REQUEST = 'SUBMIT_CHECKOUT_FEEDBACK_REQUEST';
export const SUBMIT_CHECKOUT_FEEDBACK = 'SUBMIT_CHECKOUT_FEEDBACK';
export const SUBMIT_CHECKOUT_FEEDBACK_FAILURE = 'SUBMIT_CHECKOUT_FEEDBACK_FAILURE';
export const SUBMIT_CHECKOUT_FEEDBACK_PURGE = 'SUBMIT_CHECKOUT_FEEDBACK_PURGE';

export const SUBMIT_TEST_REQUEST = 'SUBMIT_TEST_REQUEST';
export const SUBMIT_TEST = 'SUBMIT_TEST';
export const SUBMIT_TEST_FAILURE = 'SUBMIT_TEST_FAILURE';
export const SKIP_TEST_REQUEST = 'SKIP_TEST_REQUEST';
export const SKIP_TEST = 'SKIP_TEST';
export const SKIP_TEST_FAILURE = 'SKIP_TEST_FAILURE';
export const RECOMMENDED_PRODUCTS_REQUEST = 'RECOMMENDED_PRODUCTS_REQUEST';
export const RECOMMENDED_PRODUCTS_SUCCESS = 'RECOMMENDED_PRODUCTS_SUCCESS';
export const RECOMMENDED_PRODUCTS_FAILURE = 'RECOMMENDED_PRODUCTS_FAILURE';
export const TESTIMONIALS_REQUEST = 'TESTIMONIALS_REQUEST';
export const TESTIMONIALS_SUCCESS = 'TESTIMONIALS_SUCCESS';
export const TESTIMONIALS_FAILURE = 'TESTIMONIALS_FAILURE';

export const FAQS_REQUEST = 'FAQS_REQUEST';
export const FAQS_SUCCESS = 'FAQS_SUCCESS';
export const FAQS_FAILURE = 'FAQS_FAILURE';

export const EDUCATIONAL_VIDEOS_REQUEST = 'EDUCATIONAL_VIDEOS_REQUEST';
export const EDUCATIONAL_VIDEOS_SUCCESS = 'EDUCATIONAL_VIDEOS_SUCCESS';
export const EDUCATIONAL_VIDEOS_FAILURE = 'EDUCATIONAL_VIDEOS_FAILURE';

export const MUSCLES_ANATOMY_REQUEST = 'MUSCLES_ANATOMY_REQUEST';
export const MUSCLES_ANATOMY_SUCCESS = 'MUSCLES_ANATOMY_SUCCESS';
export const MUSCLES_ANATOMY_FAILURE = 'MUSCLES_ANATOMY_FAILURE';

export const MUSCLES_ANATOMY_DETAIL_REQUEST = 'MUSCLES_ANATOMY_DETAIL_REQUEST';
export const MUSCLES_ANATOMY_DETAIL_SUCCESS = 'MUSCLES_ANATOMY_DETAIL_SUCCESS';
export const MUSCLES_ANATOMY_DETAIL_FAILURE = 'MUSCLES_ANATOMY_DETAIL_FAILURE';

export const PAIN_POINT_REQUEST = 'PAIN_POINT_REQUEST';
export const PAIN_POINT_SUCCESS = 'PAIN_POINT_SUCCESS';
export const PAIN_POINT_FAILURE = 'PAIN_POINT_FAILURE';

export const PAIN_POINT_DETAIL_REQUEST = 'PAIN_POINT_DETAIL_REQUEST';
export const PAIN_POINT_DETAIL_SUCCESS = 'PAIN_POINT_DETAIL_SUCCESS';
export const PAIN_POINT_DETAIL_FAILURE = 'PAIN_POINT_DETAIL_FAILURE';

export const APT_VIDEOS_REQUEST = 'APT_VIDEOS_REQUEST';
export const APT_VIDEOS_SUCCESS = 'APT_VIDEOS_SUCCESS';
export const APT_VIDEOS_FAILURE = 'APT_VIDEOS_FAILURE';

export const COUNTRIES_REQUEST = 'COUNTRIES_REQUEST';
export const COUNTRIES_SUCCESS = 'COUNTRIES_SUCCESS';
export const COUNTRIES_FAILURE = 'COUNTRIES_FAILURE';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';

export const USER_PROFILE_UPLOAD_REQUEST = 'USER_PROFILE_UPLOAD_REQUEST';
export const USER_PROFILE_UPLOAD_SUCCESS = 'USER_PROFILE_UPLOAD_SUCCESS';
export const USER_PROFILE_UPLOAD_FAILURE = 'USER_PROFILE_UPLOAD_FAILURE';
export const USER_PROFILE_UPLOAD_PURGE = 'USER_PROFILE_UPLOAD_PURGE';

export const USER_PROFILE_UPDATE_REQUEST = 'USER_PROFILE_UPDATE_REQUEST';
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS';
export const USER_PROFILE_UPDATE_FAILURE = 'USER_PROFILE_UPDATE_FAILURE';
export const USER_PROFILE_UPDATE_PURGE = 'USER_PROFILE_UPDATE_PURGE';


export const USER_PROGRAM_EXERCISE_UPDATE_REQUEST = 'USER_PROGRAM_EXERCISE_UPDATE_REQUEST';
export const USER_PROGRAM_EXERCISE_UPDATE_SUCCESS = 'USER_PROGRAM_EXERCISE_UPDATE_SUCCESS';
export const USER_PROGRAM_EXERCISE_UPDATE_FAILURE = 'USER_PROGRAM_EXERCISE_UPDATE_FAILURE';
export const USER_PROGRAM_EXERCISE_UPDATE_PURGE = 'USER_PROGRAM_EXERCISE_UPDATE_PURGE';

export const USER_SKIP_EXERCISE_REQUEST = 'USER_SKIP_EXERCISE_REQUEST';
export const USER_SKIP_EXERCISE_SUCCESS = 'USER_SKIP_EXERCISE_SUCCESS';
export const USER_SKIP_EXERCISE_FAILURE = 'USER_SKIP_EXERCISE_FAILURE';
export const USER_SKIP_EXERCISE_PURGE = 'USER_SKIP_EXERCISE_PURGE';

export const USER_PROGRAM_UPDATE_REQUEST = 'USER_PROGRAM_UPDATE_REQUEST';
export const USER_PROGRAM_UPDATE_SUCCESS = 'USER_PROGRAM_UPDATE_SUCCESS';
export const USER_PROGRAM_UPDATE_FAILURE = 'USER_PROGRAM_UPDATE_FAILURE';
export const USER_PROGRAM_UPDATE_PURGE = 'USER_PROGRAM_UPDATE_PURGE';

export const USER_SKIP_EXERCISE_COUNT_REQUEST = ' USER_SKIP_EXERCISE_COUNT_REQUEST';
export const USER_SKIP_EXERCISE_COUNT_SUCCESS = ' USER_SKIP_EXERCISE_COUNT_SUCCESS';
export const USER_SKIP_EXERCISE_COUNT_FAILURE = ' USER_SKIP_EXERCISE_COUNT_FAILURE';
export const USER_SKIP_EXERCISE_COUNT_PURGE = ' USER_SKIP_EXERCISE_COUNT_PURGE';

export const EXERCISE_SUMMARY_REQUEST = 'EXERCISE_SUMMARY_REQUEST';
export const EXERCISE_SUMMARY_SUCCESS = 'EXERCISE_SUMMARY_SUCCESS';
export const EXERCISE_SUMMARY_FAILURE = 'EXERCISE_SUMMARY_FAILURE';


export const UPDATE_PROGRAM_STATUS_REQUEST = 'UPDATE_PROGRAM_STATUS_REQUEST';
export const UPDATE_PROGRAM_STATUS_SUCCESS = 'UPDATE_PROGRAM_STATUS_SUCCESS';
export const UPDATE_PROGRAM_STATUS_FAILURE = 'UPDATE_PROGRAM_STATUS_FAILURE';
export const UPDATE_PROGRAM_STATUS_PURGE = 'UPDATE_PROGRAM_STATUS_PURGE';

export const PAGE_DATA_REQUEST = 'PAGE_DATA_REQUEST';
export const PAGE_DATA_SUCCESS = 'PAGE_DATA_SUCCESS';
export const PAGE_DATA_FAILURE = 'PAGE_DATA_FAILURE';
export const PAGE_DATA_PURGE = 'PAGE_DATA_PURGE';

export const INSTRUCTIONS_REQUEST = 'INSTRUCTIONS_REQUEST';
export const INSTRUCTIONS_SUCCESS = 'INSTRUCTIONS_SUCCESS';
export const INSTRUCTIONS_FAILURE = 'INSTRUCTIONS_FAILURE';

export const OCCUPATION_REQUEST = 'OCCUPATION_REQUEST';
export const OCCUPATION_SUCCESS = 'OCCUPATION_SUCCESS';
export const OCCUPATION_FAILURE = 'OCCUPATION_FAILURE';

export const SPORT_REQUEST = 'SPORT_REQUEST';
export const SPORT_SUCCESS = 'SPORT_SUCCESS';
export const SPORT_FAILURE = 'SPORT_FAILURE';