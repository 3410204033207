
export function EditProfile() {
    return(  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7276 1.25C16.0835 1.25 15.5514 1.5195 15.0478 1.90373C14.5707 2.26776 14.0409 2.79756 13.4056 3.4329L7.92473 8.91374C7.50384 9.33393 7.17105 9.66617 6.96696 10.0908C6.76286 10.5155 6.71131 10.9829 6.64612 11.574L6.62843 11.7338C6.50649 12.831 6.40597 13.7353 6.42898 14.4477C6.45304 15.1928 6.61405 15.8752 7.14744 16.4086C7.68084 16.942 8.36328 17.103 9.10833 17.1271C9.82071 17.1501 10.7251 17.0496 11.8222 16.9276L11.9821 16.9099C12.5732 16.8447 13.0406 16.7932 13.4652 16.5891C13.8899 16.385 14.2221 16.0522 14.6423 15.6313L20.1231 10.1504C20.7585 9.51512 21.2883 8.98536 21.6523 8.50824C22.0366 8.00463 22.3061 7.47256 22.3061 6.82843C22.3061 6.1843 22.0366 5.65222 21.6523 5.14862C21.2883 4.6715 20.7585 4.14174 20.1232 3.50644L20.0497 3.43293C19.4143 2.79757 18.8846 2.26777 18.4074 1.90373C17.9038 1.5195 17.3718 1.25 16.7276 1.25ZM19.5548 8.59491L14.9611 4.00128C15.3668 3.60162 15.6805 3.30772 15.9577 3.09627C16.3279 2.81383 16.5433 2.75 16.7276 2.75C16.9119 2.75 17.1274 2.81383 17.4976 3.09627C17.8851 3.3919 18.3441 3.84867 19.0257 4.53033C19.7074 5.21199 20.1642 5.67101 20.4598 6.05849C20.7422 6.42866 20.8061 6.64413 20.8061 6.82843C20.8061 7.01272 20.7422 7.22819 20.4598 7.59836C20.2483 7.87551 19.9544 8.18927 19.5548 8.59491ZM13.8992 5.06066L18.4954 9.65685L13.6539 14.4983C13.1265 15.0258 12.9807 15.1577 12.8155 15.2372C12.6502 15.3166 12.4561 15.348 11.7147 15.4303C10.5448 15.5603 9.74843 15.647 9.15674 15.6279C8.58517 15.6094 8.35441 15.4943 8.2081 15.348C8.06179 15.2016 7.94665 14.9709 7.9282 14.3993C7.90909 13.8076 7.99571 13.0113 8.12571 11.8413C8.20808 11.1 8.23947 10.9059 8.31891 10.7406C8.39835 10.5753 8.53029 10.4296 9.05773 9.90213L13.8992 5.06066Z" fill="black"/>
        <path d="M9.00899 3.92846C9.42317 3.9235 9.75491 3.58371 9.74995 3.16953C9.74498 2.75534 9.40519 2.42361 8.99101 2.42857C6.56449 2.45766 4.89894 2.59043 3.62024 3.51946C3.13209 3.87412 2.70281 4.3034 2.34815 4.79155C1.76232 5.59787 1.50001 6.54225 1.37373 7.70779C1.24999 8.84992 1.24999 10.2895 1.25 12.1261V12.2166C1.24999 14.0531 1.24999 15.4927 1.37373 16.6348C1.50001 17.8004 1.76232 18.7448 2.34815 19.5511C2.70281 20.0392 3.13209 20.4685 3.62024 20.8232C4.42656 21.409 5.37094 21.6713 6.53648 21.7976C7.67859 21.9213 9.11817 21.9213 10.9547 21.9213H11.0453C12.8818 21.9213 14.3214 21.9213 15.4635 21.7976C16.6291 21.6713 17.5734 21.409 18.3798 20.8232C18.8679 20.4685 19.2972 20.0392 19.6518 19.5511C20.5809 18.2724 20.7137 16.6068 20.7427 14.1803C20.7477 13.7661 20.416 13.4263 20.0018 13.4214C19.5876 13.4164 19.2478 13.7481 19.2428 14.1623C19.2129 16.6631 19.0445 17.835 18.4383 18.6694C18.1762 19.0302 17.8589 19.3475 17.4981 19.6096C16.9901 19.9787 16.3399 20.1939 15.302 20.3063C14.25 20.4203 12.8916 20.4213 11 20.4213C9.10843 20.4213 7.74999 20.4203 6.69804 20.3063C5.66013 20.1939 5.00992 19.9787 4.50191 19.6096C4.14111 19.3475 3.82382 19.0302 3.56168 18.6694C3.19259 18.1614 2.97745 17.5112 2.865 16.4733C2.75103 15.4213 2.75 14.0629 2.75 12.1713C2.75 10.2797 2.75103 8.9213 2.865 7.86936C2.97745 6.83144 3.19259 6.18123 3.56168 5.67322C3.82382 5.31242 4.14111 4.99513 4.50191 4.73299C5.33627 4.1268 6.50819 3.95844 9.00899 3.92846Z" fill="black"/>
    </svg>)

}
export function UploadIcon() {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_130_2204)">
                <path
                    d="M11.2552 4.86018C11.054 4.98333 10.7716 4.9533 10.6034 4.78809C9.63017 3.82087 8.66595 2.84464 7.68972 1.88042C7.68972 4.2444 7.68972 6.60838 7.68972 8.97537V9.33282C7.68972 9.33882 7.68972 9.34483 7.68972 9.35084C7.68972 9.36285 7.68972 9.37487 7.68972 9.38688V9.61818C7.68972 9.74434 7.68972 9.86749 7.68972 9.99365V10.0297C7.68972 10.1679 7.68972 10.309 7.68972 10.4472C7.68672 10.6034 7.61463 10.7596 7.49147 10.8587C7.34128 10.9879 7.119 11.0209 6.93577 10.9458C6.73452 10.8678 6.59034 10.6635 6.59034 10.4472C6.59034 7.59062 6.59034 4.73702 6.59034 1.88042C5.67418 2.78757 4.76704 3.70072 3.85689 4.61086C3.73974 4.722 3.63461 4.86018 3.47541 4.90824C3.25313 4.98634 2.98579 4.89622 2.85363 4.70098C2.70043 4.49071 2.73047 4.17532 2.9167 3.99809C4.19331 2.72148 5.46992 1.44487 6.74954 0.165261C6.89672 0.00906428 7.14003 -0.0450039 7.34128 0.0360984C7.46444 0.0811551 7.55155 0.18028 7.64166 0.270394C8.87622 1.50795 10.1138 2.74251 11.3513 3.98007C11.4505 4.07619 11.5195 4.20535 11.5286 4.34353C11.5436 4.54778 11.4354 4.75805 11.2552 4.86018Z"
                    fill="#9F9F9F"/>
                <path
                    d="M0.393527 6.61441C0.61881 6.54533 0.883143 6.64145 1.0093 6.8397C1.0874 6.95384 1.10242 7.09502 1.10242 7.23019C1.11443 8.33258 1.43884 9.42896 2.03059 10.3601C2.60731 11.2763 3.43636 12.0362 4.40057 12.5229C5.82436 13.2588 7.54553 13.3819 9.06244 12.8683C10.0867 12.5259 11.0149 11.9041 11.7178 11.084C12.6369 10.0237 13.1716 8.63296 13.1836 7.22719C13.1806 7.08 13.2016 6.92681 13.2978 6.80666C13.4389 6.61442 13.7123 6.53632 13.9346 6.62643C14.1418 6.70453 14.289 6.9178 14.286 7.13707C14.289 8.48577 13.8985 9.83147 13.1716 10.9669C12.5498 11.9461 11.6847 12.7692 10.6785 13.3429C9.62715 13.9467 8.41963 14.2711 7.2061 14.2771C5.81535 14.2921 4.4246 13.8866 3.25913 13.1266C2.49617 12.631 1.82633 11.9852 1.30367 11.2402C0.456606 10.0538 -0.00597631 8.59691 3.12544e-05 7.14008C-0.00597631 6.90278 0.165239 6.67749 0.393527 6.61441Z"
                    fill="#9F9F9F"/>
                <path d="M7.6897 8.97241V9.32986C7.6897 9.33587 7.6897 9.34188 7.6897 9.34789V8.97241Z" fill="white"
                      stroke="black" stroke-miterlimit="10"/>
            </g>
            <defs>
                <clipPath id="clip0_130_2204">
                    <rect width="14.28" height="14.28" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}