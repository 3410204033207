import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

function ErrorComponent(props) {
    let navigate = useNavigate();
    const goBack = () => {
        navigate(-1)
    }

    return (
        <div className={"ErrorPage"}>
            <Container>
                <Row>
                    <Col>
                        <h1>Error Page {props.type}</h1>
                        <p>{props.message} <Button onClick={() => goBack()} className="btn-back" variant="link">Go Back</Button></p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ErrorComponent