import {
    SKIP_TEST_REASON_FAILURE,
    SKIP_TEST_REASON_REQUEST,
    SKIP_TEST_REASON_SUCCESS,
    SKIP_TEST, SKIP_TEST_FAILURE,
    SKIP_TEST_REQUEST,
    SUBMIT_TEST,
    SUBMIT_TEST_FAILURE,
    SUBMIT_TEST_REQUEST,
    USER_SKIP_EXERCISE_REQUEST,
    USER_SKIP_EXERCISE_SUCCESS,
    USER_SKIP_EXERCISE_FAILURE,
} from "./types";
import api from '../utils/api';
import { setAlert } from "./alert";

export const submitTest = (payload) => async (dispatch) => {
    try {
        dispatch({
            type: SUBMIT_TEST_REQUEST
        })
        let token = localStorage.getItem('token')
        const res = await api.post('/user-program-day-tests', payload, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        dispatch({
            type: SUBMIT_TEST,
            payload: res.data.data
        });
    } catch (err) {
        const msg = err && err.response ? err.response.data.message : null;
        if (msg) {
            dispatch(setAlert(msg, 'danger'))
        }
        dispatch({
            type: SUBMIT_TEST_FAILURE
        })
    }
};

export const skipTest = (payload) => async (dispatch) => {
    try {
        dispatch({
            type: SKIP_TEST_REQUEST
        })
        let token = localStorage.getItem('token')
        const res = await api.post('/user-skip-tests', payload, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        dispatch({
            type: SKIP_TEST,
            payload: res.data.data
        });
    } catch (err) {
        const msg = err && err.response ? err.response.data.message : null;
        if (msg) {
            dispatch(setAlert(msg, 'danger'))
        }
        dispatch({
            type: SKIP_TEST_FAILURE
        })
    }
};

export const getSkipReasons = () => async dispatch => {
    try {
        dispatch({ type: SKIP_TEST_REASON_REQUEST });
        const token = localStorage.getItem('token')
        const res = await api.get("skip-question-reasons?is_paginate=0", {
            headers: {
                Authorization: 'Bearer ' + token
            },
        });
        dispatch({
            type: SKIP_TEST_REASON_SUCCESS,
            payload: res.data.data
        });
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({ type: SKIP_TEST_REASON_FAILURE })
        dispatch(setAlert(msg, 'danger'))
    }
};

export const skipExercise = (payload) => async (dispatch) => {
    try {
        dispatch({
            type: USER_SKIP_EXERCISE_REQUEST
        })
        let token = localStorage.getItem('token')
        const res = await api.post('/user-skip-exercises', payload, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        dispatch({
            type: USER_SKIP_EXERCISE_SUCCESS,
            payload: res.data.data
        });
    } catch (err) {
        const msg = err && err.response ? err.response.data.message : null;
        if (msg) {
            dispatch(setAlert(msg, 'danger'))
        }
        dispatch({
            type: USER_SKIP_EXERCISE_FAILURE
        })
    }
};