import {
    SKIP_TEST, SKIP_TEST_FAILURE, SKIP_TEST_REASON_FAILURE, SKIP_TEST_REASON_PURGE, SKIP_TEST_REASON_REQUEST, SKIP_TEST_REASON_SUCCESS, SKIP_TEST_REQUEST,
    SUBMIT_TEST, SUBMIT_TEST_FAILURE,
    SUBMIT_TEST_REQUEST,
    USER_SKIP_EXERCISE_FAILURE,
    USER_SKIP_EXERCISE_PURGE,
    USER_SKIP_EXERCISE_REQUEST,
    USER_SKIP_EXERCISE_SUCCESS

} from '../actions/types';
import { SKIP_TEST_REASONS } from "../utils/constants";

const initialState = {
    skipTestReason: null
};

function testReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SUBMIT_TEST_REQUEST:
            return {
                ...state,
                submitTestRequest: true,
                submitTestSuccess: false,
                submitTestFailure: false
            }
        case SUBMIT_TEST:
            return {
                ...state,
                submitTestRequest: false,
                submitTestSuccess: true,
                submitTestFailure: false
            }
        case SUBMIT_TEST_FAILURE:
            return {
                ...state,
                submitTestRequest: false,
                submitTestSuccess: false,
                submitTestFailure: true
            }
        case SKIP_TEST_REQUEST:
            return {
                ...state,
                skipTestRequest: true,
                skipTestSuccess: false,
                skipTestFailure: false
            }
        case SKIP_TEST:
            return {
                ...state,
                skipTestRequest: false,
                skipTestSuccess: true,
                skipTestFailure: false
            }
        case SKIP_TEST_FAILURE:
            return {
                ...state,
                skipTestRequest: false,
                skipTestSuccess: false,
                skipTestFailure: true
            }
        case SKIP_TEST_REASON_REQUEST:
            return {
                ...state,
                skipTestReasonRequest: true,
                skipTestReasonSuccess: false,
                skipTestReasonFailure: false,
                skipTestReason: null
            }
        case SKIP_TEST_REASON_SUCCESS:
            return {
                ...state,
                skipTestReasonRequest: false,
                skipTestReasonSuccess: true,
                skipTestReasonFailure: false,
                skipTestReason: payload
            }
        case SKIP_TEST_REASON_FAILURE:
            return {
                ...state,
                skipTestReasonRequest: false,
                skipTestReasonSuccess: false,
                skipTestReasonFailure: true
            }
        case SKIP_TEST_REASON_PURGE:
            return {
                ...state,
                skipTestReasonRequest: false,
                skipTestReasonSuccess: false,
                skipTestReasonFailure: false,
                skipTestReason: null
            }

        case USER_SKIP_EXERCISE_REQUEST:
            return {
                ...state,
                skipExerciseRequest: true,
                skipExerciseSuccess: false,
                skipExerciseFailure: false,
                skipExercise: null
            }
        case USER_SKIP_EXERCISE_SUCCESS:
            return {
                ...state,
                skipExerciseRequest: false,
                skipExerciseSuccess: true,
                skipExerciseFailure: false,
                skipExercise: payload
            }
        case USER_SKIP_EXERCISE_FAILURE:
            return {
                ...state,
                skipExerciseRequest: false,
                skipExerciseSuccess: false,
                skipExerciseFailure: true
            }
        case USER_SKIP_EXERCISE_PURGE:
            return {
                ...state,
                skipExerciseRequest: false,
                skipExerciseSuccess: false,
                skipExerciseFailure: false,
                skipExercise: null
            }
        default:
            return state;
    }
}

export default testReducer;
