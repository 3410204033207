import React from 'react'
import { Link } from "react-router-dom";
import left1 from "../../images/left1.png";
import left2 from "../../images/left2.png";

const BodyType2 = () => {
  return (
       <section className="bodytype_002 ">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-12 col-lg-3 col-md-3  text-center">
              <div className="img01">
                <img src={left1} alt="left1" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-lg-6 col-md-6 text-center">
              <h2>Sway Back</h2>
              <h2 className="pain">Sway Back Pain</h2>
              <h4>Corrective Program</h4>
              <Link to="#" className="theme_btn1">
                click here
              </Link>
            </div>
            <div className="col-12 col-lg-3 col-md-3 text-center">
              <div className="img01">
                <img src={left2} alt="left2" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default BodyType2