import React from 'react'

const Assessment2 = () => {
  return (
    <section className="assestmentd1_002">
    <div className="container">
        <div className="row">
            <div className="col-12 col-lg-12 col-md-12 text-center">
                <ul className="suggested">
                    <li>Suggested</li>
                    <li className="active">Actual</li>
                </ul>
            </div>
            <div className="col-12 col-lg-4 col-md-4">
                <div className="box_p shadow_box">
                    <h6>Time</h6>
                    <div className="progress_bar">
                        <div role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                             style={{"--value": "75"}}></div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-lg-4 col-md-4">
                <div className="box_p shadow_box">
                    <h6>Exercies</h6>
                    <div className="progress_bar">
                        <div role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="75"
                             style={{"--value": "75"}}></div>
                    </div>

                </div>
            </div>

            <div className="col-12 col-lg-4 col-md-4">
                <div className="box_p shadow_box">
                    <h6>Reps</h6>
                    <div className="progress_bar">
                        <div role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="50"
                             style={{"--value": "50"}}></div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Assessment2