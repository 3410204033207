import React from "react";
import Modal from "react-bootstrap/Modal";
import {useState} from "react";
import {Link} from "react-router-dom";

const DayCompleteModal = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    return (
        <>
            <div to="" className="theme_btn1" onClick={handleShow}>
                Finish
            </div>
            <Modal show={show} onHide={handleClose} className="modal_test" centered>
                <Modal.Body className="modal-body">
                    <h4>
                        Congratulations !!!
                        <br/>
                        You have completed your day's exercises
                    </h4>
                    <Link to="/assessment-gridview" class="theme_btn1 small_btn">
                        Finish
                    </Link>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DayCompleteModal;
