import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { FadeLoader } from "react-spinners";


const Welcome1 = ({ program, loading }) => {

  if (!program && !loading) {
    return (
      <section className="welcome_001">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 col-lg-6 col-md-6 m-auto">
              <div className="box_banner_inner text-center">
                <p>No program assigned</p>
              </div>
            </div>
          </div>
        </div>
      </section>)
  }
  else {
    return (
      <div>
        {!program || loading ?
          <section className="welcome_001">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-12 col-lg-6 col-md-6 m-auto">
                  <div className="box_banner_inner text-center mx-auto">
                    <FadeLoader color={"#FB1417"} height={10} />
                  </div>
                </div>
              </div>
            </div>
          </section> :
          <section className="welcome_001">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-12 col-lg-6 col-md-6 m-auto">
                  <div className="box_banner_inner text-center mx-auto">
                    <div>
                      <h1>{program.name}</h1>
                      {/* <h5> {program.description}</h5> */}
                      <p>12 Week Corrective Exercise Program</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      </div>
    )
  }

}
Welcome1.propTypes = {
  program: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  program: state.programReducer.program,
  loading: state.programReducer.loading
});

export default connect(mapStateToProps, {})(Welcome1);