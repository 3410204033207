import React, { useEffect } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Day1 from "./Day1";
import Welcome1 from "./Welcome1";
import Welcome2 from "./Welcome2";
import TestDetails from "./TestDetails";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import { useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getProgram, getProgramTest, getUserProgramDay } from "../../../actions/user_program";

const Index = ({ getProgram, program, getUserProgramDay, getProgramTest, user_program_day, testDetails }) => {
    let { id } = useParams();
    const location = useLocation();

    useEffect(() => {
        if (!program) {
            getProgram()
        }
        if (localStorage.getItem('activeDayId')) {
            getUserProgramDay(localStorage.getItem('activeDayId'))
        }
        getProgramTest(id)
    }, [id])

    let selectedTests = location.state && location.state.selectedTests ? location.state.selectedTests : null

    if (selectedTests) {
        localStorage.setItem("selectedTests", JSON.stringify(selectedTests))
    }

    return (
        <>
            <ScrollTopOnMount />
            <Header />
            <Welcome1 program={program} user_program_day={user_program_day} exercises={true}/>
            <Day1 />
            <Welcome2 testDetails={testDetails} program={program} />
            <TestDetails testDetails={testDetails} />
            <Footer />
        </>
    );
};

const mapStateToProps = state => ({
    program: state.programReducer.program,
    testDetails: state.programReducer.testDetails,
    user_program_day: state.programReducer.user_program_day
});

export default connect(mapStateToProps, { getProgram, getProgramTest, getUserProgramDay })(Index);
