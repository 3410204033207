import React from 'react'

import bodytype from "../../images/bodytype.png";
const BodyType1 = () => {
  return (
    <section className="bodytype_001">
        <div className="container  ">
          <div className="row ">
            <div className="col-12 col-lg-4 col-md-5 m-auto ">
              <div className="">
                <h1>BODY TYPES</h1>
                <p>
                  People’s muscle
                  <br /> imbalances tend to fall
                  <br /> into 3 main categories:
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-md-6   ">
              <img src={bodytype} alt="bodytype" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
  )
}

export default BodyType1