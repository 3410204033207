export function pluck(ArrayObj, column) {
    let array = []
    for (let item of ArrayObj) {
        array.push(item[column])
    }
    return array
}

export function checkValidation(payload, keys) {
    let errors = {};
    for (let key of keys) {
        if (!payload[key]) {
            errors[key] = `${key} is required`
            continue;
        }
        switch (key) {
            case 'good_sore':
                if (!payload[key].length) {
                    errors[key] = `${key} is required`
                }
                break;
            case 'rating':
                if (!payload[key].length) {
                    errors[key] = `${key} is required`
                }
                break;
            default:
                return errors;
        }
    }
    return errors;
}

export function getNextArrayElement(array, item) {
    let index = array.indexOf(item);
    if (index >= 0 && index < array.length - 1) {
        return array[index + 1]
    } else {
        return null
    }
}