import React, {useEffect} from 'react'

const ChangeLevel1 = ({ curLevel }) => {
    useEffect(() => {
        setTimeout(()=>{
            const topElement = document.querySelector('.change_level_001'); // Replace 'top-element' with the actual ID of your top element
            if (topElement) {
                topElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start', // Scroll to the top of the element
                });
            }

        },2000)

    }, []);

  return (
    <section className="change_level_001">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12 col-md-12">
            <h4><span>Current Level:</span> {curLevel ? curLevel.name : ''} (Level-{curLevel ? curLevel.id : ''})</h4>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ChangeLevel1