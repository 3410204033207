import React from 'react'
import shape1 from '../../images/shape1.png'
import shape2 from '../../images/shape2.png'
const Home10 = () => {
  return (
    <section className="home_0010">
    <div className="container-fluid">
        <div className="row">
            <div className="col-12 col-lg-6 col-md-6 p-0">
                <img src={shape1} alt="shape1" className="img-fluid" />
            </div>
            <div className="col-12 col-lg-6 col-md-6 p-0">
                <img src={shape2}  alt="shape2" className="img-fluid"  />
            </div>

        </div>
    </div>
</section>
  )
}

export default Home10