import React, { useEffect } from 'react';
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { Link } from "react-router-dom";
import { EditProfile } from "../../../utils/svg";
import { connect } from 'react-redux';
import { getUserProfile } from '../../../actions/auth';
import imgNotFound from "../../images/user-profile.png"
import { genders, weight_unit } from "../../../utils/constants";

function MyProfile({ userProfile, getUserProfile }) {
    useEffect(() => {
        getUserProfile()
    }, [])

    return (
        <>
            <ScrollTopOnMount />
            <Header />
            <section className="profile">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                            <div className="heading-wrapper">
                                <h3>
                                    My Profile
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <ul className="breadcrumb main">
                                <li className="">
                                    <Link to="/">Home </Link>
                                </li>
                                <li className="active">
                                    <Link to="/my-profile"> My Profile</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 col-lg-3 col-md-4 order-sm-1 order-1 order-md-1 order-lg-1">
                            <div className="img-cont">
                                <img src={userProfile ? userProfile.userDetail.image ? userProfile.userDetail.image : imgNotFound : imgNotFound} alt="User Profile" className={"img-fluid"} />
                            </div>

                        </div>
                        <div className="col-10 col-lg-5 col-md-6 order-sm-3 order-3 order-md-2 order-lg-2">
                            <div className="row">
                                <div className="col-6 col-lg-6 col-md-6">
                                    <ul className="profile-info">
                                        <li>
                                            <h4>Full Name</h4>
                                            <p>{userProfile ? userProfile.name : ''}</p>
                                        </li>
                                        <li>
                                            <h4>DOB</h4>
                                            <p>{userProfile ? userProfile?.userDetail?.dob : ''}</p>
                                        </li>
                                        <li>
                                            <h4>Weight Unit</h4>
                                            <p>{userProfile ? userProfile.userDetail.weight_unit == weight_unit.KG ? 'KG' : userProfile.userDetail.weight_unit == weight_unit.LBS ? 'LBS' : '' : ''}</p>
                                        </li>
                                        <li>
                                            <h4>Country</h4>
                                            <p>{userProfile ? userProfile.userDetail.countries ? userProfile.userDetail.countries.nicename : '' : ''}</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6 col-lg-6 col-md-6">
                                    <ul className="profile-info">
                                        <li>
                                            <h4>Email Address</h4>
                                            <p>{userProfile ? userProfile.email : ''}</p>
                                        </li>
                                        <li>
                                            <h4>Gender</h4>
                                            <p>{userProfile ? userProfile.userDetail.gender == genders.MALE ? 'Male' : userProfile.userDetail.gender == genders.FEMALE ? 'Female' : '' : ''}</p>
                                        </li>
                                        <li>
                                            <h4>Phone Number</h4>
                                            <p>{userProfile ? userProfile.phone : ''}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-4 col-md-2 order-sm-2 order-2 order-md-3 order-lg-3">
                            <Link to={"/my-profile/edit-profile"} className="edit-profile">
                                <EditProfile />  Edit Profile
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
const mapStateToProps = state => ({
    userProfileRequest: state.auth.userProfileRequest || null,
    userProfileSuccess: state.auth.userProfileSuccess || null,
    userProfileFailure: state.auth.userProfileFailure || null,
    userProfile: state.auth.userProfile,
});

export default connect(mapStateToProps, { getUserProfile })(MyProfile);